import { useEffect, useState } from "react";
import { MatterRecordableEventModel } from "models/view/MatterRecordableEventModel";
import { deleteMatterRecordableEvent, getActivitiesForChargingSchemeFeeEarner, getMatterRecordableEventWithItems } from "actions/matter";
import { Col, Row } from "react-bootstrap";
import Field from "components/Fields/Field";
import Loader from "components/Loader/index";
import { DateFormat } from "utils/constants";
import moment from "moment";
import { ActivityDropdownForRecordTimeModel } from "models/view/ActivityDropdownForRecordTimeModel";
import ViewMatterRecordableEventActivityList from "../ViewMatterRecordableEventList/ViewMatterRecordableEventActivityList";
import ViewMatterRecordableEventIncidentalExpenseList from "../ViewMatterRecordableEventList/ViewMatterRecordableEventIncidentalExpenseList";
import useSlidingPanelActions from "actions/slidingPanel";
import EditMatterRecordableEvent from "../EditMatterRecordableEvent/EditMatterRecordableEvent";
import { useAppSelector } from "hooks/appSelector";
import { MatterActivityHistoryModel } from "models/view/MatterActivityHistoryModel";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import EditActivityHistoryTaxonomyTermsForm from "../../ActivityHistoryGrid/ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import useGridActions from "actions/grid";
import UnlinkMatterIncidentalExpenseForm from "../UnlinkMatterIncidentalExpense/UnlinkMatterIncidentalExpenseForm";
import usePageActions from "actions/page";

type Props = {
  matterId: string,
  recordableEventId: string,
  isBilled?: boolean,
  activityHistoryId?: string,
  isMatterClosed?: boolean,
  hasTaxonomies?: boolean,
  showMoreButtons?: boolean,
}

export default function ViewMatterRecordableEvent(props: Props) {
  const [matterRecordableEvent, setMatterRecordableEvent] = useState<MatterRecordableEventModel | undefined>(undefined);
  const [activities, setActivities] = useState<ActivityDropdownForRecordTimeModel[]>([]);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const modalActions = useModalActions();
  const gridActions = useGridActions();
  const currentGridState = useAppSelector((state) => state.grid);
  const pageActions = usePageActions();

  useEffect(() => {
    setIsLoading(true);
    getMatterRecordableEventWithItems(props.matterId, props.recordableEventId).then((response) => {
      getActivitiesForChargingSchemeFeeEarner(response.data.chargingSchemeId, response.data.feeEarnerLevelId).then((activitiesResponse) => {
        setMatterRecordableEvent(response.data);
        setActivities(activitiesResponse.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      setIsLoading(false);
    });

    if(!props.isMatterClosed && !props.isBilled && props.matterId) {
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();
  
        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Edit Recordable Event",
          children: <EditMatterRecordableEvent
            matterId={props.matterId!}
            recordableEventId={props.recordableEventId}
            onSubmitCallback={onChildPanelClose}
          />,
          onCancel: onChildPanelClose
        });
      });
    }

    if (!props.isMatterClosed && props.showMoreButtons) {
      slidingPanelActions.setMoreButtons([
        ...(props.hasTaxonomies && props.activityHistoryId ? [{
          type: ActionButtonTypes.EditTaxonomyTerms,
          callback: () => onClickTaxonomies(),
        }] : []),
        {
          type: ActionButtonTypes.UnlinkIncidentalExpenses,
          callback: () => onClickUnlinkIncidentalExpenses(),
        },
        ...(!props.isBilled ? [{
          type: ActionButtonTypes.Delete,
          callback: () => onClickDelete(),
        }] : [])
      ]);
    }
  }, [props.matterId, props.recordableEventId]);

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  }

  const onClickTaxonomies = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        children: <EditActivityHistoryTaxonomyTermsForm
          matterId={props.matterId}
          activityHistoryId={props.activityHistoryId!}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const onClickUnlinkIncidentalExpenses = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Unlink Incidental Expense",
        children: <UnlinkMatterIncidentalExpenseForm
          matterId={props.matterId}
          recordableEventId={props.recordableEventId}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const onClickDelete = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Recordable Event?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.recordableEventId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const deleteCallback = (matterId: string, activityId: string) => {
    const gridState = currentGridState;
    modalActions.toggleModalLoading();
    deleteMatterRecordableEvent(matterId, activityId).then(() => {
      gridActions.setGridRowData(gridState.rowData.filter((x: MatterActivityHistoryModel) => x.matterRecordableEventId !== activityId));
      pageActions.triggerReloadPage(false);

      modalActions.toggleModalShownStatus();
      slidingPanelActions.clearSlidingPanel();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  return (
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col sm={6}>
          <Field
            label={"Fee Earner"}
            value={matterRecordableEvent?.user?.name}
          />
        </Col>

        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Fee Earner Level"}
            value={matterRecordableEvent?.feeEarnerLevel?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Client"}
            value={matterRecordableEvent?.matterParticipatingEntity?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            label={"Date"}
            value={matterRecordableEvent?.date ? moment(matterRecordableEvent?.date).format(DateFormat.Moment) : "—"}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Display Name"}
            value={matterRecordableEvent?.displayName}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Description"}
            value={matterRecordableEvent?.description}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            label={"Charging Scheme"}
            value={matterRecordableEvent?.chargingScheme?.name}
          />
        </Col>
        {/*<Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Event Type"}
            value={matterRecordableEvent?.recordableEventType?.name}
          />
        </Col>*/}
      </Row>
      <Row>
        <Col>
          <ViewMatterRecordableEventActivityList
            matterRecordableItems={matterRecordableEvent?.matterRecordableItems ?? []}
            activities={activities}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ViewMatterRecordableEventIncidentalExpenseList
            matterIncidentalExpenses={matterRecordableEvent?.matterIncidentalExpenses ?? []}
          />
        </Col>
      </Row>
    </>
  );
}
