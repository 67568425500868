export enum SummaryIds {
  DashboardSumary = "DashboardSummary",
  ChartOfAccountsLedgerEntriesSummary = "ChartOfAccountsLedgerEntriesSummary",
  MatterActivityHistorySummary = "MatterActivityHistorySummary",
  MatterClientLedgerSummary = "MatterClientLedgerSummary",
  MatterDisbursementsSummary = "MatterDisbursementsSummary",
  MatterIncidentalExpensesSummary = "MatterIncidentalExpensesSummary",
  MatterRecordableItemsSummary = "MatterRecordableItemsSummary",
  MatterDetailsSummary = "MatterDetailsSummary",
  LteIncidentalExpensesSummary = "LteIncidentalExpensesSummary",
  LteRecordableItemsSummary = "LteRecordableItemsSummary",
}