import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Row, Col, Form, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { getMatterEmailById, updateMatterEmailDetails } from "actions/matter";
import moment from "moment";
import Field from "components/Fields/Field";
import BooleanField from "components/Fields/BooleanField";
import { DateFormat } from "utils/constants";
import { UpdateMatterEmailDetailsModel } from "models/update/UpdateMatterEmailDetailsModel";
import { MatterEmailModel } from "models/view/MatterEmailModel";
import { getEmailTypeName } from "utils/enums";
import { useAppSelector } from "hooks/appSelector";
import usePageActions from "actions/page";
import FormErrorButton from "components/Buttons/FormErrorButton";

type Props = {
  matterId: string,
  emailId: string,
  onSubmitCallback?: Function,
}

export default function EditMatterEmailDetailsForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const [matterEmail, setMatterEmail] = useState<MatterEmailModel>();
  const pageActions = usePageActions();

  const {reset, register, handleSubmit, formState: {errors}} = useForm<UpdateMatterEmailDetailsModel>({
    resolver: vestResolver(validationSuite)
  });

  useEffect(() => {
    setIsLoading(true);
    getMatterEmailById(props.matterId, props.emailId).then((response) => {
      setMatterEmail(response.data);
      let initialState: UpdateMatterEmailDetailsModel = {
        description: response.data.description,
        additionalNote: response.data.additionalNote
      };

      reset(initialState);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
      
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: UpdateMatterEmailDetailsModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    updateMatterEmailDetails(props.matterId, props.emailId, data)
      .then((response) => {
        pageActions.triggerReloadPage();

        slidingPanelActions.clearSlidingPanel();
        reset();

        props.onSubmitCallback && props.onSubmitCallback();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel !== undefined && currentSlidingPanelState?.onCancel();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="description">
            <Form.Label className="required">Description</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.description?.message ? 'invalid' : ''}`}
              {...register(`description`, {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.description?.message && (errors.description?.message)}
            </Form.Text>
          </Form.Group>
        </Row>
        <Row>
          <Col>
            <Field label={'Subject'} value={matterEmail?.subject} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={'Sender'}
              value={matterEmail?.senderEmailAddress}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={'To'}
              value={
                matterEmail?.toRecipients && matterEmail?.toRecipients.length > 0
                  ? matterEmail?.toRecipients.join(', ')
                  : '—'
              }
            />
            {matterEmail?.ccRecipients &&
              matterEmail?.ccRecipients.length > 0 && (
                <Field
                  label={'Cc'}
                  value={matterEmail?.ccRecipients.join(', ')}
                />
              )}
            {matterEmail?.bccRecipients &&
              matterEmail?.bccRecipients.length > 0 && (
                <Field
                  label={'Bcc'}
                  value={matterEmail?.bccRecipients.join(', ')}
                />
              )}
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field label={'Type'} value={getEmailTypeName(matterEmail?.type!)} />
          </Col>
          {matterEmail?.type == 0 && (
            <Col sm={6} className="mt-4 mt-sm-0">
              <Field
                label={'Sent Date Time'}
                value={
                  matterEmail?.sentDateTime
                    ? moment(matterEmail?.sentDateTime).format(
                        DateFormat.MomentWithTime
                      )
                    : '—'
                }
              />
            </Col>
          )}
          {matterEmail?.type != 0 && (
            <Col sm={6} className="mt-4 mt-sm-0">
              <Field
                label={'Received Date Time'}
                value={
                  matterEmail?.receivedDateTime
                    ? moment(matterEmail?.receivedDateTime).format(
                        DateFormat.MomentWithTime
                      )
                    : '—'
                }
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col sm={6}>
            <BooleanField
              label={'Has Attachments'}
              value={matterEmail?.hasAttachments ?? false}
            />
          </Col>
          <Col sm={6} className="mt-4 mt-sm-0">
            <BooleanField
              label={'Internal'}
              value={matterEmail?.isInternal ?? false}
            />
          </Col>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="additionalNote">
            <Form.Label>Additional Note</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              className={`${errors?.additionalNote?.message ? 'invalid' : ''}`}
              {...register(`additionalNote`, {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.additionalNote?.message && (errors.additionalNote.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Update" />
              : <Button variant="success" type="submit">Update</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
