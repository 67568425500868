import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from 'react-bootstrap';
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { getEntityEmails, linkEntityEmail } from "actions/entity";
import CustomSelect from "components/Select/Select";
import { removeEmptyFields } from "utils/form";
import { EntityEmailModel } from "models/view/EntityEmailModel";
import { LinkEntityEmailModel } from "models/create/LinkEntityEmailModel";
import FormErrorButton from "components/Buttons/FormErrorButton";

type Props = {
  toEntityId: string,
  fromEntityId: string,
  submitCallback?: Function
}

export default function LinkEntityEmailForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();

  const { reset, control, handleSubmit, formState: {errors} } = useForm<LinkEntityEmailModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: LinkEntityEmailModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    linkEntityEmail(props.toEntityId, data).then((response) => {
      slidingPanelActions.clearSlidingPanel();
      if(props.submitCallback) {
        props.submitCallback(response.data);
      }
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}
      
      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="entityEmailIdToCopy">
            <Form.Label className="required">Email Address</Form.Label>
            <Controller
              control={control}
              name="entityEmailIdToCopy"
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="entityEmailIdToCopy"
                  inputRef={ref}
                  className={`lp-select${errors?.entityEmailIdToCopy?.message ? ' invalid' : ''}`}
                  getOptionLabel={(x: EntityEmailModel) => x.email}
                  getOptionValue={(x: any) => x.id}
                  endpointCall={() => getEntityEmails(props.fromEntityId)}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.entityEmailIdToCopy?.message && (errors.entityEmailIdToCopy.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Create" />
              : <Button variant="success" type="submit">Create</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
