import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { MdAdd } from "react-icons/md";
import { getColumnDefs } from "./columnDefs";
import CreateLteBankAccountForm from "./CreateLteBankAccount/CreateLteBankAccountForm";
import { Card, Button } from "react-bootstrap";
import { getLteBankAccounts } from "actions/lte";
import useSlidingPanelActions from "actions/slidingPanel";
import Title from "components/Title/index";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { AgGridReact } from "ag-grid-react";
import { GridIds } from "enums/GridIds";

type Props = {
  lteId: string
}

const LteBankAccountList = forwardRef(function LteBankAccountList(props: Props, forwardedRef: Ref<AgGridReact | null>) {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const loggedInUser = useAppSelector((state) => state.user);

  const gridRef = useRef<AgGridReact>(null);
  useImperativeHandle(forwardedRef, () => gridRef.current!);

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs();
    gridActions.setGridColumnDefs([...columnDefs]);
  }, [props.lteId]);  

  useEffect(() => {
    gridActions.setGridId(GridIds.LteBankAccounts);
  }, []);

  useEffect(() => {
    loadLteBankAccounts();
  }, [props.lteId]);

  const loadLteBankAccounts = () => {
    getLteBankAccounts(props.lteId).then((response) => 
    {
      gridActions.setGridRowData(response.data as any)
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  }

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Add Bank Account",
        children: <CreateLteBankAccountForm submitCallback={loadLteBankAccounts} />
      }
    );
  }

  return (
    <>
      <Title type="section" title={"Bank accounts"}>
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageAllLTEs || a == UserPermissionsNames.ManageLTE) &&
          <Button onClick={toggleSlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        <Card.Body>
          <Grid 
            ref={gridRef}
            onGridReady={onGridReady}
          />
        </Card.Body>
      </Card>
    </>
  );
});

export default LteBankAccountList;
