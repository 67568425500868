import CustomSelect from "components/Select/Select";
import { TabModel } from "models/view/TabModel";
import React, { useEffect, useRef, useState } from "react";
import { Tabs, TabList, Tab } from "react-tabs";
import { InputGroup } from "react-bootstrap";
import './style.scss';
import useWindowSize from "hooks/windowSize";
import { useLocation, useNavigate, useParams } from "react-router-dom";

type Props = {
  tabList: TabModel[],
  asyncTabListLoaded?: boolean
}

export default function CustomTabs(props: Props) {
  const [width, height] = useWindowSize();
  const { tab } = useParams();

  // Keep track of the tab key in a ref to be able to use it in an event listener
  const tabKey = useRef<string | undefined>(tab);

  // Set tab index based on tab key. If result is -1 (not found), Math.max will return 0 and the first tab will be selected
  // If a user opens a link to a tab he doesn't have access to, it will default to 0 (the tab won't be in the tabList - therefore, not found)
  const [tabIndex, setTabIndex] = useState<number>(Math.max(0, props.tabList.findIndex((t) => t.key === tab)));

  // Keep track of the tabs that have been loaded in the lifecycle of this component
  // Filters, grouping and sorting will only be applied to the tabs that the user is not accessing for the first time
  const [sessionLoadedTabKeys, setSessionLoadedTabKeys] = useState<string[]>([]);

  const location = useLocation();
  const navigate = useNavigate();

  const onTabChange = (index: number, isBrowserNavigationButtonSource?: boolean) => {
    const prevTabIndex = tabIndex!;
    const prevTab = props.tabList[prevTabIndex];
    const nextTab = props.tabList[index];

    if(nextTab && nextTab.key) {
      // If there is a previous tab, add it to sessionLoadedTabKeys & set it in localStorage
      if(prevTab.key && !sessionLoadedTabKeys.includes(prevTab.key)) {
        localStorage.setItem('sessionLoadedTabKeys', JSON.stringify([...sessionLoadedTabKeys, prevTab.key]));
        setSessionLoadedTabKeys([...sessionLoadedTabKeys, prevTab.key]);
      }

      // If path wasn't changed by browser navigation buttons then change it here
      if(!isBrowserNavigationButtonSource) {
        let path = location.pathname;
        // If the pathname contains the previous tab key, remove it
        if(prevTab?.key && location.pathname.endsWith(prevTab.key)) {
          path = path.substring(0, path.lastIndexOf("/"));
        }

        // Navigate to the next tab
        navigate(`${path}/${nextTab.key}`);
      }

      setTabIndex(index);
    }
  };

  useEffect(() => {
    // Sync tab changes with tabKey ref
    tabKey.current = tab;
  }, [tab]);

  useEffect(() => {
    if(location?.state?.quickSearchMatterId) {
      // If the user navigated from quick search panel, reset sessionLoadedTabKeys
      setSessionLoadedTabKeys([]);
      localStorage.removeItem('sessionLoadedTabKeys');
    }
  }, [location]);

  useEffect(() => {
    // If there is no tab in the URL, replace the URL with the first tab
    if(!tab && props.tabList.length > 0) {
      navigate(`${location.pathname}/${props.tabList[0].key}`, { replace: true });
    }

    // If the current tab doesn't match the one from the URL, replace the URL to the correct tab
    // This can happen when a user navigates to a tab that he doesn't have access to
    // When the tabList is loaded asynchronously, this will be handled by the useEffect that handles async tab list loading
    if(tab && props.tabList.length > 0 && props.asyncTabListLoaded === undefined) {
      const currentTabIndex = props.tabList.findIndex((t) => t.key === tab);
      if(currentTabIndex === -1) {
        replaceUrlTabToIndex(tabIndex);
      }
    }
  }, []);

  // Special case for tabs where the final tabList is loaded asynchronously
  useEffect(() => {
    if(!props.asyncTabListLoaded) {
      return;
    }

    // After all tabs have loaded, navigate to the correct tab
    if(tab && props.tabList.length > 0) {
      const currentTabIndex = props.tabList.findIndex((t) => t.key === tab);

      // If the tab is not in the tabList, replace URL to the first tab then return
      if(currentTabIndex === -1) {
        replaceUrlTabToIndex(tabIndex);
        return;
      }

      if(currentTabIndex !== tabIndex) {
        onTabChange(currentTabIndex, true);
      }
    }
  }, [props.asyncTabListLoaded]);

  const replaceUrlTabToIndex = (index: number) => {
    const tab = props.tabList[index];
    if(tab?.key) {
      const path = location.pathname.substring(0, location.pathname.lastIndexOf("/"));
      navigate(`${path}/${tab.key}`, { replace: true });
    }
  }

  const unloadCallback = () => {
    // When navigating away from current page, remove sessionLoadedTabKeys from localStorage
    localStorage.removeItem('sessionLoadedTabKeys');
  };

  const onBrowserNavigationCallback = () => {
    const index = props.tabList.findIndex((t) => t.key === tabKey.current);
    onTabChange(index === -1 ? 0 : index, true);
  };

  useEffect(() => {
    // Execute unloadCallback on beforeunload event. This will be called when refreshing page or when closing it
    window.addEventListener("beforeunload", unloadCallback);

    // Listen for browser back & forward buttons and call onBrowserNavigationCallback
    window.addEventListener("popstate", onBrowserNavigationCallback);

    return () => {
      unloadCallback(); // Called when compoenent is unmounted
      window.removeEventListener("beforeunload", unloadCallback);
      window.removeEventListener("popstate", onBrowserNavigationCallback);
    }
  }, []);

  return (
    <>
      { tabIndex != null &&
        <Tabs selectedIndex={tabIndex} onSelect={(index) => onTabChange(index)}>
          {width < 1200 &&
            <div className="lp-tabs-mobile">
              <InputGroup>
                <InputGroup.Text id="basic-addon1">Select tab</InputGroup.Text>
                <CustomSelect
                  id="tabs"
                  options={
                    props.tabList.map((x: TabModel, index: number) => {return {id: index, name: x.name};})
                  }
                  value={tabIndex}
                  onChange={val => onTabChange(val?.id)}
                />
              </InputGroup>
            </div>
          }
          <TabList>
            {props.tabList.map((x: TabModel, index: number) => <Tab key={index}>{x.name}</Tab>)}
          </TabList>
          {props.tabList.map((x: TabModel, index: number) => <React.Fragment key={index}>{x.panel}</React.Fragment>)}
        </Tabs>
      }
    </>
  );
}
