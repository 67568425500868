import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import CustomSelect from "components/Select/Select";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { getMatterIncidentalExpensesForRecordableEventSummary, unlinkMatterIncidentalExpenses } from "actions/matter";
import { UnlinkMatterIncidentalExpenseModel } from "models/update/UnlinkMatterIncidentalExpenseModel";
import { useAppSelector } from "hooks/appSelector";
import usePageActions from "actions/page";
import FormErrorButton from "components/Buttons/FormErrorButton";

type Props = {
  matterId: string,
  recordableEventId: string,
  onSubmitCallback?: Function,
}

export default function UnlinkMatterIncidentalExpenseForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const pageActions = usePageActions();

  const {reset, control, handleSubmit, formState: {errors}} = useForm<UnlinkMatterIncidentalExpenseModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: UnlinkMatterIncidentalExpenseModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    unlinkMatterIncidentalExpenses(props.matterId, props.recordableEventId, data)
      .then((response) => {
        pageActions.triggerReloadPage();

        slidingPanelActions.clearSlidingPanel();
        reset();

        props.onSubmitCallback && props.onSubmitCallback();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel && currentSlidingPanelState.onCancel();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="matterIncidentalExpenseIds">
            <Form.Label>Incidental Expenses</Form.Label>
            <Controller
              control={control}
              name="matterIncidentalExpenseIds"
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="matterIncidentalExpenseIds"
                  inputRef={ref}
                  className={`lp-select${errors?.matterIncidentalExpenseIds?.message ? ' invalid' : ''}`}
                  endpointCall={() => getMatterIncidentalExpensesForRecordableEventSummary(props.matterId, props.recordableEventId)}
                  value={value}
                  onChange={val => onChange(val?.map((x: { id: any; }) => x.id) ?? null)}
                  isMulti
                  isClearable
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.matterIncidentalExpenseIds?.message && (errors.matterIncidentalExpenseIds.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Unlink" />
              : <Button variant="success" type="submit">Unlink</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
