import Title from "components/Title";
import { TabPanel } from "react-tabs";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import DefaultAccountTypeCategoryList from "./DefaultAccountTypeCategories/DefaultAccountTypeCategoryList";
import DefaultAccountList from "./DefaultAccounts/DefaultAccountList";
import DefaultAccountTypeList from "./DefaultAccountTypes/DefaultAccountTypeList";
import AccountList from "./Accounts/AccountList";
import AccountTypeList from "./AccountTypes/AccountTypeList";
import AccountTypeCategoryList from "./AccountTypeCategories/AccountTypeCategoryList";
import CustomTabs from "components/CustomTabs";

export default function ViewSettings() {
  const loggedInUser = useAppSelector((state) => state.user);

  return (
    <div className="lp-page-content">
      <Title type="page" title="Accounts Settings"></Title>

      <CustomTabs 
        tabList={[
          ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewDefaultSettings || a == UserPermissionsNames.ManageDefaultSettings) ? [{
            name: 'Default Accounts',
            key: 'defaultAccounts',
            panel: <TabPanel>
              <DefaultAccountList />
            </TabPanel>
          },
          {
            name: 'Default Account Types',
            key: 'defaultAccountTypes',
            panel: <TabPanel>
              <DefaultAccountTypeList />
            </TabPanel>
          },
          {
            name: 'Default Account Type Categories',
            key: 'defaultAccountTypeCategories',
            panel: <TabPanel>
              <DefaultAccountTypeCategoryList />
            </TabPanel>
          }] : []),
          ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ViewLTESettings || a == UserPermissionsNames.ManageLTESettings) ? [{
            name: 'Accounts',
            key: 'accounts',
            panel: <TabPanel>
              <AccountList />
            </TabPanel>
          },
          {
            name: 'Account Types',
            key: 'accountTypes',
            panel: <TabPanel>
              <AccountTypeList />
            </TabPanel>
          },
          {
            name: 'Account Type Categories',
            key: 'accountTypeCategories',
            panel: <TabPanel>
              <AccountTypeCategoryList />
            </TabPanel>
          }] : [])
        ]}
      />
    </div>
  );
}
