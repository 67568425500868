import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Card, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import Loader from "components/Loader";
import { getInvoiceDisplayNameSuggestions, getInvoiceDueDateSettingTypes, updateLteGeneralSettings } from "actions/lte";
import { removeEmptyFields } from "utils/form";
import { getValidationSuite } from "./validationSuite";
import { UpdateLteGeneralSettingsModel } from "models/update/UpdateLteGeneralSettingsModel";
import { LteGeneralSettingsModel } from "models/view/LteGeneralSettingsModel";
import CustomSelect from "components/Select/Select";
import CustomCreatableSelect from "components/Select/CreatableSelect";
import DatePicker from "react-datepicker";
import { getDateOnly } from "utils/date";
import { DateFormat } from "utils/constants";

type Props = {
  lteId: string,
  lteGeneralSettings: LteGeneralSettingsModel | undefined,
  submitCallbackFn: Function,
  cancelCallbackFn: Function,
  isLoading: boolean
}

export default function EditLteGeneralSettingsSummary(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(props.isLoading);

  const { register, reset, control, watch, setValue, handleSubmit, formState: { errors } } = useForm<UpdateLteGeneralSettingsModel>({
    resolver: vestResolver(getValidationSuite())
  });

  useEffect(() => {
    if (props.lteGeneralSettings) {
      let initialFields: UpdateLteGeneralSettingsModel = {
        invoiceDueDateSettingNumber: props.lteGeneralSettings.invoiceDueDateSettingNumber,
        invoiceDueDateSettingTypeId: props.lteGeneralSettings.invoiceDueDateSettingTypeId,
        invoiceDisplayNameSetting: props.lteGeneralSettings.invoiceDisplayNameSetting,
        matterDestructionDateSetting: props.lteGeneralSettings.matterDestructionDateSetting,
        financialYearEnd: props.lteGeneralSettings.financialYearEnd
      };
      reset(initialFields);
    }
  }, [props.lteGeneralSettings]);

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: UpdateLteGeneralSettingsModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    updateLteGeneralSettings(props.lteId, data).then((response) => {
      if(props.submitCallbackFn){
        props.submitCallbackFn(response.data);
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const onCancelClick = () => {
    if(props.cancelCallbackFn){
      props.cancelCallbackFn();
    }
  };

  const onChangeInvoiceDueDateSettingType = (val: any) => {
    if(!val){
      setValue('invoiceDueDateSettingNumber', undefined);
    }
  };

  return (
    <Card>
      {isLoading && <Loader inlineLoader />}

      <Card.Body>
        {genericErrors && (
          <div className="lp-errors">
            {genericErrors}
          </div>
        )}

        <Form onSubmit={onSubmit}>
          <Row>
            <Form.Group as={Col} controlId={"matterDestructionDateSetting"}>
              <Form.Label className={"required"}>Matter Data Destruction Date (years)</Form.Label>
              <Form.Control
                type="number"
                className={`${errors?.matterDestructionDateSetting?.message ? 'invalid' : ''}`}
                {...register("matterDestructionDateSetting", {shouldUnregister: true})}
                min="0"
                step="1"
                onWheel={e => e.currentTarget.blur()}
              />
              <Form.Text className="lp-error">
                {errors?.matterDestructionDateSetting?.message && (errors.matterDestructionDateSetting?.message)}
              </Form.Text>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col}>
              <Form.Label>Invoice Due Date</Form.Label>
              <InputGroup className="lp-invoice-due-date">
                <Form.Control
                  type="number"
                  className={`${errors?.invoiceDueDateSettingNumber?.message ? 'invalid' : ''}`}
                  {...register("invoiceDueDateSettingNumber", {shouldUnregister: true})}
                  min="0"
                  step="1"
                  onWheel={e => e.currentTarget.blur()}
                />
                <Controller
                  control={control}
                  name={`invoiceDueDateSettingTypeId`}
                  shouldUnregister={true}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <CustomSelect
                      id="invoiceDueDateSettingTypeId"
                      inputRef={ref}
                      className={`lp-select${errors?.invoiceDueDateSettingTypeId?.message ? ' invalid' : ''}`}
                      endpointCall={getInvoiceDueDateSettingTypes}
                      value={value}
                      onChange={val => { onChange(val?.id ?? null); onChangeInvoiceDueDateSettingType(val?.id); }}
                      isClearable
                    />
                  )}
                />
              </InputGroup>
              <Form.Text className="lp-error">
                {errors?.invoiceDueDateSettingNumber?.message && (errors.invoiceDueDateSettingNumber.message)}
                {errors?.invoiceDueDateSettingTypeId?.message && (errors.invoiceDueDateSettingTypeId.message)}
              </Form.Text>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} sm={6} controlId="invoiceDisplayNameSetting">
              <Form.Label>Invoice Display Name</Form.Label>
              <Controller
                control={control}
                name="invoiceDisplayNameSetting"
                shouldUnregister={true}
                render={({field: { value, onChange }}) => (
                  <CustomCreatableSelect
                    id="invoiceDisplayNameSetting"
                    className={`lp-select${errors?.invoiceDisplayNameSetting?.message ? ' invalid' : ''}`}
                    endpointCall={getInvoiceDisplayNameSuggestions}
                    onChange={(val) => onChange(val?.value ?? null)}
                    value={value}
                    isClearable
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.invoiceDisplayNameSetting?.message && errors.invoiceDisplayNameSetting.message}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="financialYearEnd">
              <Form.Label className="required">Financial Year End</Form.Label>
              <Controller
                control={control}
                name="financialYearEnd"
                shouldUnregister={true}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    className={`${errors?.financialYearEnd?.message ? 'invalid' : ''}`}
                    id="financialYearEnd"
                    dateFormat={DateFormat.DayMonthpicker}
                    selected={value ? getDateOnly(value) : null}
                    onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                    showMonthDropdown
                    showYearPicker={false}
                    autoComplete="off"
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.financialYearEnd?.message && (errors.financialYearEnd.message)}
              </Form.Text>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className="d-flex justify-content-between">
              <Button variant="success" type="submit">Update</Button>
              <Button variant="secondary-400" onClick={onCancelClick}>Cancel</Button>
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
