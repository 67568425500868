import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PageState = {
  reloadGrid: Function | undefined,
  reloadSummary: Function | undefined,
}

const initialState: PageState = {
  reloadGrid: undefined,
  reloadSummary: undefined,
}

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setReloadGrid: (state, action: PayloadAction<Function>) => {
      state.reloadGrid = action.payload;
    },
    setReloadSummary: (state, action: PayloadAction<Function>) => {
      state.reloadSummary = action.payload;
    },
    resetPage: (state) => {
      state.reloadGrid = undefined;
      state.reloadSummary = undefined;
    },
  },
})

export const {
  setReloadGrid,
  setReloadSummary,
  resetPage,
} = pageSlice.actions;

export default pageSlice.reducer;
