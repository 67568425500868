import Field from "components/Fields/Field"
import { Card, Row, Col } from "react-bootstrap";
import { UserCalendarSettingsModel } from "models/view/UserCalendarSettingsModel";
import Loader from "components/Loader";
import moment from "moment";

type Props = {
  isLoading: boolean;
  userCalendarSettings: UserCalendarSettingsModel | undefined;
}

export default function ViewLteInfo(props: Props) {
  return (
    <>
      <Card>
        {props.isLoading && <Loader inlineLoader />}

        <Card.Body>
          <Row>
            <Col>
              <Field
                label={"First day of the week"}
                value={props.userCalendarSettings?.firstDayOfWeek}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                label={"Date Format"}
                value={props.userCalendarSettings?.dateFormat}
              />
            </Col>
            <Col>
              <Field
                label={"Time Format"}
                value={props.userCalendarSettings?.timeFormatIs24Hours ? "24 Hours" : "12 Hours"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                label={"Date Format Preview"}
                value={moment("2020-01-30T15:30:00Z").format(props.userCalendarSettings?.dateFormat)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                label={"Working Day Start Time"}
                value={props.userCalendarSettings?.startWorkingHours.slice(0, -3)} // Remove seconds
              />
            </Col>
            <Col>
              <Field
                label={"Working Day End Time"}
                value={props.userCalendarSettings?.endWorkingHours.slice(0, -3)} // Remove seconds
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
