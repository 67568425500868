export enum ActionButtonTypes {
  View,
  Edit,
  EditOnlyIsPreferred,
  Delete,
  Unlink,
  Deactivate,
  Activate,
  ShowMore,
  Call,
  Email,
  EditTaxonomyTerms,
  OpenFile,
  PreviewFile,
  PreviewEmail,
  Copy,
  SaveAttachments,
  LinkToRecordableEvent,
  UnlinkIncidentalExpenses,
  Payment,
  MarkAsDraft,
  ShowOnMatterStatisticsCards,
  HideFromFromMatterStatisticsCards,
  ViewParentResource,
  WriteOff,
  Download,
  Abate,
  ViewProfile
}
