import { ColDef, ColGroupDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { dateComparator } from "components/Grid/Comparators/DateComparator";
import { MatterClientLedgerEntryModel } from "models/view/MatterClientLedgerEntryModel";
import { DateFormatter } from "components/Grid/ValueFormatters/DateFormatter";
import EditMatterClientLedgerEntryForm from "./EditMatterClientLedgerEntry/EditMatterClientLedgerEntryForm";
import store from "state/store";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";
import ViewAccountLedgerTransaction from "components/SlidingPanel/ViewAccountLedgerTransaction/ViewAccountLedgerTransaction";

export const viewTransactionCallback = (rowData: MatterClientLedgerEntryModel, isMatterClosed: boolean) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        allowNavigation: true,
        title: "View Ledger Transaction",
        children: <ViewAccountLedgerTransaction
          accountLedgerTransactionId={rowData.accountLedgerTransactionId}
          accountLedgerTransactionEntryId={rowData.accountLedgerTransactionEntryId}
          editComponent={ !isMatterClosed
            ? <EditMatterClientLedgerEntryForm 
                matterClientLedgerEntryId={rowData.accountLedgerTransactionEntryId}
              />
            : undefined
          }
        />
      }
    )
  );
}

export const getColumnDefs = (matterIsClosed: boolean, hideClientAccountColumns: boolean) => {
  const columnDefs: (ColDef<MatterClientLedgerEntryModel> | ColGroupDef<MatterClientLedgerEntryModel> | {excludeFromExport: boolean})[] = [
    { 
      headerName: 'Date',
      field: 'accountLedgerTransactionDate',
      filter: 'agDateColumnFilter',
      sortable: false,
      valueFormatter: DateFormatter,
      filterParams: {
        comparator: dateComparator
      },
      minWidth: 120
    },
    { 
      headerName: 'Details',
      field: 'accountLedgerTransactionDescription',
      filter: 'agTextColumnFilter',
      sortable: false,
      minWidth: 400,
    },
    { 
      headerName: 'Business Account',
      children: [
        {
          headerName: 'DR',
          field: 'businessSideDRAmount',
          filter: 'agNumberColumnFilter',
          valueFormatter: CurrencyFormatter,
          sortable: false,
          cellClass: 'text-end',
        },
        {
          headerName: 'CR',
          field: 'businessSideCRAmount',
          filter: 'agNumberColumnFilter',
          valueFormatter: CurrencyFormatter,
          sortable: false,
          cellClass: 'text-end',
        },
        {
          headerName: 'BAL',
          field: 'businessSideBalance',
          filter: 'agNumberColumnFilter',
          valueFormatter: CurrencyFormatter,
          sortable: false,
          cellClass: 'text-end',
        }
      ]
    },
    { 
      headerName: 'Client Account',
      children: [
        {
          headerName: 'DR',
          field: 'clientSideDRAmount',
          filter: 'agNumberColumnFilter',
          valueFormatter: CurrencyFormatter,
          sortable: false,
          cellClass: 'text-end',
          hide: hideClientAccountColumns
        },
        {
          headerName: 'CR',
          field: 'clientSideCRAmount',
          filter: 'agNumberColumnFilter',
          valueFormatter: CurrencyFormatter,
          sortable: false,
          cellClass: 'text-end',
          hide: hideClientAccountColumns
        },
        {
          headerName: 'BAL',
          field: 'clientSideBalance',
          filter: 'agNumberColumnFilter',
          valueFormatter: CurrencyFormatter,
          sortable: false,
          cellClass: 'text-end',
          hide: hideClientAccountColumns
        }
      ]
    },
    { 
      headerName: 'Notes',
      field: 'accountLedgerTransactionEntryNotes',
      filter: 'agTextColumnFilter',
      sortable: false,
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return { buttonsData: [
          {
            type: ActionButtonTypes.View,
            callback: () => viewTransactionCallback(params.data, matterIsClosed)
          }
        ]}
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 65,
      maxWidth: 65,
      width: 65,
      suppressMovable: true,
      pinned: 'right',
      resizable: false,
      hide: matterIsClosed
    }
  ];

  return columnDefs;
}