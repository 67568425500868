import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col } from "react-bootstrap";
import { deleteMatterReturnMoney, getMatterReturnMoneyById } from "actions/matter";
import moment from "moment";
import { DateFormat } from "utils/constants";
import _ from 'lodash';
import { formatCurrency } from "utils/misc";
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import { useAppSelector } from "hooks/appSelector";
import { MatterReturnMoneyModel } from "models/view/MatterReturnMoneyModel";
import EditMatterReturnMoneyForm from "../EditMatterReturnMoney/EditMatterReturnMoneyForm";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import usePageActions from "actions/page";

type Props = {
  matterId: string,
  matterReturnMoneyId: string,
  onDeleteCallback?: Function,
  onEditCallback?: Function,
  showMoreButtons?: boolean,
}

function ViewMatterReturnMoney(props: Props){
  const [isLoading, setIsLoading] = useState(true);
  const [matterReturnMoney, setMatterReturnMoney] = useState<MatterReturnMoneyModel>();
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const loggedInUser = useAppSelector((state) => state.user);
  const pageActions = usePageActions();

  useEffect(() => {
    setIsLoading(true);
    getMatterReturnMoneyById(props.matterId, props.matterReturnMoneyId).then((response) => {
      setMatterReturnMoney(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  },[]);

  useEffect(() => {
    if(matterReturnMoney){
      setSlidingPanelEditButton();
      setSlidingPanelMoreButtons();
    }
  }, [matterReturnMoney]);

  const setSlidingPanelEditButton = () => {
    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageChartOfAccounts) &&
      !matterReturnMoney?.matter?.isClosed) {
      slidingPanelActions.setOnEdit(onClickEdit);
    }
  }

  const setSlidingPanelMoreButtons = () => {
    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageChartOfAccounts) && 
      !matterReturnMoney?.matter?.isClosed && props.showMoreButtons) {
      slidingPanelActions.setMoreButtons([{
        type: ActionButtonTypes.Delete,
        callback: onClickDelete,
      }]);
    }
  }

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  }

  function onClickDelete() {
    setIsLoading(true);
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Matter Return Money?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.matterReturnMoneyId),
      onClose: () => {setIsLoading(false);},
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();  
  }

  const deleteCallback = (matterId: string, matterReturnMoneyId: string) => {
    modalActions.toggleModalLoading();
    deleteMatterReturnMoney(matterId, matterReturnMoneyId).then(() => {
      pageActions.triggerReloadPage();

      slidingPanelActions.clearSlidingPanel();
      modalActions.toggleModalShownStatus(); 

      props.onDeleteCallback && props.onDeleteCallback();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      setIsLoading(false);
    });
  };

  function onClickEdit() {
    //close View Matter Return Money sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open Edit Matter Return Money sliding panel having onCancel event to reopen 
    //Edit Matter Return Money sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Edit Return Money",
      children: <EditMatterReturnMoneyForm
        matterId={props.matterId}
        matterReturnMoneyId={props.matterReturnMoneyId}
        onSubmitCallback={onChildPanelClose}
      />,
      onCancel: onChildPanelClose
    });
  }

  return(
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Client"}
            value={matterReturnMoney?.matterParticipatingEntity?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            label={"Date"}
            value={matterReturnMoney?.date ? moment(matterReturnMoney?.date).format(DateFormat.Moment) : "—"}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Return From Account"}
            value={matterReturnMoney?.account?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            label={"Returned By"}
            value={matterReturnMoney?.returnedBy?.name}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Returned To"}
            value={matterReturnMoney?.returnedTo}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            label={"Amount"}
            value={matterReturnMoney?.amount ? formatCurrency(matterReturnMoney?.amount) : "—"}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Reason"}
            value={matterReturnMoney?.reason?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Description"}
            value={matterReturnMoney?.description}
          />
        </Col>
      </Row>
    </>
  );
}

export default ViewMatterReturnMoney;
