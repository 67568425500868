import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import CustomSelect from "components/Select/Select";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { addEntityLink, getEntitiesSummary, getEntityAddresses, getEntityDocumentExchanges, getEntityEmails, getEntityPhoneNumbers } from "actions/entity";
import { CreateOrUpdateEntityLinkModel } from "models/create/CreateOrUpdateEntityLinkModel";
import { EntityTypeFilter } from "enums/EntityTypeFilter";
import { formatAddress } from "utils/address";
import { EntityDocumentExchangeModel } from "models/view/EntityDocumentExchangeModel";
import { EntityPhoneNumberModel } from "models/view/EntityPhoneNumberModel";
import { EntityEmailModel } from "models/view/EntityEmailModel";
import { EntityAddressModel } from "models/view/EntityAddressModel";
import FormErrorButton from "components/Buttons/FormErrorButton";

type Props = {
  entityId: string,
  submitCallback?: Function
}

export default function CreateOwnedByEntityLinkForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingPhoneNumbers, setIsLoadingPhoneNumbers] = useState<boolean>(false);
  const [phoneNumbers, setPhoneNumbers] = useState<EntityPhoneNumberModel[]>([]);
  const [isLoadingDocumentExchanges, setIsLoadingDocumentExchanges] = useState<boolean>(false);
  const [documentExchanges, setDocumentExchanges] = useState<EntityDocumentExchangeModel[]>([]);
  const [isLoadingEmails, setIsLoadingEmails] = useState<boolean>(false);
  const [emails, setEmails] = useState<EntityEmailModel[]>([]);
  const [isLoadingAddresses, setIsLoadingAddresses] = useState<boolean>(false);
  const [addresses, setAddresses] = useState<EntityAddressModel[]>([]);
  const slidingPanelActions = useSlidingPanelActions();

  const {reset, control, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateEntityLinkModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: CreateOrUpdateEntityLinkModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    data.toEntityId = props.entityId;
    addEntityLink(data)
      .then((response) => {
        if(props.submitCallback) {
          props.submitCallback(response.data);
        }
        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  const onChangeEntity = (selectedEntityId: string) => {
    setIsLoadingAddresses(true);
    setAddresses([]);
    getEntityAddresses(selectedEntityId).then((response) => {
      setAddresses(response.data.map(
        (x: EntityAddressModel) => {return {...x, id: x.addressId}}
      ));
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingAddresses(false);
    });

    setIsLoadingDocumentExchanges(true);
    setDocumentExchanges([]);
    getEntityDocumentExchanges(selectedEntityId).then((response) => {
      setDocumentExchanges(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingDocumentExchanges(false);
    });

    setIsLoadingEmails(true);
    setEmails([]);
    getEntityEmails(selectedEntityId).then((response) => {
      setEmails(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingEmails(false);
    });

    setIsLoadingPhoneNumbers(true);
    setPhoneNumbers([]);
    getEntityPhoneNumbers(selectedEntityId).then((response) => {
      setPhoneNumbers(response.data.map(
        (x: EntityPhoneNumberModel) => {return {...x, id: x.phoneNumberId}}
      ));
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoadingPhoneNumbers(false);
    });
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}
      
      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="fromEntityId">
            <Form.Label className="required">Entity</Form.Label>
            <Controller
              control={control}
              name="fromEntityId"
              shouldUnregister={true}
              render={({field: { onChange, value }}) => (
                <CustomSelect
                  id="fromEntityId"
                  className={`lp-select${errors?.fromEntityId?.message ? ' invalid' : ''}`}
                  endpointCall={() => getEntitiesSummary(EntityTypeFilter.NonHumanBeing)}
                  value={value}
                  onChange={val => { onChange(val?.id ?? null); onChangeEntity(val?.id); }}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.fromEntityId?.message && (errors.fromEntityId.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="phoneNumberIdsToCopy">
            <Form.Label>Phone Numbers</Form.Label>
            <Controller
              control={control}
              name="phoneNumberIdsToCopy"
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="phoneNumberIdsToCopy"
                  inputRef={ref}
                  getOptionLabel={(x: EntityPhoneNumberModel) => x.phoneNumber?.number + (x.phoneNumber?.extension ? ' (Extension: ' + x.phoneNumber?.extension + ')': '')}
                  options={phoneNumbers}
                  isLoading={isLoadingPhoneNumbers}
                  value={value}
                  onChange={val => onChange(val?.map((x: EntityPhoneNumberModel) => x.phoneNumberId))}
                  isMulti
                  isClearable
                />
              )}
            />
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="entityEmailIdsToCopy">
            <Form.Label>Email Addresses</Form.Label>
            <Controller
              control={control}
              name="entityEmailIdsToCopy"
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="entityEmailIdsToCopy"
                  inputRef={ref}
                  getOptionLabel={(x: EntityEmailModel) => x.email}
                  getOptionValue={(x: any) => x.id}
                  options={emails}
                  isLoading={isLoadingEmails}
                  value={value}
                  onChange={val => onChange(val?.map((x: { id: any; }) => x.id) ?? null)}
                  isMulti
                  isClearable
                />
              )}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="addressIdsToCopy">
            <Form.Label>Addresses</Form.Label>
            <Controller
              control={control}
              name="addressIdsToCopy"
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="addressIdsToCopy"
                  inputRef={ref}
                  getOptionLabel={(x: EntityAddressModel) => formatAddress(x.address)}
                  options={addresses}
                  isLoading={isLoadingAddresses}
                  value={value}
                  onChange={val => onChange(val?.map((x: EntityAddressModel) => x.addressId))}
                  isMulti
                  isClearable
                />
              )}
            />
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="entityDocumentExchangeIdsToCopy">
            <Form.Label>Document Exchanges</Form.Label>
            <Controller
              control={control}
              name="entityDocumentExchangeIdsToCopy"
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="entityDocumentExchangeIdsToCopy"
                  inputRef={ref}
                  getOptionLabel={(x: EntityDocumentExchangeModel) => x.dxExchange + ' (Number: ' + x.dxNumber + ')'}
                  getOptionValue={(x: any) => x.id}
                  options={documentExchanges}
                  isLoading={isLoadingDocumentExchanges}
                  value={value}
                  onChange={val => onChange(val?.map((x: { id: any; }) => x.id) ?? null)}
                  isMulti
                  isClearable
                />
              )}
            />
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Create" />
              : <Button variant="success" type="submit">Create</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
