import { vestResolver } from "@hookform/resolvers/vest";
import useSlidingPanelActions from "actions/slidingPanel";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { removeEmptyFields } from "utils/form";
import { getChargingBasis, getChargingRate, updateChargingRate } from "actions/chargingSchemes";
import Loader from "components/Loader/index";
import { Button, Form, Row, Col } from "react-bootstrap";
import CustomSelect from "components/Select/Select";
import { useAppSelector } from "hooks/appSelector";
import { CreateOrUpdateChargingRateLevelModel } from "models/create/CreateOrUpdateChargingRateLevelModel";
import { validationSuite } from "./validationSuite";
import Field from "components/Fields/Field";
import { ChargingRateModel } from "models/view/ChargingRateModel";
import { ChargingBasisIds } from "enums/ChargingBasisIds";
import useGridActions from "actions/grid";
import FormErrorButton from "components/Buttons/FormErrorButton";

type Props = {
  chargingRateId: string,
  onSubmitCallback?: Function
}

export default function UpdateChargingRate(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const [chargingRate, setChargingRate] = useState<ChargingRateModel>();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const navigate = useNavigate();

  const gridActions = useGridActions();
  const grid = useAppSelector((state) => state.grid);

  useEffect(() => {
    setIsLoading(true);
    getChargingRate(props.chargingRateId!).then((response) => {
      setChargingRate(response.data);
      reset(response.data);
    })
    .catch((error) => {
      if (error.response.status == 404 || error.response.status == 400) {
        navigate('/error404');
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const slidingPanelActions = useSlidingPanelActions();

  const { register, handleSubmit, watch, control, trigger, reset, formState: { errors } } = useForm<CreateOrUpdateChargingRateLevelModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateChargingRateLevelModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    updateChargingRate(props.chargingRateId, data).then((response) => {
      let index: number = grid.rowData.findIndex((x: any) => x.id === response.data.id);
      let newEntry: ChargingRateModel = {...response.data};
      let newArray: Array<any> = [...grid.rowData];
      newArray[index] = newEntry;
      gridActions.setGridRowData(newArray);
      slidingPanelActions.clearSlidingPanel();
      reset();

      props.onSubmitCallback && props.onSubmitCallback();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel && currentSlidingPanelState.onCancel();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId={`activityId`}>
            <Field
              label={"Activity"}
              value={chargingRate?.activity?.name}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId={`feeEarnerLevelId`}>
            <Field
              label={"Fee Earner Level"}
              value={chargingRate?.feeEarnerLevel?.name ?? "[All Levels]"}
            />
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId={`chargingBasisId`}>
            <Form.Label className="required">Charging Basis</Form.Label>
            <Controller
              control={control}
              name={`chargingBasisId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id={`chargingBasisId`}
                  inputRef={ref}
                  className={`lp-select${errors?.chargingBasisId?.message ? ' invalid' : ''}`}
                  endpointCall={getChargingBasis}
                  value={value}
                  onChange={val => {onChange(val?.id ?? null); trigger('minutesPerUnit')}}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.chargingBasisId?.message && (errors?.chargingBasisId?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId={`chargeRate`}>
            <Form.Label className="required">
              Charge rate
            </Form.Label>
            <Form.Control
              type="number"
              className={`${errors?.chargeRate?.message ? 'invalid' : ''}`}
              {...register(`chargeRate`, {shouldUnregister: true})}
              min="0"
              step="0.001"
              onWheel={e => e.currentTarget.blur()}
            />
            <Form.Text className="lp-error">
              {errors?.chargeRate?.message && (errors.chargeRate?.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId={`minutesPerUnit`}>
            <Form.Label
              className={`${(watch(`chargingBasisId`) == ChargingBasisIds.TimeId) ? " required" : ""}`}
            >
              Minutes per Unit
            </Form.Label>
            <Form.Control
              type="number"
              className={`${errors?.minutesPerUnit?.message ? 'invalid' : ''}`}
              {...register(`minutesPerUnit`, {shouldUnregister: true})}
              min="0"
              onWheel={e => e.currentTarget.blur()}
            />
            <Form.Text className="lp-error">
              {errors?.minutesPerUnit?.message && (errors.minutesPerUnit?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Update" />
              : <Button variant="success" type="submit">Update</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
