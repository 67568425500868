import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { getValidationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { getDateOnly } from "utils/date";
import { DateFormat } from "utils/constants";
import DatePicker from "react-datepicker";
import store from "state/store";
import { getUserSummaryForLte } from "actions/user";
import CustomSelect from "components/Select/Select";
import { useAppSelector } from "hooks/appSelector";
import { AdvancedGridFilterModel } from "models/view/AdvancedGridFilterModel";
import { GridAdvancedFilterDateOptions } from "enums/GridAdvancedFilterDateOptions";
import { getLteCurrentFinancialYear } from "actions/lte";
import { LteFinancialYearModel } from "models/view/LteFinancialYearModel";
import moment from "moment";
import { getGridAdvancedFilterDateOptionName } from "utils/misc";
import FormErrorButton from "components/Buttons/FormErrorButton";

type Props = {
  filterCallback: Function,
  showFeeEarnersFilter?: boolean,
  showDateFilter?: boolean,
  showDateOptionsFilter?: boolean,
  dateOptionsFilterValues?: GridAdvancedFilterDateOptions[]
}

export default function AdvancedGridFilterForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const loggedInUser = useAppSelector((state) => state.user);
  const [currentFinancialYear, setCurrentFinancialYear] = useState<LteFinancialYearModel | undefined>(undefined);
  const today = getDateOnly(moment().toDate());
  const startOfCurrentWeek = getDateOnly(moment().startOf('week').toDate());
  const endOfCurrentWeek = getDateOnly(moment().endOf('week').toDate());
  const startOfPreviousWeek = getDateOnly(moment().subtract(7, 'day').startOf('week').toDate());
  const endOfPreviousWeek = getDateOnly(moment().subtract(7, 'day').endOf('week').toDate());
  const startOfCurrentMonth = getDateOnly(moment().startOf('month').toDate());
  const endOfCurrentMonth = getDateOnly(moment().endOf('month').toDate());
  const startOfPreviousMonth = getDateOnly(moment().subtract(1, 'month').startOf('month').toDate());
  const endOfPreviousMonth = getDateOnly(moment().subtract(1, 'month').endOf('month').toDate());
  const startOfCurrentPrevious3Months = getDateOnly(moment().subtract(3, 'month').startOf('month').toDate());
  const endOfCurrentPrevious3Months = getDateOnly(moment().endOf('month').toDate());
  const startOfCurrentPrevious6Months = getDateOnly(moment().subtract(6, 'month').startOf('month').toDate());
  const endOfCurrentPrevious6Months = getDateOnly(moment().endOf('month').toDate());

  const {reset, control, setValue, watch, handleSubmit, formState: {errors}} = useForm<AdvancedGridFilterModel>({
    resolver: vestResolver(getValidationSuite(props.showFeeEarnersFilter, props.showDateFilter))
  });

  useEffect(() => {
    const gridState = store.getState().grid;
    setIsLoading(true);
    if(gridState.advancedFilter){
      let initialState: AdvancedGridFilterModel = {
        ...gridState.advancedFilter
      };

      reset(initialState);
    }

    if(props.showDateOptionsFilter && 
      (props.dateOptionsFilterValues?.includes(GridAdvancedFilterDateOptions.CurrentFinancialYear) || 
      props.dateOptionsFilterValues?.includes(GridAdvancedFilterDateOptions.PreviousFinancialYear))) {
      getLteCurrentFinancialYear()
        .then((response) => {
          setCurrentFinancialYear(response.data);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    else {
      setIsLoading(false);
    }
   
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: AdvancedGridFilterModel) {
    setIsLoading(true);
    removeEmptyFields(data);

    props.filterCallback(data);

    slidingPanelActions.clearSlidingPanel();
    reset();
    setIsLoading(false);
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  function getDateOptionsFilterValues(): {id: GridAdvancedFilterDateOptions, name: string}[] {
    let filterValues = props.dateOptionsFilterValues;
    
    if(filterValues && !filterValues?.includes(GridAdvancedFilterDateOptions.Custom)) {
      filterValues = [...props.dateOptionsFilterValues!, GridAdvancedFilterDateOptions.Custom];
    }

    const dropdownValues = filterValues?.map(x =>
      ({ id: x, name: getGridAdvancedFilterDateOptionName(x) })
    ) ?? [];
  
    return dropdownValues;
  }

  const onChangeDateOption = (dateOption?: GridAdvancedFilterDateOptions) => {
    const startOfPreviousFinancialYear = currentFinancialYear?.startDate ? moment(currentFinancialYear.startDate).subtract(1, 'year').toDate() : undefined;
    const endOfPreviousFinancialYear = currentFinancialYear?.endDate ? moment(currentFinancialYear.endDate).subtract(1, 'year').toDate() : undefined;

    switch(dateOption) {
      case undefined: {
        setValue("startDate", undefined);
        setValue("endDate", undefined);
        return;
      }
      case GridAdvancedFilterDateOptions.Today: {
        setValue("startDate", today);
        setValue("endDate", today);
        return;
      }
      case GridAdvancedFilterDateOptions.CurrentWeek: {
        setValue("startDate", startOfCurrentWeek);
        setValue("endDate", endOfCurrentWeek);
        return;
      }
      case GridAdvancedFilterDateOptions.PreviousWeek: {
        setValue("startDate", startOfPreviousWeek);
        setValue("endDate", endOfPreviousWeek);
        return;
      }
      case GridAdvancedFilterDateOptions.CurrentMonth: {
        setValue("startDate", startOfCurrentMonth);
        setValue("endDate", endOfCurrentMonth);
        return;
      }
      case GridAdvancedFilterDateOptions.PreviousMonth: {
        setValue("startDate", startOfPreviousMonth);
        setValue("endDate", endOfPreviousMonth);
        return;
      }
      case GridAdvancedFilterDateOptions.CurrentPrevious3Months: {
        setValue("startDate", startOfCurrentPrevious3Months);
        setValue("endDate", endOfCurrentPrevious3Months);
        return;
      }
      case GridAdvancedFilterDateOptions.CurrentPrevious6Months: {
        setValue("startDate", startOfCurrentPrevious6Months);
        setValue("endDate", endOfCurrentPrevious6Months);
        return;
      }
      case GridAdvancedFilterDateOptions.CurrentFinancialYear: {
        setValue("startDate", currentFinancialYear?.startDate);
        setValue("endDate", currentFinancialYear?.endDate);
        return;
      }
      case GridAdvancedFilterDateOptions.PreviousFinancialYear: {
        setValue("startDate", startOfPreviousFinancialYear);
        setValue("endDate", endOfPreviousFinancialYear);
        return;
      }
      case GridAdvancedFilterDateOptions.Custom: {
        return;
      }
      default: { 
        return;
      }
    }
  }

  const onChangeDates = (startDate?: Date, endDate?: Date) => {
    if(props.showDateOptionsFilter) {
      const startOfPreviousFinancialYear = currentFinancialYear?.startDate ? moment(currentFinancialYear.startDate).subtract(1, 'year').toDate() : undefined;
      const endOfPreviousFinancialYear = currentFinancialYear?.endDate ? moment(currentFinancialYear.endDate).subtract(1, 'year').toDate() : undefined;
      const startDateStr = startDate?.toDateString();
      const endDateStr = endDate?.toDateString();

      if(startDateStr == today.toDateString() && endDate?.toDateString() == today.toDateString() && 
        props.dateOptionsFilterValues?.includes(GridAdvancedFilterDateOptions.Today)) {
        setValue("dateOption", GridAdvancedFilterDateOptions.Today);
      }
      else if(startDateStr == startOfCurrentWeek.toDateString() && endDateStr == endOfCurrentWeek.toDateString() && 
        props.dateOptionsFilterValues?.includes(GridAdvancedFilterDateOptions.CurrentWeek)) {
        setValue("dateOption", GridAdvancedFilterDateOptions.CurrentWeek);
      }
      else if(startDateStr == startOfPreviousWeek.toDateString() && endDateStr == endOfPreviousWeek.toDateString() && 
        props.dateOptionsFilterValues?.includes(GridAdvancedFilterDateOptions.PreviousWeek)) {
        setValue("dateOption", GridAdvancedFilterDateOptions.PreviousWeek);
      }
      else if(startDateStr == startOfCurrentMonth.toDateString() && endDateStr == endOfCurrentMonth.toDateString() && 
        props.dateOptionsFilterValues?.includes(GridAdvancedFilterDateOptions.CurrentMonth)) {
        setValue("dateOption", GridAdvancedFilterDateOptions.CurrentMonth);
      }
      else if(startDateStr == startOfPreviousMonth.toDateString() && endDateStr == endOfPreviousMonth.toDateString() && 
        props.dateOptionsFilterValues?.includes(GridAdvancedFilterDateOptions.PreviousMonth)) {
        setValue("dateOption", GridAdvancedFilterDateOptions.PreviousMonth);
      }
      else if(startDateStr == startOfCurrentPrevious3Months.toDateString() && endDateStr == endOfCurrentPrevious3Months.toDateString() && 
        props.dateOptionsFilterValues?.includes(GridAdvancedFilterDateOptions.CurrentPrevious3Months)) {
        setValue("dateOption", GridAdvancedFilterDateOptions.CurrentPrevious3Months);
      }
      else if(startDateStr == startOfCurrentPrevious6Months.toDateString() && endDateStr == endOfCurrentPrevious6Months.toDateString() && 
        props.dateOptionsFilterValues?.includes(GridAdvancedFilterDateOptions.CurrentPrevious6Months)) {
        setValue("dateOption", GridAdvancedFilterDateOptions.CurrentPrevious6Months);
      }
      else if(startDateStr == currentFinancialYear?.startDate?.toDateString() && endDateStr == currentFinancialYear?.endDate?.toDateString() && 
        currentFinancialYear != undefined && props.dateOptionsFilterValues?.includes(GridAdvancedFilterDateOptions.CurrentFinancialYear)) {
        setValue("dateOption", GridAdvancedFilterDateOptions.CurrentFinancialYear);
      }
      else if(startDateStr == startOfPreviousFinancialYear?.toDateString() && endDateStr == endOfPreviousFinancialYear?.toDateString() && 
        currentFinancialYear != undefined && props.dateOptionsFilterValues?.includes(GridAdvancedFilterDateOptions.PreviousFinancialYear)) {
        setValue("dateOption", GridAdvancedFilterDateOptions.PreviousFinancialYear);
      }
      else if(startDateStr || endDateStr) {
        setValue("dateOption", GridAdvancedFilterDateOptions.Custom);
      }
      else {
        setValue("dateOption", undefined);
      }
    }
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          {props.showFeeEarnersFilter &&
            <Form.Group as={Col} sm={props.showDateOptionsFilter ? 6 : 12} controlId="feeEarnerIds">
              <Form.Label>Fee Earners</Form.Label>
              <Controller
                control={control}
                name="feeEarnerIds"
                shouldUnregister={true}
                render={({field: { onChange, value, ref }}) => (
                  <CustomSelect
                    id="feeEarnerIds"
                    inputRef={ref}
                    className={`lp-select${errors?.feeEarnerIds?.message ? ' invalid' : ''}`}
                    endpointCall={() => getUserSummaryForLte(loggedInUser.lawPageTradingEntityId!)}
                    value={value}
                    onChange={val => onChange(val?.map((x: { id: any }) => x.id) ?? null)}
                    isMulti
                    isClearable
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.feeEarnerIds?.message && (errors.feeEarnerIds.message)}
              </Form.Text>
            </Form.Group>
          }

          {props.showDateOptionsFilter && 
            <Form.Group as={Col} sm={props.showFeeEarnersFilter ? 6 : 12} className={props.showFeeEarnersFilter ? 'mt-4 mt-sm-0' : ''} controlId="dateOption">
              <Form.Label>Quick Select Dates</Form.Label>
              <Controller
                control={control}
                name="dateOption"
                shouldUnregister={true}
                render={({field: { onChange, value, name, ref }}) => (
                  <CustomSelect
                    id="dateOption"
                    inputRef={ref}
                    className={`lp-select${errors?.dateOption?.message ? ' invalid' : ''}`}
                    options={getDateOptionsFilterValues()}
                    value={value}
                    onChange={val => {onChange(val?.id ?? null); onChangeDateOption(val?.id);}}
                    isClearable
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.dateOption?.message && (errors.dateOption.message)}
              </Form.Text>
            </Form.Group>
          }
        </Row>

        {props.showDateFilter &&
          <Row>
            <Form.Group as={Col} sm={6} controlId="startDate">
              <Form.Label>Start Date</Form.Label>
              <Controller
                control={control}
                name="startDate"
                shouldUnregister={true}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    className={`${errors?.startDate?.message ? 'invalid' : ''}`}
                    id="date"
                    dateFormat={DateFormat.Datepicker}
                    selected={value ? getDateOnly(value) : null}
                    onChange={(val) => {onChange(val != null ? getDateOnly(val) : val); onChangeDates(val != null ? getDateOnly(val) : undefined, watch("endDate"));}}
                    showMonthDropdown
                    showYearDropdown
                    isClearable
                    autoComplete="off"
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.startDate?.message && (errors.startDate.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="endDate">
              <Form.Label>End Date</Form.Label>
              <Controller
                control={control}
                name="endDate"
                shouldUnregister={true}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    className={`${errors?.endDate?.message ? 'invalid' : ''}`}
                    id="date"
                    dateFormat={DateFormat.Datepicker}
                    selected={value ? getDateOnly(value) : null}
                    onChange={(val) => {onChange(val != null ? getDateOnly(val) : val); onChangeDates(watch("startDate"), val != null ? getDateOnly(val) : undefined);}}
                    showMonthDropdown
                    showYearDropdown
                    isClearable
                    autoComplete="off"
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.endDate?.message && (errors.endDate.message)}
              </Form.Text>
            </Form.Group>
          </Row>
        }

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Filter" />
              : <Button variant="success" type="submit">Filter</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
