import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { CreateOrUpdateLteTeamModel } from "models/create/CreateOrUpdateLteTeamModel";
import { Form, Button, Row, Col } from "react-bootstrap";
import { addLteTeam, getLteDepartments } from "actions/lte";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { useParams } from "react-router-dom";
import CustomSelect from "components/Select/Select";
import { validationSuite } from "./validationSuite";
import { useAppSelector } from "hooks/appSelector";
import FormErrorButton from "components/Buttons/FormErrorButton";

type Props = {
  submitCallback?: Function
}

export default function CreateLteTeamForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();

  const { id: lteId } = useParams();
  const user = useAppSelector((state) => state.user);

  const {register, reset, control, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateLteTeamModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateLteTeamModel) {
    setIsLoading(true);
    data.lteId = lteId ?? user.lawPageTradingEntityId!;
    addLteTeam(data).then((response) => {
      slidingPanelActions.clearSlidingPanel();
      if(props.submitCallback) {
        props.submitCallback(response.data);
      }
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} sm={6} controlId="name">
            <Form.Label className="required">Name</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.name?.message ? 'invalid' : ''}`}
              {...register("name", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.name?.message && (errors.name.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="lteDepartmentId">
            <Form.Label className="required">Department</Form.Label>
            <Controller
              control={control}
              name="lteDepartmentId"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <CustomSelect
                  id="lteDepartmentId"
                  inputRef={ref}
                  className={`lp-select${errors?.lteDepartmentId?.message ? ' invalid' : ''}`}
                  endpointCall={() => getLteDepartments(lteId ?? user.lawPageTradingEntityId!)}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.lteDepartmentId?.message && (errors.lteDepartmentId.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Create" />
              : <Button variant="success" type="submit">Create</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
