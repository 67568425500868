import SlidingPanel from "components/SlidingPanel/index";
import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect, useRef, useState } from "react";
import { getColumnDefs, viewTransactionCallback } from "./columnDefs";
import { Button, Card, Col, Row } from "react-bootstrap";
import Title from "components/Title/index";
import { getAccountBalanceSummary, getChartOfAccountsLedgerEntries, getLteCurrentFinancialYear } from "actions/lte";
import { getAccountById } from "actions/settings";
import { AccountModel } from "models/view/AccountModel";
import { useParams } from "react-router-dom";
import useSlidingPanelActions from 'actions/slidingPanel';
import CreateMatterReceiveMoneyForm from "containers/Matter/CreateMatterReceiveMoney/CreateMatterReceiveMoneyForm";
import { AccountKinds } from "enums/AccountKinds";
import { MdAddCircle } from "react-icons/md";
import { AdvancedGridFilterModel } from "models/view/AdvancedGridFilterModel";
import { getDateOnly } from "utils/date";
import AdvancedGridFilterForm from "components/Grid/GridFilters/AdvancedGridFilter/AdvancedGridFilterForm";
import { GridAdvancedFilterDateOptions } from "enums/GridAdvancedFilterDateOptions";
import { GridFilterTypes } from "enums/GridFilterTypes";
import { GridFilterButtonDataModel } from "models/view/GridFilterButtonDataModel";
import store from "state/store";
import Loader from "components/Loader/index";
import SummaryBoxesSection from "components/SummaryBoxesSection";
import SummaryBox from "components/SummaryBox";
import { SummaryBoxTypes } from "enums/SummaryBoxTypes";
import { AccountBalanceSummaryModel } from "models/view/AccountBalanceSummaryModel";
import CreateMatterReturnMoneyForm from "containers/Matter/CreateMatterReturnMoney/CreateMatterReturnMoneyForm";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { AgGridReact } from "ag-grid-react";
import { GridIds } from "enums/GridIds";
import { SummaryIds } from "enums/SummaryIds";
import usePageActions from "actions/page";

export default function ChartOfAccountsLedgerEntries() {
  const gridActions = useGridActions();
  const pageActions = usePageActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const [account, setAccount] = useState<AccountModel>();
  const [summaryInfo, setSummaryInfo] = useState<AccountBalanceSummaryModel | undefined>(undefined);
  const [isSummaryInfoLoading, setIsSummaryInfoLoading] = useState<boolean>(false);
  const [genericErrorsForSummaryInfo, setGenericErrorsForSummaryInfo] = useState(undefined);
  const loggedInUser = useAppSelector((state) => state.user);
  const gridRef = useRef<AgGridReact>(null);

  const { id: accountId } = useParams();
  
  const reloadGridData = () => {
    const gridState = store.getState().grid;
    gridAdvancedFilterCallback(gridState.advancedFilter);
  }

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs();
    gridActions.setGridColumnDefs([...columnDefs]);
    gridActions.setGridAdvancedFilterClearCallback(gridAdvancedFilterClearCallback);
  }, [accountId]);

  useEffect(() => {
    gridActions.setGridId(GridIds.ChartOfAccountsLedgerEntries);
    pageActions.setReloadGridData(reloadGridData);

    return () => {
      pageActions.resetPageData();
    }
  }, []);

  useEffect(() => {
    if(accountId) {
      const promises: Promise<any>[] = [];
      promises.push(getAccountById(accountId));
      promises.push(getLteCurrentFinancialYear());

      Promise.all(promises).then(([accountResponse, financialYearResponse]) => {
        setAccount(accountResponse.data);
        if(accountResponse.data.accountType?.partialBalance){
          const initialAdvancedFilter: AdvancedGridFilterModel = {
            dateOption: GridAdvancedFilterDateOptions.CurrentFinancialYear,
            startDate: financialYearResponse.data?.startDate,
            endDate: financialYearResponse.data?.endDate
          };

          gridAdvancedFilterCallback(initialAdvancedFilter);
        }
        else {
          gridAdvancedFilterCallback(undefined);
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      });
    }
    else {
      gridActions.setGridRowData([]);
    }
  }, [accountId]);

  const populateChartOfAccountsLedgerEntries = (accountId?: string, advancedFilter?: AdvancedGridFilterModel) => {
    if(accountId) {
      const filterStartDate = advancedFilter?.startDate ? getDateOnly(advancedFilter?.startDate) : undefined;
      const filterEndDate = advancedFilter?.endDate ? getDateOnly(advancedFilter?.endDate) : undefined;
    
      getChartOfAccountsLedgerEntries(accountId, filterStartDate, filterEndDate)
        .then((response) => {
          gridActions.setGridRowData(response.data);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
          gridActions.setGridRowData([]);
        });
    }
    else {
      gridActions.setGridRowData([]);
    }
  };

  const loadAccountSummaryInfo = (accountId?: string, advancedFilter?: AdvancedGridFilterModel) => {
    if(accountId) {
      setIsSummaryInfoLoading(true);
      const filterStartDate = advancedFilter?.startDate ? getDateOnly(advancedFilter?.startDate) : undefined;
      const filterEndDate = advancedFilter?.endDate ? getDateOnly(advancedFilter?.endDate) : undefined;

      getAccountBalanceSummary(accountId, filterStartDate, filterEndDate).then((response) => {
        setSummaryInfo(response.data);
      })
      .catch((error) => {
        setGenericErrorsForSummaryInfo(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsSummaryInfoLoading(false);
      });
    }
    else {
      setSummaryInfo(undefined);
    }
  }

  const addMatterReceiveMoney = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Create Receive Money",
        children: <CreateMatterReceiveMoneyForm
          accountId={accountId!}
        />
      }
    );
  };

  const addMatterReturnMoney = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Create Return Money",
        children: <CreateMatterReturnMoneyForm
          accountId={accountId!}
        />
      }
    );
  };

  const gridAdvancedFilterOnClickCallback = () => {
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Advanced Filter",
      children: <AdvancedGridFilterForm
        filterCallback={gridAdvancedFilterCallback}
        showDateFilter
        showDateOptionsFilter
        dateOptionsFilterValues={[
          GridAdvancedFilterDateOptions.CurrentPrevious3Months,
          GridAdvancedFilterDateOptions.CurrentPrevious6Months,
          GridAdvancedFilterDateOptions.CurrentFinancialYear,
          GridAdvancedFilterDateOptions.PreviousFinancialYear
        ]}
      />
    });
  };

  const gridFilters: GridFilterButtonDataModel[] = [
    {
      type: GridFilterTypes.AdvancedFilters,
      callback: gridAdvancedFilterOnClickCallback
    }
  ];

  const gridAdvancedFilterCallback = (advancedFilter?: AdvancedGridFilterModel) => {
    gridActions.setGridAdvancedFilter(advancedFilter);
    const nonEmptyFilterValues = [advancedFilter?.startDate, advancedFilter?.endDate].filter(x => x);
    if(nonEmptyFilterValues.length > 0) {
      gridActions.setGridAdvancedFilterInfo(<>{`${nonEmptyFilterValues.length} advanced filter(s) applied`}</>);
    }
    else {
      gridActions.setGridAdvancedFilterInfo(undefined);
    }

    populateChartOfAccountsLedgerEntries(accountId, advancedFilter);
    loadAccountSummaryInfo(accountId, advancedFilter);
  };

  const gridAdvancedFilterClearCallback = () => {
    gridAdvancedFilterCallback(undefined);
  };

  return (
    <>
      <div className="lp-page-content">
        <Title
          type="page"
          title={
            <>
              {account && <>Chart of Accounts Ledger Entries - {account?.displayName}</>}
              {!account && <>Chart of Accounts Ledger Entries</>}
            </>
          }
        >
          {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageChartOfAccounts) && 
            account && account.accountKind == AccountKinds.ClientCashAccount &&
            <>
              <Button variant="success" onClick={addMatterReceiveMoney}>
                <MdAddCircle />
                Receive Money
              </Button>
              <Button variant="success" onClick={addMatterReturnMoney}>
                <MdAddCircle />
                Return Money
              </Button>
            </>
          }
        </Title>

        {isSummaryInfoLoading &&
          <div className="lp-summary-boxes-placeholder">
            <Loader inlineLoader />
          </div>
        }

        {genericErrorsForSummaryInfo && (
          <div className="lp-errors">
            {genericErrorsForSummaryInfo}
          </div>
        )}

        {!isSummaryInfoLoading && 
          <Row>
            <Col>
              <SummaryBoxesSection id={SummaryIds.ChartOfAccountsLedgerEntriesSummary}>
                <SummaryBox
                  type={SummaryBoxTypes.Balance}
                  values={[{ value: summaryInfo?.balance ?? 0 }]}
                />
              </SummaryBoxesSection>
            </Col>
          </Row>
        }

        <Row className="h-100">
          <Col>
            <Title
              type="section"
              title={<>Ledger Entries</>}
            >
            </Title>
            
            {genericErrors && (
              <div className="lp-errors">
                {genericErrors}
              </div>
            )}

            <Card className="with-grid">
              <Card.Body>
                <Grid 
                  ref={gridRef}
                  onGridReady={onGridReady}
                  gridFilters={gridFilters}
                  getRowId={params => params.data?.accountLedgerTransactionEntryId}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <SlidingPanel gridRef={gridRef} onNavigation={(item: any) => viewTransactionCallback(item)} />
      </div>
    </>
  );
}
