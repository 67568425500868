import Field from "components/Fields/Field"
import { LteUserModel } from "models/view/LteUserModel";
import { DateFormat } from "utils/constants";
import moment from "moment";
import BooleanField from "components/Fields/BooleanField";
import { Card, Row, Col } from "react-bootstrap";
import { formatCurrency } from "utils/misc";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type Props = {
  user: LteUserModel | undefined
}

export default function ViewLteInfo(props: Props) {
  const user = useAppSelector((state) => state.user);
  
  return (
    <Card>
      <Card.Body>
        <Row className="multiple">
          <Col sm={6} lg={4} xxl={3}>
            <Field
              label={"Relationship with Business"}
              value={props.user?.employmentRelationshipType?.name}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <Field
              label={"Employment Start Date"}
              value={props.user?.employmentStartDate ?
                moment(props.user?.employmentStartDate).format(DateFormat.Moment) : "—"}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <Field
              label={"Employment End Date"}
              value={props.user?.employmentEndDate ?
                moment(props.user?.employmentEndDate).format(DateFormat.Moment) : "—"}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <Field
              label={"Job Title"}
              value={props.user?.jobTitle}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <Field
              label={"Branch"}
              value={props.user?.lteBranch?.name}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <Field
              label={"Department"}
              value={props.user?.lteDepartment?.name}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <Field
              label={"Team"}
              value={props.user?.lteTeam?.name}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <Field
              label={"Annual Holiday Entitlement"}
              value={props.user?.annualHolidayEntitlement?.toString()}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <BooleanField
              label={"Regular Pay Entitlement"}
              value={props.user?.regularPayEntitlement ?? false}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <BooleanField
              label={"Fee Incentive Scheme Entitlement"}
              value={props.user?.feeIncentiveSchemeEntitlement ?? false}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <BooleanField
              label={"Overtime"}
              value={props.user?.overtimeEntitlement ?? false}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <BooleanField
              label={"Non Fee Incentive Entitlement"}
              value={props.user?.nonFeeIncentiveEntitlement ?? false}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <Field
              label={"User Hourly Cost"}
              value={formatCurrency(props.user?.userHourlyCost)}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <Field
              label={"Annual Fee Income Target"}
              value={formatCurrency(props.user?.annualFeeIncomeTarget)}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <BooleanField
              label={"Fee Earner"}
              value={props.user?.isFeeEarner ?? false}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <Field
              label={"Default Fee Earner Level"}
              value={props.user?.defaultFeeEarnerLevel?.name}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <Field
              label={"Default Charging Scheme"}
              value={props.user?.defaultChargingScheme?.name}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <BooleanField
              label={"Undertakes Legal Aid Work"}
              value={props.user?.undertakesLegalAidWork ?? false}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <BooleanField
              label={"Active"}
              value={props.user?.active ?? false}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <Field
              label={"User Role"}
              value={props.user?.userRole.name}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <BooleanField
              label={"Manages Diary"}
              value={props.user?.managesDiary ?? false}
            />
          </Col>
          <Col sm={6} lg={4} xxl={3}>
            <Field
              label={"Additional User Permissions"}
              value={
                props.user?.userPermissions?.length == 0 ?
                  "—" :
                  props.user?.userPermissions?.map(a => a.name).join(", ")
              }
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
