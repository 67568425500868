import { ColDef, ColGroupDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { dateComparator } from "components/Grid/Comparators/DateComparator";
import { DateFormatter } from "components/Grid/ValueFormatters/DateFormatter";
import store from "state/store";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ChartOfAccountsLedgerEntryModel } from "models/view/ChartOfAccountsLedgerEntryModel";
import EditChartOfAccountsLedgerEntryForm from "./EditChartOfAccountsLedgerEntry/EditChartOfAccountsLedgerEntryForm";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { getLteBranchesSummary, getLteDepartmentsSummary, getLteTeamsSummary } from "actions/lte";
import ViewAccountLedgerTransaction from "components/SlidingPanel/ViewAccountLedgerTransaction/ViewAccountLedgerTransaction";

export const viewTransactionCallback = (rowData: ChartOfAccountsLedgerEntryModel) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        allowNavigation: true,
        title: "View Ledger Transaction",
        children: <ViewAccountLedgerTransaction
          accountLedgerTransactionId={rowData.accountLedgerTransactionId}
          accountLedgerTransactionEntryId={rowData.accountLedgerTransactionEntryId}
          editComponent={
            <EditChartOfAccountsLedgerEntryForm
              ledgerTransactionEntryId={rowData.accountLedgerTransactionEntryId}
            />
          }
          parentResourceData={{
            accountLedgerTransactionType: rowData.accountLedgerTransactionType,
            matterId: rowData.matterId,
            matterDisbursementId: rowData.matterDisbursementId,
            matterIncidentalExpenseId: rowData.matterIncidentalExpenseId,
            matterInvoiceId: rowData.matterInvoiceId,
            matterReceiveMoneyId: rowData.matterReceiveMoneyId,
            matterReturnMoneyId: rowData.matterReturnMoneyId,
            matterFundsTransferId: rowData.matterFundsTransferId
          }}
          showMatterInfo
        />
      }
    )
  );
}

export const getColumnDefs = () => {
  const columnDefs: (ColDef<ChartOfAccountsLedgerEntryModel> | ColGroupDef<ChartOfAccountsLedgerEntryModel> | {excludeFromExport: boolean})[] = [
    { 
      headerName: 'Date',
      field: 'accountLedgerTransactionDate',
      filter: 'agDateColumnFilter',
      sortable: false,
      valueFormatter: DateFormatter,
      filterParams: {
        comparator: dateComparator
      },
      minWidth: 120
    },
    { 
      headerName: 'Matter',
      field: 'matterFileNumberWithDisplayName',
      filter: 'agTextColumnFilter',
      sortable: false,
      minWidth: 180
    },
    { 
      headerName: 'Details',
      field: 'accountLedgerTransactionDescription',
      filter: 'agTextColumnFilter',
      sortable: false,
      minWidth: 400
    },
    {
      headerName: 'DR',
      field: 'drAmount',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      sortable: false,
      cellClass: 'text-end',
    },
    {
      headerName: 'CR',
      field: 'crAmount',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      sortable: false,
      cellClass: 'text-end',
    },
    {
      headerName: 'Balance',
      field: 'balance',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      sortable: false,
      cellClass: 'text-end',
    },
    { 
      headerName: 'Notes',
      field: 'accountLedgerTransactionEntryNotes',
      filter: 'agTextColumnFilter',
      sortable: false,
    },
    {
      headerName: 'Fee Earner',
      field: 'user.name',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    { 
      headerName: 'Branch',
      field: 'branch.name', 
      filter: DropdownFilter,
      filterParams: {
        property: 'branchId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getLteBranchesSummary
      },
      minWidth: 220
    },
    { 
      headerName: 'Department',
      field: 'department.name', 
      filter: DropdownFilter,
      filterParams: {
        property: 'departmentId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getLteDepartmentsSummary
      },
      minWidth: 220
    },
    { 
      headerName: 'Team',
      field: 'team.name', 
      filter: DropdownFilter,
      filterParams: {
        property: 'teamId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getLteTeamsSummary
      },
      minWidth: 220
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return { buttonsData: [
          {
            type: ActionButtonTypes.View,
            callback: () => viewTransactionCallback(params.data)
          },
        ]};
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 65,
      maxWidth: 65,
      width: 65,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];

  return columnDefs;
}