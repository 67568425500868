import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect, useState } from "react";
import { getColumnDefs } from "./columnDefs";
import { Card } from "react-bootstrap";
import Title from "components/Title/index";
import { getAccountTypes } from "actions/settings";
import SlidingPanel from "components/SlidingPanel/index";
import { GridIds } from "enums/GridIds";

export default function AccountTypeList() {
  const gridActions = useGridActions();
  const [genericErrors, setGenericErrors] = useState(null);

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs();
    gridActions.setGridColumnDefs([...columnDefs]);
    getAccountTypes().then((response) => 
    {
      gridActions.setGridRowData(response.data as any)
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  }, []); 

  useEffect(() => {
    gridActions.setGridId(GridIds.SettingsAccountTypes);
  }, []);

  return (
    <>
      <Title type="section" title={"Account Types"}>
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        <Card.Body>
          <Grid 
            onGridReady={onGridReady}
          />
        </Card.Body>
      </Card>

      <SlidingPanel />
    </>
  );
}
