import Title from "components/Title";
import { TabPanel } from "react-tabs";
import CustomTabs from "components/CustomTabs";
import ViewDiaryManagerList from "./ViewDiaryManagerList/ViewDiaryManagerList";
import ViewDiaryManagerCalendar from "./ViewDiaryManagerCalendar/ViewDiaryManagerCalendar";

export default function DiaryManagerCalendar() {
  return (
    <div className="lp-page-content">
      <Title type="page" title="Diary Manager Appointments"></Title>

      <CustomTabs
        tabList={[
          {
            name: 'Calendar',
            key: 'calendar',
            panel: <TabPanel>
              <ViewDiaryManagerCalendar />
            </TabPanel>
          },
          {
            name: 'List',
            key: 'list',
            panel: <TabPanel>
              <ViewDiaryManagerList />
            </TabPanel>
          }
        ]}
      />
    </div>
  );
}
