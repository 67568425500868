import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { ParticipatingEntityModel } from "models/view/ParticipatingEntityModel";
import { deleteParticipatingEntity, getParticipatingEntityById } from "actions/matter";
import { getPreferredContacts } from "actions/entity";
import { EntityPreferredContactModel } from "models/view/EntityPreferredContactModel";
import { EntityEmailModel } from "models/view/EntityEmailModel";
import { MdEmail, MdLocationPin, MdPhone } from "react-icons/md";
import { EntityPhoneNumberModel } from "models/view/EntityPhoneNumberModel";
import { formatAddress } from "utils/address";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Row, Col, Accordion } from 'react-bootstrap';
import { getInitialsForName } from "utils/form";
import moment from "moment";
import { DateFormat } from "utils/constants";
import BooleanField from "components/Fields/BooleanField";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import EditParticipatingEntityForm from "../EditParticipatingEntity/EditParticipatingEntityForm";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import useGridActions from "actions/grid";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type Props = {
  participatingEntityId: string,
  matterTypeId: string,
  isMatterClosed?: boolean,
}

function ViewParticipatingEntity(props: Props){
  const [loading, setLoading] = useState(true);
  const [genericErrors, setGenericErrors] = useState(null);
  const [participatingEntity, setParticipatingEntity] = useState<ParticipatingEntityModel>();
  const [preferredContacts, setPreferredContacts] = useState<EntityPreferredContactModel>();
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const modalActions = useModalActions();
  const gridActions = useGridActions();
  const currentGridState = useAppSelector((state) => state.grid);
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(()=>{
    setLoading(true);
    getParticipatingEntityById(props.participatingEntityId).then((responseParticipatingEntity) => {
      getPreferredContacts(responseParticipatingEntity?.data?.entityId ?? '').then((responsePreferredContacts) => {
        setParticipatingEntity(responseParticipatingEntity.data);
        setPreferredContacts(responsePreferredContacts.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setLoading(false);
      });
    })

    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatters) && !props.isMatterClosed) {
      // Set edit button action
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();

        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Participating Entity Details",
          children: <EditParticipatingEntityForm
            participatingEntityId={props.participatingEntityId}
            matterTypeId={props.matterTypeId}
            onSubmitCallback={onChildPanelClose}
          />,
          onCancel: onChildPanelClose
        });
      });

      // Set more buttons
      slidingPanelActions.setMoreButtons([{
        type: ActionButtonTypes.Delete,
        callback: () => onClickDelete(props.participatingEntityId),
      }]);
    }
  }, [props.participatingEntityId]);

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  };

  const onClickDelete = (id: string) => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Participating Entity?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(id),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };
  
  const deleteCallback = (id: string) => {
    const gridState = currentGridState;
    modalActions.toggleModalLoading();
    deleteParticipatingEntity(id).then(() => {
      gridActions.setGridRowData(gridState.rowData.filter((x: any) => x.id !== id));
      modalActions.toggleModalShownStatus();
      slidingPanelActions.clearSlidingPanel();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  const getInvoiceDueDateSettingString = () => {
    if(!participatingEntity?.invoiceDueDateSettingTypeId) {
      return "—";
    }
    else {
      return `${participatingEntity?.invoiceDueDateSettingNumber ?? "0"} ${participatingEntity?.invoiceDueDateSettingType?.name}`; 
    }
  };

  return(
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <div className="lp-entity-profile">
        <div className="lp-entity-photo">
          {getInitialsForName(participatingEntity?.entity?.displayName)}
          <span className={`lp-entity-status${participatingEntity?.isDefaultClient ? ' active' : ' inactive' }`}>
            {participatingEntity?.isDefaultClient ? <FaCheck /> : <FaTimes />}
          </span>
        </div>
        <div className="lp-entity-name">
          {participatingEntity?.entity.displayName}
        </div>
        <div className="lp-entity-role">
          {participatingEntity?.entityRole?.name}
        </div>
      </div>

      <div className="lp-entity-info">
        <Row className={(participatingEntity?.entityMatterReference || participatingEntity?.entity?.humanBeingDetail?.dateOfBirth) ? '' : 'd-none'}>
          {participatingEntity?.entityMatterReference &&
            <Col sm={6}>
              <Field
                label={"Matter Reference"}
                value={participatingEntity?.entityMatterReference ?? ""}
              />
            </Col>
          }
          {participatingEntity?.entity?.humanBeingDetail?.dateOfBirth &&
            <Col sm={6} className="mt-4 mt-sm-0">
              <Field
                label={"Date Of Birth"}
                value={moment(participatingEntity?.entity?.humanBeingDetail?.dateOfBirth).format(DateFormat.Moment)}
              />
            </Col>
          }
        </Row>
        
        <Row>
          {participatingEntity?.entity?.humanBeingDetail?.prisonNumber &&
            <Col sm={6}>
              <Field
                label={"Prison Number"}
                value={participatingEntity?.entity?.humanBeingDetail?.prisonNumber ?? ""}
              />
            </Col>
          }
          <Col sm={6} className={participatingEntity?.entity?.humanBeingDetail?.prisonNumber ? 'mt-4 mt-sm-0' : ''}>
            <BooleanField
              label={"Is Invoicing Party"}
              value={participatingEntity?.isInvoicingParty ?? false}
            />
          </Col>
        </Row>

        {participatingEntity?.isInvoicingParty && 
          <Row>
            <Col sm={6}>
              <Field
                label={"Purchase Order Reference"}
                value={participatingEntity?.purchaseOrderReference}
              />
            </Col>
            <Col sm={6} className="mt-4 mt-sm-0">
              <Field
                label={"Invoice Due Date"}
                value={getInvoiceDueDateSettingString()}
              />
            </Col>
          </Row>
        }

        {participatingEntity?.notes &&
          <Row>
            <Col>
              <Field
                label={"Notes"}
                value={participatingEntity?.notes ?? ""}
              />
            </Col>
          </Row>
        }
      </div>

      <div className="lp-entity-contact">
        {((preferredContacts?.entityEmails?.length ?? 0) == 0 && 
          (preferredContacts?.entityPhoneNumbers?.length ?? 0) == 0 &&
          (preferredContacts?.entityAddresses?.length ?? 0) == 0) &&
          <div className="lp-list-empty">No preferred contact info found.</div>
        }
        {preferredContacts?.entityEmails?.map((entityEmail: EntityEmailModel, index: number) => 
          <div className="lp-entity-contact-item" key={entityEmail.id}>
            <MdEmail />
            <span className="email">{entityEmail?.email}</span>
          </div>
        )}

        {preferredContacts?.entityPhoneNumbers?.map((entityPhoneNumber: EntityPhoneNumberModel, index: number) => 
          <div className="lp-entity-contact-item" key={entityPhoneNumber.phoneNumberId}>
            <MdPhone />
            {entityPhoneNumber?.phoneNumber?.number}
          </div>
        )}

        {preferredContacts?.entityAddresses?.map((entityAddress: any, index: number) => 
          <div className="lp-entity-contact-item" key={entityAddress.addressId}>
            <MdLocationPin />
            {formatAddress(entityAddress?.address, entityAddress?.sourceEntityAddress?.entity?.displayName)}
          </div>
        )}
      </div>

      {participatingEntity?.bailStatus !== undefined &&
        <div className="lp-entity-bails">
          <div className="lp-entity-bail-status">
            <div className="lp-entity-bail-status-title">Bail Status</div>
            <div className="lp-entity-bail-status-content">
              <div className="lp-entity-bail-status-value">{participatingEntity?.bailStatus?.name ?? ""}</div>
              <div className="lp-entity-bail-status-date">
                {participatingEntity?.custodyTimeLimitExpiryDate ? moment(participatingEntity?.custodyTimeLimitExpiryDate).format(DateFormat.Moment) : ''}
              </div>
            </div>
          </div>
          {(participatingEntity?.matterCriminalBailConditions !== undefined && participatingEntity?.matterCriminalBailConditions?.length > 0) &&
            <div className="lp-entity-bail-condition">
              <div className="lp-entity-bail-condition-title">Bail conditions</div>
              <Accordion>
                {participatingEntity?.matterCriminalBailConditions?.map((x: any, index: number) => {
                  return (
                    <Accordion.Item eventKey={`${index}`} key={x.id}>
                      <Accordion.Header>{x?.bailCondition?.name ?? ''}</Accordion.Header>
                      <Accordion.Body>{x?.additionalText ?? ''}</Accordion.Body>
                    </Accordion.Item>
                  )
                })}
              </Accordion>
            </div>
          }
        </div>
      }
    </>
  );
}

export default ViewParticipatingEntity;
