import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import { vestResolver } from '@hookform/resolvers/vest';
import { Form, Button, Row, Col } from 'react-bootstrap';
import useSlidingPanelActions from 'actions/slidingPanel';
import Loader from 'components/Loader';
import { validationSuite } from './validationSuite';
import { uploadFilesToSharepoint } from 'actions/matter';
import { UploadFilesToSharepointModel } from 'models/create/UploadFilesToSharepointModel';
import Upload from 'components/Upload';
import FormErrorButton from 'components/Buttons/FormErrorButton';

type Props = {
  matterId: string;
  syncMatterFiles: Function
};

export default function UploadMatterFilesForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UploadFilesToSharepointModel>({
    resolver: vestResolver(validationSuite),
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: UploadFilesToSharepointModel) {
    const formData = new FormData();

    for (let i = 0; i < data.files.length; i++) {
      formData.append('files', data.files[i]);
    }

    setIsLoading(true);
    
    uploadFilesToSharepoint(props.matterId, formData)
      .then(() => {
        props.syncMatterFiles();
        slidingPanelActions.clearSlidingPanel();
        reset();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  };

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="files">
            <Form.Label>Files</Form.Label>
            <Controller
              control={control}
              name="files"
              shouldUnregister={true}
              render={({ field: { onChange } }) => (
                <Upload
                  className={`${errors?.files?.message ? 'invalid' : ''}`}
                  onChange={(val) => onChange(val ?? null)}
                  isMulti
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.files?.message && errors.files.message}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Upload" />
              : <Button variant="success" type="submit">Upload</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>
              Cancel
            </Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
