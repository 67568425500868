import { useEffect, useState } from "react";
import { getLteBranch } from "actions/lte";
import Loader from "components/Loader/index";
import { LteBranchModel } from "models/view/LteBranchModel";
import Field from "components/Fields/Field";
import { LteDepartmentModel } from "models/view/LteDepartmentModel";
import { LteTeamModel } from "models/view/LteTeamModel";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import useSlidingPanelActions from "actions/slidingPanel";
import ViewLteDepartment from "../../LteDepartment/ViewLteDepartment/ViewLteDepartment";
import ViewLteTeam from "../../LteTeam/ViewLteTeam/ViewLteTeam";
import { Row, Col, Button } from "react-bootstrap";
import EditLteBranchForm from "../EditLteBranch/EditLteBranchForm";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import ListSlidingPanel from "components/List/ListSlidingPanel";
import { formatAddress } from "utils/address";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type Props = {
  id: string,
  canBeEdited?: boolean,
  deleteCallback: Function,
  deleteCallbackLteDepartment?: Function,
  deleteCallbackLteTeam?: Function,
  updateBranchesList?: Function
}

export default function ViewLteBranchForm(props: Props) {
  const [loading, setLoading] = useState(true);
  const [genericErrors, setGenericErrors] = useState(null);
  const [lteBranch, setLteBranch] = useState<LteBranchModel>();
  const [lteDepartments, setLteDepartments] = useState<LteDepartmentModel[]>();
  const [lteTeams, setLteTeams] = useState<LteTeamModel[]>();

  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const modalActions = useModalActions();
  const loggedInUser = useAppSelector((state) => state.user);

  const viewLteDepartment = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: `View Department`,
      children: <ViewLteDepartment departmentId={id} canBeEdited={props.canBeEdited} deleteCallback={props.deleteCallbackLteDepartment} />,
      onCancel: onChildPanelClose
    });
  };

  const viewLteTeam = (id: string) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: `View Team`,
      children: <ViewLteTeam teamId={id} canBeEdited={props.canBeEdited} deleteCallback={props.deleteCallbackLteTeam} />,
      onCancel: onChildPanelClose
    });
  };

  useEffect(() => {
    setLoading(true);
    getLteBranch(props.id).then((response) => {
      setLteBranch(response.data);
      setLteDepartments(response.data.children)
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setLoading(false);
    });

    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageAllLTEs || a == UserPermissionsNames.ManageLTE) && 
      props.canBeEdited) {
        slidingPanelActions.setOnEdit(editLteBranch);

        slidingPanelActions.setMoreButtons([{
          type: ActionButtonTypes.Delete,
          callback: () => deleteModal(),
        }]);
    }
  }, [props.id]);

  useEffect(() => {
    if(!lteDepartments) {
      return;
    }
    const lteTeamsFromDepartments: LteTeamModel[] = [];
    lteDepartments.forEach((x: LteDepartmentModel) => {
      if(x.children !== undefined) {
        lteTeamsFromDepartments.push(...x.children)
      }
    });
    setLteTeams(lteTeamsFromDepartments);
  }, [lteDepartments]);

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  };

  const editLteBranch = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: `Edit Branch`,
      children: <EditLteBranchForm 
                  branchId={props.id}
                  submitCallback={() => {
                    props.updateBranchesList && props.updateBranchesList();
                    onChildPanelClose();
                  }}
                />,
      onCancel: onChildPanelClose
    });
  };

  const deleteModal = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Branch?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => props.deleteCallback(props.id),
      show: true
    }
    slidingPanelActions.clearSlidingPanel();
    modalActions.setModal(modal);
   }; 
      
  return(
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Name"}
            value={lteBranch?.name ?? ""}
          />
        </Col>
        <Col>
          <Field
            label={"Phone Number"}
            value={lteBranch?.ltePhoneNumber?.phoneNumber ? (lteBranch?.ltePhoneNumber?.phoneNumber?.number + 
              (lteBranch?.ltePhoneNumber?.phoneNumber?.extension ? ' (Extension: ' + lteBranch?.ltePhoneNumber?.phoneNumber?.extension + ')': '')) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Address"}
            value={lteBranch?.lteAddress?.address ? formatAddress(lteBranch?.lteAddress?.address) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ListSlidingPanel
            listItems={lteDepartments ?? []}
            listButtons={
              [
                {
                  type: ActionButtonTypes.View,
                  callback: (id: string) => viewLteDepartment(id)
                },
              ]
            }
          >
            Departments included
          </ListSlidingPanel>
          <ListSlidingPanel
            listItems={lteTeams ?? []}
            listButtons={
              [
                {
                  type: ActionButtonTypes.View,
                  callback: (id: string) => viewLteTeam(id)
                },
              ]
            }
          >
            Teams included
          </ListSlidingPanel>
        </Col>
      </Row>
    </>
  );
}
