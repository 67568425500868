import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { getAccountLedgerTransactionEntryById, updateAccountLedgerTransactionEntry } from "actions/matter";
import useGridActions from "actions/grid";
import { useAppSelector } from "hooks/appSelector";
import { UpdateAccountLedgerTransactionEntryModel } from "models/update/UpdateAccountLedgerTransactionEntryModel";
import { AccountLedgerTransactionEntryModel } from "models/view/AccountLedgerTransactionEntryModel";
import Field from "components/Fields/Field";
import { getAccountIncreaseRepresentedByName } from "utils/enums";
import { ChartOfAccountsLedgerEntryModel } from "models/view/ChartOfAccountsLedgerEntryModel";
import { formatCurrency } from "utils/misc";
import FormErrorButton from "components/Buttons/FormErrorButton";

type Props = {
  ledgerTransactionEntryId: string,
  onSubmitCallback?: Function,
}

export default function EditChartOfAccountsLedgerEntryForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const gridActions = useGridActions();
  const grid = useAppSelector((state) => state.grid);
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const [accountLedgerTransactionEntry, setAccountLedgerTransactionEntry] = useState<AccountLedgerTransactionEntryModel>();

  const {reset, register, handleSubmit, formState: {errors}} = useForm<UpdateAccountLedgerTransactionEntryModel>({
    resolver: vestResolver(validationSuite)
  });

  useEffect(() => {
    setIsLoading(true);
    getAccountLedgerTransactionEntryById(props.ledgerTransactionEntryId).then((response) => {
      setAccountLedgerTransactionEntry(response.data);
      let initialState: UpdateAccountLedgerTransactionEntryModel = {
        notes: response.data.notes,
      };

      reset(initialState);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
      
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: UpdateAccountLedgerTransactionEntryModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    updateAccountLedgerTransactionEntry(props.ledgerTransactionEntryId, data)
      .then((response) => {
        let index: number = grid.rowData.findIndex((x: any) => x.accountLedgerTransactionEntryId === response.data.id);
        let accountLedgerTransactionEntry: AccountLedgerTransactionEntryModel = {...response.data};
        let newArray: Array<ChartOfAccountsLedgerEntryModel> = [...grid.rowData];
        let currentRow: ChartOfAccountsLedgerEntryModel = {...newArray[index]};
        currentRow.accountLedgerTransactionEntryNotes = accountLedgerTransactionEntry?.notes; 
        newArray[index] = currentRow;
        gridActions.setGridRowData(newArray);
        slidingPanelActions.clearSlidingPanel();
        reset();

        props.onSubmitCallback && props.onSubmitCallback();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel && currentSlidingPanelState.onCancel();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Col>
            <Field
              label={"Matter"}
              value={accountLedgerTransactionEntry?.matter?.name}
            />
          </Col>
          <Col>
            <Field
              label={"Details"}
              value={accountLedgerTransactionEntry?.accountLedgerTransaction?.name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Account Name"}
              value={accountLedgerTransactionEntry?.account?.displayName}
            />
          </Col>
          <Col>
            <Field
              label={"Fee Earner"}
              value={accountLedgerTransactionEntry?.user?.name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              label={"Amount"}
              value={formatCurrency(accountLedgerTransactionEntry?.amount)}
            />
          </Col>
          <Col>
            <Field
              label={"Type"}
              value={accountLedgerTransactionEntry?.entryType ? getAccountIncreaseRepresentedByName(accountLedgerTransactionEntry.entryType) : "—"}
            />
          </Col>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="note">
            <Form.Label className="required">Notes</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              className={`${errors?.notes?.message ? 'invalid' : ''}`}
              {...register("notes", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.notes?.message && (errors.notes.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Update" />
              : <Button variant="success" type="submit">Update</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
