import Field from "components/Fields/Field";
import { Card, Row, Col } from "react-bootstrap";
import { LteGeneralSettingsModel } from "models/view/LteGeneralSettingsModel";
import Loader from "components/Loader/index";
import { DateFormat } from "utils/constants";
import moment from "moment";

type Props = {
  lteGeneralSettings: LteGeneralSettingsModel | undefined,
  isLoading: boolean
}

export default function ViewLteGeneralSettingsSummary(props: Props) {

  const getInvoiceDueDateSettingString = () => {
    if(!props.lteGeneralSettings?.invoiceDueDateSettingTypeId) {
      return "—";
    }
    else {
      return `${props.lteGeneralSettings?.invoiceDueDateSettingNumber ?? "0"} ${props.lteGeneralSettings?.invoiceDueDateSettingType?.name}`; 
    }
  };

    
  return (
    <>
      <Card>
        {props.isLoading && <Loader inlineLoader />}
        <Card.Body>
          <Row>
            <Col>
              <Field
                label={"Matter Data Destruction Date (years)"}
                value={props.lteGeneralSettings?.matterDestructionDateSetting}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Field
                label={"Invoice Due Date"}
                value={getInvoiceDueDateSettingString()}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Field
                label={"Invoice Display Name"}
                value={props.lteGeneralSettings?.invoiceDisplayNameSetting}
              />
            </Col>
            <Col sm={6} className="mt-4 mt-sm-0">
              <Field
                label={"Financial Year End"}
                value={props.lteGeneralSettings?.financialYearEnd ? moment(props.lteGeneralSettings?.financialYearEnd).format(DateFormat.MomentWithoutYear) : "—"}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
