import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import { useParams } from "react-router-dom";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { getEntityNote, updateEntityNote } from "actions/entity";
import { CreateOrUpdateEntityNoteModel } from "models/create/CreateOrUpdateEntityNoteModel";
import { removeEmptyFields } from "utils/form";
import FormErrorButton from "components/Buttons/FormErrorButton";

type Props = {
  entityId: string,
  noteId: string,
  submitCallback?: Function
}

export default function EditEntityNoteForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const {id: entityId} = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();

  useEffect(() => {
    setIsLoading(true);
    getEntityNote(props.noteId).then( (response) => {
      reset(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const {register, reset, control, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateEntityNoteModel>({
    resolver: vestResolver(validationSuite)
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateEntityNoteModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    data.entityId = props.entityId;
    updateEntityNote(props.noteId, data).then((response) => {
      if(props.submitCallback) {
        props.submitCallback();
      }
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="note">
            <Form.Label className="required">Note</Form.Label>
            <Form.Control
              as="textarea"
              rows={10}
              className={`${errors?.note?.message ? 'invalid' : ''}`}
              {...register("note", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.note?.message && (errors.note.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Update" />
              : <Button variant="success" type="submit">Update</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
