import useGridActions from "actions/grid";
import { useCallback, useEffect, useRef, useState } from "react";
import { Card, Form } from "react-bootstrap";
import Title from "components/Title";
import DatePicker from "react-datepicker";
import SlidingPanel from "components/SlidingPanel";
import Loader from "components/Loader";
import { DateFormat } from "utils/constants";
import moment from "moment";
import { getDateOnly } from "utils/date";
import Grid from "components/Grid";
import { getFreeBusySchedule } from "actions/lte";
import CustomSelect from "components/Select/Select";
import { getAvailabilityViewIntervalValues } from "utils/misc";
import { FreeBusyIntervalModel, FreeBusyScheduleModel } from "models/view/FreeBusyScheduleModel";
import FreeBusyCellRenderer from "./FreeBusyCellRenderer";
import { AvailabilityViewInterval } from "enums/AvailabilityViewInterval";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { useAppSelector } from "hooks/appSelector";
import { GridState } from "state/gridSlice";
import { GridIds } from "enums/GridIds";
import "./style.scss";
import { getUserCalendarSettings } from "actions/user";
import { UserCalendarSettingsModel } from "models/view/UserCalendarSettingsModel";
import { DayOfWeek } from "enums/DayOfWeek";

export default function FreeBusyCalendar() {
  const gridActions = useGridActions();
  const [genericErrors, setGenericErrors] = useState<string | undefined>();
  const grid = useAppSelector((state) => state.grid);
  const gridRef = useRef<GridState>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [asyncColumnDefsLoaded, setAsyncColumnDefsLoaded] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [userCalendarSettings, setUserCalendarSettings] = useState<UserCalendarSettingsModel>();
  const [excludeWeekends, setExcludeWeekends] = useState<boolean>(true);
  const [onlyWorkingHours, setOnlyWorkingHours] = useState<boolean>(true);
  const [availabilityViewInterval, setAvailabilityViewInterval] = useState<string>(AvailabilityViewInterval.Hours12);
  const isFirstRender = useRef<boolean>(false);
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    gridActions.setGridId(GridIds.FreeBusyCalendar);
  }, []);

  const getUsersAsFakePromise = useCallback(() => {
    //the reference is used in order to always have the updated value when this function gets passed to floatingFilterComponentParams:
    if(!gridRef.current?.rowData || gridRef.current.rowData.length == 0) {
      return Promise.resolve({
        data: []
      });
    }

    const dataArray = gridRef.current.rowData.map(x => ({
      id: x.scheduleId,
      name: x.scheduleId
    }));
  
    return Promise.resolve({
      data: dataArray
    });
  }, []);

  const setGridData = (startDate: Date, endDate: Date, use24HourFormat: boolean) => {
    setIsLoading(true);
    const userTimeZone = moment.tz.guess();
    getFreeBusySchedule(startDate!.toISOString(), endDate!.toISOString(), availabilityViewInterval, userTimeZone, onlyWorkingHours, excludeWeekends).then((response) => {
      const columnDefs: any[] = [
        {
          headerName: "User",
          field: "scheduleId",
          filter: DropdownFilter,
          filterParams: {
            property: "scheduleId",
            suppressFilterButtons: false,
          },
          floatingFilterComponent: DropdownFloatingFilter,
          floatingFilterComponentParams: {
            endpointCall: getUsersAsFakePromise,
          },
          minWidth: 250,
          lockPosition: true,
          sortable: false,
          suppressSizeToFit: true
        }
      ];

      if(response.data.length > 0){
        response.data[0].freeBusyIntervals.forEach((interval: FreeBusyIntervalModel, index: number) => {
          const intervalStart = moment(interval.intervalStart);
          const intervalEnd = moment(interval.intervalEnd);
          const currentDay = columnDefs.find((def: any) => def.headerName === intervalStart.format(DateFormat.FreeBusyHeaderDate));

          // Create current day if it doesn't exist
          if(!currentDay) {
            columnDefs.push({
              headerName: intervalStart.format(DateFormat.FreeBusyHeaderDate),
              children: [],
              marryChildren: true,
              colId: `${index}_parent`,
            });
          }

          // Add interval to current day as children
          const fieldName: string = intervalStart.format(DateFormat.MomentWithTimeNoSpaces);
          columnDefs.find((def: any) => def.headerName === intervalStart.format(DateFormat.FreeBusyHeaderDate)).children.push({
            headerName: availabilityViewInterval === AvailabilityViewInterval.Hours12 && !onlyWorkingHours
              ? intervalStart.format(DateFormat.Meridiem)
              : `${intervalStart.format(use24HourFormat ? DateFormat.Moment24HourTime : DateFormat.MomentTime)} - ${intervalEnd.format(use24HourFormat ? DateFormat.Moment24HourTime : DateFormat.MomentTime)}`,
            field: fieldName,
            cellRenderer: FreeBusyCellRenderer,
            cellRendererParams: {
              columnKey: fieldName,
              use24HourFormat: use24HourFormat
            },
            lockPosition: true,
            valueGetter: (params: any) => {
              if (params.data[fieldName]) {
                  return params.data[fieldName].status;
              }
              return 'Error';
            },
            minWidth: 135,
            sortable: false,
            floatingFilter: false,
            colId: index,
          });
        });
      }

      response.data.forEach((schedule: FreeBusyScheduleModel) => {
        schedule.freeBusyIntervals.forEach((interval: FreeBusyIntervalModel) => {
          const intervalStart = moment(interval.intervalStart).format(DateFormat.MomentWithTimeNoSpaces);
          (schedule as any)[intervalStart] = interval;
        });
      });

      gridActions.setGridColumnDefs(columnDefs);
      gridActions.setGridRowData(response.data);
      setAsyncColumnDefsLoaded(true);
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridColumnDefs([]);
      gridActions.setGridRowData([]);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if(startDate && endDate && startDate >= endDate) {
      setGenericErrors("Start Date cannot be after End Date");
      return;
    }

    setGenericErrors(undefined);

    if(isFirstRender.current) {
      setGridData(startDate!, endDate!, userCalendarSettings?.timeFormatIs24Hours ?? false);
    }
    else {
      isFirstRender.current = true;
    }
  }, [availabilityViewInterval, startDate, endDate, onlyWorkingHours, excludeWeekends]);

  // On load get calendar settings and default date range
  useEffect(() => {
    getUserCalendarSettings(user.userId!).then((response) => {
      setUserCalendarSettings(response.data);
      let weekStartDate = moment().day(DayOfWeek[response.data.firstDayOfWeek]).startOf('day');
      if(weekStartDate.isAfter(moment())) {
        weekStartDate = weekStartDate.subtract(7, 'days').startOf('day');
      }
      const weekEndDate = weekStartDate.clone().add(6, 'days').endOf('day');

      // After changing startDate and endDate state, another useEffect will trigger the grid data load
      setStartDate(weekStartDate.toDate());
      setEndDate(weekEndDate.toDate());
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    });
  }, []);

  useEffect(() => {
    gridRef.current = grid;
  }, [grid]);

  return (
    <div className="lp-page-content">
      <>
        <Title
          type="page"
          title="Free/Busy Calendar"
        >
        </Title>

        <SlidingPanel />

        {genericErrors && (
          <div className="lp-errors">
            {genericErrors}
          </div>
        )}

        <Card className="with-grid">
          {isLoading && <Loader inlineLoader />}

          <Card.Body>
            <div className="lp-calendar-date appointments free-busy">
              {!onlyWorkingHours &&
                <CustomSelect
                  id="availabilityViewInterval"
                  menuPlacement="bottom"
                  placeholder="Select Interval"
                  options={getAvailabilityViewIntervalValues()}
                  onChange={(val) => setAvailabilityViewInterval(val?.id ?? null)}
                  value={availabilityViewInterval}
                />
              }
              <span>From</span>
              <DatePicker 
                dateFormat={DateFormat.Datepicker}
                value={moment(startDate).format(DateFormat.Moment)}
                selected={startDate ? getDateOnly(startDate) : null}
                onChange={(date: Date) => {setStartDate(moment(date).startOf('day').toDate())}}
                showMonthDropdown
                showYearDropdown
                autoComplete="off"
              />
              <span>to</span>
              <DatePicker 
                dateFormat={DateFormat.Datepicker}
                value={moment(endDate).format(DateFormat.Moment)}
                selected={endDate ? getDateOnly(endDate) : null}
                onChange={(date: Date) => {setEndDate(moment(date).startOf('day').toDate())}}
                showMonthDropdown
                showYearDropdown
                autoComplete="off"
              />

              <Form className="d-flex gap-2">
                <Form.Group className="d-flex gap-2">
                  <Form.Label>Work Hours Only</Form.Label>
                    <Form.Check type="switch">
                      <Form.Check.Input
                        className= "form-check-input"
                        checked={onlyWorkingHours}
                        onChange={(ev: any) => setOnlyWorkingHours(ev.target.checked)}
                      />
                    </Form.Check>
                </Form.Group>

                <Form.Group className="d-flex gap-2">
                  <Form.Label>Ignore Weekends</Form.Label>
                    <Form.Check type="switch">
                      <Form.Check.Input
                        className= "form-check-input"
                        checked={excludeWeekends}
                        onChange={(ev: any) => setExcludeWeekends(ev.target.checked)}
                      />
                    </Form.Check>
                </Form.Group>
              </Form>
            </div>
            <Grid
              asyncColumnDefsLoaded={asyncColumnDefsLoaded}
              skipRowIdCheck={true}
            />
          </Card.Body>
        </Card>
      </>
    </div>
  )
}
