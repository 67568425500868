import SlidingPanel from "components/SlidingPanel/index";
import { useCallback, useEffect, useState } from 'react';
import CustomCalendar from 'components/Calendar/CustomCalendar';
import { Button, Card } from 'react-bootstrap';
import Title from 'components/Title';
import moment from 'moment';
import { MdFilterAlt, MdRefresh } from 'react-icons/md';
import { CalendarEventModel } from 'models/CalendarEventModel';
import useSlidingPanelActions from 'actions/slidingPanel';
import { getAvailableLtesForDiaryManagerFilter, getDiaryManagerEvents } from 'actions/lte';
import { View, Views } from 'react-big-calendar';
import ViewCalendarEvent from "../../ViewCalendarEvent/ViewCalendarEvent";
import CalendarFilterForm from "components/CalendarFilter/CalendarFilterForm";
import { DiaryManagerCalendarEventModel } from "models/DiaryManagerCalendarEventModel";
import _ from "lodash";
import { CalendarFilterModel } from "models/view/CalendarFilterModel";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import './style.scss';
import { CalendarIds } from "enums/CalendarIds";
import { FaTimesCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { UserCalendarSettingsModel } from "models/view/UserCalendarSettingsModel";
import { useAppSelector } from "hooks/appSelector";
import { getUserCalendarSettings } from "actions/user";
import { DayOfWeek } from "enums/DayOfWeek";

function ViewDiaryManagerCalendar() {
  const localStorageCalendarFilterKey = `Calendar_${CalendarIds.DiaryManagerCalendar}_filter`;
  const { tab } = useParams();

  const [events, setEvents] = useState<DiaryManagerCalendarEventModel[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<DiaryManagerCalendarEventModel[]>([]);
  const [userLtes, setUserLtes] = useState<DropDownOptionModel[]>([]);
  const slidingPanelActions = useSlidingPanelActions();
  const [startDate, setStartDate] = useState<Date>(
    moment().startOf('week').toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().endOf('week').toDate()
  );
  const [loading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const [view, setView] = useState<View>(Views.WEEK);
  const [windowOnFocus, setWindowOnFocus] = useState(true);
  const user = useAppSelector((state) => state.user);
  const [userCalendarSettings, setUserCalendarSettings] = useState<UserCalendarSettingsModel>();

  const [appliedFilters, setAppliedFilters] = useState<CalendarFilterModel>(() => {
    // Get saved filters and tabs loaded on current page
    const savedFiltersJSON = localStorage.getItem(localStorageCalendarFilterKey);
    const sessionLoadedTabsJSON = localStorage.getItem('sessionLoadedTabKeys');

    // If there are filters and the tab was already loaded, set the state to the saved filters
    if(savedFiltersJSON && sessionLoadedTabsJSON) {
      const savedFilters = JSON.parse(savedFiltersJSON);
      const sessionLoadedTabs = JSON.parse(sessionLoadedTabsJSON);
      if(sessionLoadedTabs.includes(tab)) {
        return savedFilters;
      }
    }
    return undefined;
  });

  const checkTokensOnFocus = () => {
    setWindowOnFocus(true);
  };

  const onBlur = () => {
    setWindowOnFocus(false);
  };

  useEffect(() => {
    window.addEventListener('focus', checkTokensOnFocus);
    return () => {
      window.removeEventListener('focus', checkTokensOnFocus);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  const getEventsForInterval = useCallback(() => {
    if(startDate != null && endDate != null) {
      setIsLoading(true);
      const userTimeZone = moment.tz.guess();

      Promise.all([
        getDiaryManagerEvents(startDate.toISOString(), endDate.toISOString(), userTimeZone),
        getAvailableLtesForDiaryManagerFilter()
      ]).then(([eventsResponse, ltesResponse]) => {
        setEvents(eventsResponse.data);
        setUserLtes(ltesResponse.data);

        // Set filtered events based on already applied filters
        // Send an empty object if there are no filters loaded into appliedFilters
        // This function will populate filteredEvents state and it will also
        // overwrite the filters from localStorage when they are no longer needed (ex. page refresh)
        applyEventFilters(appliedFilters ?? {}, eventsResponse.data);

        //clear previous errors
        setGenericErrors(null);
      }).catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    setIsLoading(true);
    getUserCalendarSettings(user.userId!).then((calendarSettings) => {
      setUserCalendarSettings(calendarSettings.data);

      // Get events after getting user calendar settings to prevent events from jumping around
      getEventsForInterval();
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    });
  }, [getEventsForInterval]);

  const onRangeChange = (range: Date[] | { start: Date; end: Date }) => {
    setIsLoading(true);
    setEvents([]);

    if(Array.isArray(range)) {
      setStartDate(range[0]);
      if(range.length === 1) {
        const newEndDate = moment(range[0]).add(1, 'day');
        setEndDate(newEndDate.toDate());
      }
      else {
        setEndDate(moment(range[range.length - 1]).add(1, 'day').toDate());
      }
    }
    else {
      setStartDate(range.start);
      setEndDate(moment(range.end).add(1, 'day').toDate());
    }
  };

  const handleEventClick = (event: any) => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'View Calendar Event',
      children: <ViewCalendarEvent eventData={event} />
    });
  };

  const handleFilterClick = () => {
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: 'Filter Calendar',
      children: <CalendarFilterForm
        filterCallback={(filters: CalendarFilterModel) => applyEventFilters(filters, events)}
        lteList={userLtes}
        localStorageCalendarFilterKey={localStorageCalendarFilterKey}
      />
    });
  };

  const applyEventFilters = (filters: CalendarFilterModel, originalEvents: DiaryManagerCalendarEventModel[]) => {
    const filteredEvents = originalEvents.filter(event => {
      return (!filters.ltes || filters.ltes.includes(event.lawPageTradingEntity?.id!)) &&
        (!filters.location || (event.location && event.location.toLowerCase().includes(filters.location.toLowerCase()))) &&
        (!filters.attendee
          || event.requiredAttendees.some(e => e.toLowerCase().includes(filters.attendee!.toLowerCase()))
          || event.optionalAttendees.some(e => e.toLowerCase().includes(filters.attendee!.toLowerCase()))
        );
    });

    localStorage.setItem(localStorageCalendarFilterKey, JSON.stringify(filters));
    setAppliedFilters(filters);
    setFilteredEvents(filteredEvents);
  };

  const getFiltersAsString = () => {
    let filtersString = '';
    if(appliedFilters?.ltes?.length) {
      const ltes = userLtes.filter(lte => appliedFilters.ltes!.includes(lte.id)).map(lte => lte.name);
      filtersString += `Filtered LTEs: ${ltes.join(', ')}`;
    }
    if(appliedFilters?.location?.length) {
      filtersString += `${filtersString ? ' | ' : ''}Filtered Locations: ${appliedFilters.location}`;
    }
    if(appliedFilters?.attendee?.length) {
      filtersString += `${filtersString ? ' | ' : ''}Filtered Attendees: ${appliedFilters.attendee}`;
    }
    return filtersString;
  }

  const calendarFilterClear = () => {
    localStorage.removeItem(localStorageCalendarFilterKey);
    setAppliedFilters({});
    applyEventFilters({}, events);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if(windowOnFocus) {
        getEventsForInterval();
      }
    }, 300000);

    return () => clearInterval(interval);
  }, [getEventsForInterval, windowOnFocus]);

  return (
    <>
      <Title
        type="section"
        title={<>Diary Manager Calendar</>}
      >
        <Button onClick={handleFilterClick} className="btn-icon" variant={appliedFilters && Object.keys(appliedFilters).length ? "success" : "secondary-400"}>
          <MdFilterAlt />
        </Button>
        <Button onClick={getEventsForInterval} className="btn-icon" variant="primary">
          <MdRefresh />
        </Button>
      </Title>

      {genericErrors && <div className="lp-errors calendar-errors">{genericErrors}</div>}

      <Card className="with-calendar">
        <Card.Body className="lp-diary-manager-calendar">
          {appliedFilters && Object.keys(appliedFilters).length ? (
            <div className="lp-calendar-filters-info">
              <div>{getFiltersAsString()}</div>
              <Button variant='secondary-400' onClick={() => calendarFilterClear()}>
                <FaTimesCircle />
                Clear
              </Button>
            </div>
          ) : null }
          <div className="calendar-container">
            <CustomCalendar
              handleEventClick={handleEventClick}
              onRangeChange={onRangeChange}
              events={filteredEvents as CalendarEventModel[]}
              showOtherLtesIcon={true}
              isLoading={loading}
              view={view}
              setView={setView}
              firstDayOfWeek={userCalendarSettings?.firstDayOfWeek ? DayOfWeek[userCalendarSettings.firstDayOfWeek as keyof typeof DayOfWeek] ?? 0 : 0}
            />
          </div>
        </Card.Body>
      </Card>

      <SlidingPanel />
    </>
  );
}

export default ViewDiaryManagerCalendar;
