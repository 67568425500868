import Tippy from '@tippyjs/react';
import { CalendarEventModel } from 'models/CalendarEventModel';
import { EventProps } from 'react-big-calendar';
import { MdLock, MdRefresh } from 'react-icons/md';
import { FaUser } from "react-icons/fa";

export default function CustomEvent(props: EventProps, isUserLte: boolean) {
  return (
    <Tippy
      content={(props.event as any)?.lawPageTradingEntity?.name
        ? `[${(props.event as any).lawPageTradingEntity.name}] ${props.title}`
        : props.title
      }
      className="lp-calendar-event-tooltip"
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      duration={100}
      placement="bottom-start"
      trigger="mouseenter"
    >
      {/* title="" disables the tooltip */}
      <div title="">
        {!isUserLte && <span className="lp-calendar-event-lte-icon"><FaUser /></span>}
        <span>{props.title}</span>
        {(props.event as CalendarEventModel).private && <MdLock />}
        {!(props.event as CalendarEventModel).isSingleInstance && <MdRefresh />}
      </div>
    </Tippy>
  );
}
