import { useState } from 'react';
import Loader from 'components/Loader';
import { Form, Button, Row, Col } from 'react-bootstrap';
import CustomSelect from 'components/Select/Select';
import {
  addEmailAttachments,
  getEmailAttachmentsSuggestions,
} from 'actions/matter';
import useSlidingPanelActions from 'actions/slidingPanel';
import { useAppSelector } from 'hooks/appSelector';
import usePageActions from 'actions/page';
import FormErrorButton from 'components/Buttons/FormErrorButton';

type Props = {
  matterId: string;
  emailId: string;
  syncMatterFiles?: Function;
  onSubmitCallback?: Function;
};

function SaveAttachments(props: Props) {
  const [attachmentsIds, setAttachmentsIds] = useState<string[]>();
  const [genericErrors, setGenericErrors] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const pageActions = usePageActions();

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();

    currentSlidingPanelState.onCancel !== undefined && currentSlidingPanelState.onCancel();
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (attachmentsIds == null || attachmentsIds == undefined || attachmentsIds.length == 0) {
      return setGenericErrors('Attachments cannot be null.');
    }
    setIsLoading(true);
    addEmailAttachments(
      props.matterId,
      props.emailId,
      attachmentsIds
    )
      .then(() => {
        props.syncMatterFiles && props.syncMatterFiles();

        slidingPanelActions.clearSlidingPanel();

        props.onSubmitCallback && props.onSubmitCallback();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="files">
            <Form.Label className="required">Attachments:</Form.Label>
            <CustomSelect
              id="attachmentsIds"
              endpointCall={() =>
                getEmailAttachmentsSuggestions(props.matterId, props.emailId)
              }
              onChange={(val) =>
                setAttachmentsIds(val?.map((x: { id: any }) => x.id) ?? null)
              }
              value={attachmentsIds}
              isMulti
              isClearable
            />
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { genericErrors
              ? <FormErrorButton text="Upload" />
              : <Button variant="success" type="submit">Upload</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>
              Cancel
            </Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}

export default SaveAttachments;
