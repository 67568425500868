import { useDispatch } from "react-redux";
import {
  setReloadGrid,
  setReloadSummary,
  resetPage
} from "../state/pageSlice";
import store from "state/store";

const usePageActions = () => {
  const dispatch = useDispatch();

  const setReloadGridData = (reloadFunction: Function) => {
    dispatch(setReloadGrid(reloadFunction));
  }

  const setReloadSummaryData = (reloadFunction: Function) => {
    dispatch(setReloadSummary(reloadFunction));
  }

  const resetPageData = () => {
    dispatch(resetPage());
  }

  const triggerReloadPage = (reloadGrid: boolean = true, reloadSummary: boolean = true) => {
    const pageState = store.getState().page;
    if(reloadGrid && pageState.reloadGrid){
      pageState.reloadGrid();
    }
    if(reloadSummary && pageState.reloadSummary){
      pageState.reloadSummary();
    }
  }

  return {
    setReloadGridData,
    setReloadSummaryData,
    resetPageData,
    triggerReloadPage,
  };
}

export default usePageActions;
