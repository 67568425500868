import { ColDef, ColGroupDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { BooleanFilter } from "components/Grid/GridFilters/Filters/BooleanFilter";
import { BooleanFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/BooleanFloatingFilter";
import CheckboxCellRenderer from "components/Grid/CheckboxCellRenderer";
import store from "state/store";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import { dateComparator } from "components/Grid/Comparators/DateComparator";
import { DateFormatter } from "components/Grid/ValueFormatters/DateFormatter";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";
import { MatterIncidentalExpenseModel } from "models/view/MatterIncidentalExpenseModel";
import ViewMatterIncidentalExpense from "containers/Matter/ViewMatter/ActivityHistory/MatterIncidentalExpense/ViewMatterIncidentalExpense/ViewMatterIncidentalExpense";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { getLteBranchesSummary, getLteDepartmentsSummary, getLteTeamsSummary } from "actions/lte";
import MatterIncidentalExpenseNameCellRenderer from "components/Grid/MatterIncidentalExpenseNameCellRenderer";
import MatterRefCellRenderer from "components/Grid/MatterRefCellRenderer";
import { CheckboxFormatter } from "components/Grid/ValueFormatters/CheckboxFormatter";

export const viewCallback = (rowData: MatterIncidentalExpenseModel) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        allowNavigation: true,
        title: "View Incidental Expense",
        children: <ViewMatterIncidentalExpense
          matterId={rowData.matterId}
          matterIncidentalExpenseId={rowData.id!}
          hideEdit
        />
      }
    )
  );
}

export const getColumnDefs = () => {
  const columnDefs: (ColDef<MatterIncidentalExpenseModel> | ColGroupDef<MatterIncidentalExpenseModel> | {excludeFromExport: boolean})[] = [
    { 
      headerName: 'Display Name',
      field: 'displayName',
      cellRenderer: MatterIncidentalExpenseNameCellRenderer,
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150,
    },
    { 
      headerName: 'Date',
      field: 'date',
      filter: 'agDateColumnFilter',
      valueFormatter: DateFormatter,
      filterParams: {
        comparator: dateComparator
      },
      minWidth: 150
    },
    {
      headerName: 'Fee Earner',
      field: 'user.name',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    { 
      headerName: 'Matter Ref',
      field: 'matter.fileNumberWithDisplayName',
      filter: 'agTextColumnFilter',
      cellRenderer: MatterRefCellRenderer,
      suppressSizeToFit: true,
      minWidth: 300,
      width: 300
    },
    { 
      headerName: 'Recordable Event',
      field: 'matterRecordableEvent.name',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    { 
      headerName: 'Description',
      field: 'description',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    {
      headerName: 'Gross Value',
      field: 'grossValue',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },
    {
      headerName: 'Is Billed',
      field: 'isBilled',
      minWidth: 100,
      width: 100,
      filter: BooleanFilter,
      filterParams: {
        property: 'isBilled',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    { 
      headerName: 'Invoice Ref Number',
      field: 'matterInvoice.invoiceRefNumber',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    {
      headerName: 'Gross Value On Invoice',
      field: 'grossValueOnInvoice',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },

    {
      headerName: 'Paid By User Own Funds',
      field: 'paidByUserOwnFunds',
      filter: BooleanFilter,
      filterParams: {
        property: 'paidByUserOwnFunds',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: BooleanFloatingFilter,
      floatingFilterComponentParams: {
        values: [{
          id: 'true',
          name: 'Yes'
        },
        {
          id: 'false',
          name: 'No'
        },
      ]},
      minWidth: 100,
      width: 100,
      cellRenderer: CheckboxCellRenderer,
      valueFormatter: CheckboxFormatter
    },
    { 
      headerName: 'Branch',
      field: 'branch.name', 
      filter: DropdownFilter,
      filterParams: {
        property: 'branchId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getLteBranchesSummary
      },
      minWidth: 220
    },
    { 
      headerName: 'Department',
      field: 'department.name', 
      filter: DropdownFilter,
      filterParams: {
        property: 'departmentId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getLteDepartmentsSummary
      },
      minWidth: 220
    },
    { 
      headerName: 'Team',
      field: 'team.name', 
      filter: DropdownFilter,
      filterParams: {
        property: 'teamId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getLteTeamsSummary
      },
      minWidth: 220
    },
    { 
      headerName: 'Client',
      field: 'matterParticipatingEntity.name',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      width: 150
    },
    { 
      excludeFromExport: true,
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return { buttonsData: [
          {
            type: ActionButtonTypes.View,
            callback: () => viewCallback(params.data)
          }]
        };
      },
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 65,
      maxWidth: 65,
      width: 65,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];

  return columnDefs;
}