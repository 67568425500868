import SlidingPanel from "components/SlidingPanel/index";
import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect, useRef, useState } from "react";
import { getColumnDefs, viewCallback } from "./columnDefs";
import { Row, Col, Card } from "react-bootstrap";
import SummaryBox from 'components/SummaryBox';
import { SummaryBoxTypes } from "enums/SummaryBoxTypes";
import { getMatterRecordableItemsForMatter, getMatterRecordableItemsSummaryInfo } from "actions/matter";
import Title from "components/Title/index";
import { GridIds } from "enums/GridIds";
import Loader from "components/Loader/index";
import SummaryBoxesSection from "components/SummaryBoxesSection";
import { MatterRecordableItemsSummaryInfoModel } from "models/view/MatterRecordableItemsSummaryInfoModel";
import { AgGridReact } from "ag-grid-react";
import { SummaryIds } from "enums/SummaryIds";
import usePageActions from "actions/page";

type Props = {
  matterId: string,
  matterFileNumber: string,
  syncMatterFiles: Function,
  matterIsClosed?: boolean
}

export default function MatterRecordableItemList(props: Props) {
  const gridActions = useGridActions();
  const pageActions = usePageActions();
  const [matterRecordableItemsSummaryInfo, setMatterRecordableItemsSummaryInfo] = useState<MatterRecordableItemsSummaryInfoModel | undefined>(undefined);
  const [isSummaryInfoLoading, setIsSummaryInfoLoading] = useState<boolean>(false);
  const [genericErrorsForSummaryInfo, setGenericErrorsForSummaryInfo] = useState(undefined);
  const [genericErrors, setGenericErrors] = useState(null);
  const gridRef = useRef<AgGridReact>(null);

  const onGridReady = useCallback(() =>
  {
    loadMatterRecordableItemsGrid();
  }, [props.matterId]);

  useEffect(() => {
    loadMatterRecordableItemsSummaryInfo();

    pageActions.setReloadGridData(reloadGridData);
    pageActions.setReloadSummaryData(loadMatterRecordableItemsSummaryInfo);
  }, [props.matterId]);

  useEffect(() => {
    gridActions.setGridId(GridIds.MatterRecordableItems);

    return () => {
      pageActions.resetPageData();
    }
  }, []);

  const reloadGridData = () => populateMatterRecordableItems(props.matterId);

  const loadMatterRecordableItemsGrid = () => {
    const columnDefs = getColumnDefs(pageActions.triggerReloadPage);
    gridActions.setGridColumnDefs([...columnDefs]);

    populateMatterRecordableItems(props.matterId);
  }

  const populateMatterRecordableItems = (matterId?: string) => {
    if(matterId) {
      getMatterRecordableItemsForMatter(props.matterId ?? '').then((response) => 
      {
        gridActions.setGridRowData(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        gridActions.setGridRowData([]);
      });
    }
    else {
      gridActions.setGridRowData([]);
    }
  };

  const loadMatterRecordableItemsSummaryInfo = () => {
    if(props.matterId) {
      setIsSummaryInfoLoading(true);
      getMatterRecordableItemsSummaryInfo(props.matterId).then((response) => {
        setMatterRecordableItemsSummaryInfo(response.data);
      })
      .catch((error) => {
        setGenericErrorsForSummaryInfo(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsSummaryInfoLoading(false);
      });
    }
    else{
      setMatterRecordableItemsSummaryInfo(undefined);
    }
  }

  return (
    <>
      {isSummaryInfoLoading &&
        <div className="lp-summary-boxes-placeholder">
          <Loader inlineLoader />
        </div>
      }

      {genericErrorsForSummaryInfo && (
        <div className="lp-errors">
          {genericErrorsForSummaryInfo}
        </div>
      )}

      {matterRecordableItemsSummaryInfo && 
        <Row>
          <Col>
            <SummaryBoxesSection id={SummaryIds.MatterRecordableItemsSummary}>
              <SummaryBox
                type={SummaryBoxTypes.TimeDoughnut}
                values={[
                  {
                    value: matterRecordableItemsSummaryInfo.totalBilledMinutes ?? 0,
                    label: "Billed",
                    className: "lp-primary"
                  },
                  {
                    value: matterRecordableItemsSummaryInfo.totalUnbilledMinutes ?? 0,
                    label: "Unbilled",
                    className: "lp-warning"
                  }
                ]}
              />

              <SummaryBox 
                type={SummaryBoxTypes.FeeIncomeDoughnut}
                values={[
                  {
                    value: matterRecordableItemsSummaryInfo.totalBilled ?? 0,
                    label: "Billed",
                    className: "lp-primary"
                  },
                  {
                    value: matterRecordableItemsSummaryInfo.totalUnbilled ?? 0,
                    label: "Unbilled",
                    className: "lp-warning"
                  }
                ]}
              />
            </SummaryBoxesSection>
          </Col>
        </Row>
      }

      <Row>
        <Col>
          <Title
            type="section"
            title={<>Recordable Items</>}
          >
          </Title>

          {genericErrors && (
            <div className="lp-errors">
              {genericErrors}
            </div>
          )}

          <Card className="with-grid">
            <Card.Body>
              <Grid 
                ref={gridRef}
                onGridReady={onGridReady}
                pagination={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <SlidingPanel gridRef={gridRef} onNavigation={(item: any) => viewCallback(item)} />
    </>
  );
}
