import { vestResolver } from "@hookform/resolvers/vest";
import {
  getMatterClientsSummary,
  getMatterReturnMoneyAccounts,
  getMatterReturnMoneyById,
  getOpenMattersSummary,
  getReturnMoneyByTypes,
  getReturnMoneyReasons,
  updateMatterReturnMoney
} from "actions/matter";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader/index";
import CustomSelect from "components/Select/Select";
import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { DateFormat } from "utils/constants";
import { removeEmptyFields } from "utils/form";
import { validationSuite } from "./validationSuite";
import { getDateOnly } from "utils/date";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { useAppSelector } from "hooks/appSelector";
import { CreateOrUpdateMatterReturnMoneyModel } from "models/create/CreateOrUpdateMatterReturnMoneyModel";
import { ReturnMoneyReasonIds } from "enums/ReturnMoneyReasonIds";
import usePageActions from "actions/page";
import FormErrorButton from "components/Buttons/FormErrorButton";

type Props = {
  matterId: string,
  matterReturnMoneyId: string,
  onSubmitCallback?: Function
}

export default function EditMatterReturnMoneyForm(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const [clients, setClients] = useState<DropDownOptionModel[]>([]);
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const pageActions = usePageActions();

  const { register, handleSubmit, control, watch, reset, formState: { errors } } = useForm<CreateOrUpdateMatterReturnMoneyModel>({
    resolver: vestResolver(validationSuite)
  });

  useEffect(() => {
    setIsLoading(true);
    getMatterReturnMoneyById(props.matterId, props.matterReturnMoneyId).then((response) => {
      let initialState: CreateOrUpdateMatterReturnMoneyModel = {
        ...response.data,
        date: getDateOnly(response.data.date)
      };

      loadClients(initialState.matterId);

      reset(initialState);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateMatterReturnMoneyModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    updateMatterReturnMoney(props.matterId, props.matterReturnMoneyId, data).then((response) => {
      pageActions.triggerReloadPage();

      slidingPanelActions.clearSlidingPanel();
      reset();

      props.onSubmitCallback && props.onSubmitCallback();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel !== undefined && currentSlidingPanelState?.onCancel();
  }

  const loadClients = (matterId?: string) => {
    if(matterId) {
      setIsLoading(true);
      getMatterClientsSummary(matterId).then((response) => {
        setClients(response.data);
      }).catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
    else {
      setClients([]);
    }
  }

  const onChangeMatter = (matterId?: string) => {
    loadClients(matterId);
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} sm={6} controlId="matterId">
            <Form.Label className="required">Matter</Form.Label>
            <Controller
              control={control}
              name={`matterId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="matterId"
                  inputRef={ref}
                  className={`lp-select${errors?.matterId?.message ? ' invalid' : ''}`}
                  endpointCall={getOpenMattersSummary}
                  isDisabled={!props.matterId}
                  value={value}
                  onChange={val => {onChange(val?.id ?? null); onChangeMatter(val?.id ?? undefined);}}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.matterId?.message && (errors.matterId?.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="matterParticipatingEntityId">
            <Form.Label className="required">Client</Form.Label>
            <Controller
              control={control}
              name={`matterParticipatingEntityId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="matterParticipatingEntityId"
                  inputRef={ref}
                  className={`lp-select${errors?.matterParticipatingEntityId?.message ? ' invalid' : ''}`}
                  options={clients}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.matterParticipatingEntityId?.message && (errors.matterParticipatingEntityId?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="date">
            <Form.Label className="required">Date</Form.Label>
            <Controller
              control={control}
              name="date"
              shouldUnregister={true}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  className={`${errors?.date?.message ? 'invalid' : ''}`}
                  id="date"
                  dateFormat={DateFormat.Datepicker}
                  selected={value ? getDateOnly(value) : null}
                  onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                  showMonthDropdown
                  showYearDropdown
                  autoComplete="off"
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.date?.message && (errors.date.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="accountId">
            <Form.Label className="required">Return From Account</Form.Label>
            <Controller
              control={control}
              name={`accountId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="accountId"
                  inputRef={ref}
                  className={`lp-select${errors?.accountId?.message ? ' invalid' : ''}`}
                  endpointCall={getMatterReturnMoneyAccounts}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.accountId?.message && (errors.accountId?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="returnedById">
            <Form.Label className="required">Returned By</Form.Label>
            <Controller
              control={control}
              name={`returnedById`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="returnedById"
                  inputRef={ref}
                  className={`lp-select${errors?.returnedById?.message ? ' invalid' : ''}`}
                  endpointCall={getReturnMoneyByTypes}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.returnedById?.message && (errors.returnedById?.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="returnedTo">
            <Form.Label className="required">Returned To</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.returnedTo?.message ? 'invalid' : ''}`}
              {...register(`returnedTo`, {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.returnedTo?.message && (errors.returnedTo?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="amount">
            <Form.Label className={"required"}>Amount</Form.Label>
            <Form.Control
              type="number"
              className={`${errors?.amount?.message ? 'invalid' : ''}`}
              {...register(`amount`, {shouldUnregister: true})}
              min="0.00"
              step="0.01"
              onWheel={e => e.currentTarget.blur()}
            />
            <Form.Text className="lp-error">
              {errors?.amount?.message && (errors.amount?.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="reasonId">
            <Form.Label className="required">Reason</Form.Label>
            <Controller
              control={control}
              name={`reasonId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="reasonId"
                  inputRef={ref}
                  className={`lp-select${errors?.reasonId?.message ? ' invalid' : ''}`}
                  endpointCall={getReturnMoneyReasons}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.reasonId?.message && (errors.reasonId?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="description">
            <Form.Label className={`${watch("reasonId") == ReturnMoneyReasonIds.ReturnForAnotherReasonId ? 'required' : ''}`}>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              className={`${errors?.description?.message ? 'invalid' : ''}`}
              {...register(`description`, {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.description?.message && (errors.description?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Update" />
              : <Button variant="success" type="submit">Update</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
