import Title from "components/Title/index";
import { useEffect, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import ViewUserCalendarSettings from "./ViewUserCalendarSettings/ViewUserCalendarSettings";
import { useAppSelector } from "hooks/appSelector";
import { UserCalendarSettingsModel } from "models/view/UserCalendarSettingsModel";
import { getUserCalendarSettings } from "actions/user";
import EditUserCalendarSettings from "./EditUserCalendarSettings/EditUserCalendarSettings";

export default function UserCalendarSettings() {
  const [edit, setEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userCalendarSettings, setUserCalendarSettings] = useState<UserCalendarSettingsModel>();
  const [genericErrors, setGenericErrors] = useState(null);
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if(user.userId) {
      setIsLoading(true);
      getUserCalendarSettings(user.userId).then((response) => {
        setUserCalendarSettings(response.data);
      }).catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, []);

  const submitEditCallback = (data: UserCalendarSettingsModel) => {
    setUserCalendarSettings(data);
    setEdit(false);
  };

  const cancelEditCallback = () => {
    setEdit(false);
  };

  return (
    <Col xl={6}>
      <Title type="section" title="Calendar Settings">
        { edit !== true &&
          <Button onClick={() => setEdit(true)} className="btn-icon" variant="primary">
            <MdEdit />
          </Button>
        }
      </Title>

      { genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      { edit === true
        ? <EditUserCalendarSettings
            userCalendarSettings={userCalendarSettings}
            submitCallbackFn={(data: UserCalendarSettingsModel) => submitEditCallback(data)}
            cancelCallbackFn={cancelEditCallback}
          />
        : <ViewUserCalendarSettings isLoading={isLoading} userCalendarSettings={userCalendarSettings} />
      }
    </Col>
  );
}
