import Tippy from "@tippyjs/react";
import { ICellRendererParams } from "ag-grid-community";
import "components/Grid/style.scss";
import { FreeBusyEventModel } from "models/view/FreeBusyScheduleModel";
import moment from "moment";
import { DateFormat } from "utils/constants";

export interface FreeBusyCellRendererParams extends ICellRendererParams {
  columnKey: string,
  use24HourFormat: boolean
}

export default function FreeBusyCellRenderer(props: FreeBusyCellRendererParams) {
  const getTooltipContent = () => {
    const events = props.data[props.columnKey].events;
    if(events.length === 0) {
      return (
        <div>
          There are 0 events.
        </div>
      );
    }
    else {
      const shownEvents = events.slice(0, 15);
      const hiddenEvents = events.slice(15);
      const timeFormat = props.use24HourFormat ? DateFormat.Moment24HourTime : DateFormat.MomentTime;

      return (
        <div>
          Events:
          {
            shownEvents.map((x: FreeBusyEventModel, index: number) => 
              <div key={index}>
                {`${moment(x.start).format(timeFormat)} - ${moment(x.end).format(timeFormat)} ${x.subject ? x.subject : "[No subject]"} - ${x.location ? x.location : "[No location]"}`}
              </div>
            )
          }
          {
            hiddenEvents.length > 0
            ? <div>
                and {hiddenEvents.length} more...
              </div>
            : null
          }
        </div>
      );
    }
  };

  return (
    <Tippy
      content={getTooltipContent()}
      className="lp-calendar-event-tooltip"
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      duration={100}
      placement="bottom-start"
      trigger="mouseenter"
    >
      <div className={`lp-cell-time-slot ${props.value.toLowerCase()}`}>
        {
         props.value
        }
      </div>
    </Tippy>
  );
}
