import SlidingPanel from "components/SlidingPanel/index";
import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useRef, useEffect, useState } from "react";
import { getColumnDefs, viewCallback } from "./columnDefs";
import { Row, Col, Card } from "react-bootstrap";
import SummaryBox from 'components/SummaryBox';
import { SummaryBoxTypes } from "enums/SummaryBoxTypes";
import {
  getLteIncidentalExpensesSummaryInfo,
  getMatterIncidentalExpensesForLte
} from "actions/matter";
import Title from "components/Title/index";
import { GridOptionButtonDataModel } from "models/view/GridOptionButtonDataModel";
import Loader from "components/Loader/index";
import useSlidingPanelActions from 'actions/slidingPanel';
import { useAppSelector } from "hooks/appSelector";
import SummaryBoxesSection from "components/SummaryBoxesSection";
import { GridFilterTypes } from "enums/GridFilterTypes";
import { getDateOnly } from "utils/date";
import { GridFilterButtonDataModel } from "models/view/GridFilterButtonDataModel";
import { MatterIncidentalExpensesSummaryInfoModel } from "models/view/MatterIncidentalExpensesSummaryInfoModel";
import AdvancedGridFilterForm from "components/Grid/GridFilters/AdvancedGridFilter/AdvancedGridFilterForm";
import { AdvancedGridFilterModel } from "models/view/AdvancedGridFilterModel";
import { GridAdvancedFilterDateOptions } from "enums/GridAdvancedFilterDateOptions";
import { UserRoleNames } from "enums/UserRoleNames";
import { AgGridReact } from "ag-grid-react";
import { GridIds } from "enums/GridIds";
import { SummaryIds } from "enums/SummaryIds";

export default function LteIncidentalExpenseList() {
  const gridActions = useGridActions();
  const [summaryInfo, setSummaryInfo] = useState<MatterIncidentalExpensesSummaryInfoModel | undefined>(undefined);
  const [isSummaryInfoLoading, setIsSummaryInfoLoading] = useState<boolean>(false);
  const [genericErrorsForSummaryInfo, setGenericErrorsForSummaryInfo] = useState(undefined);
  const [genericErrors, setGenericErrors] = useState(null);
  const loggedInUser = useAppSelector((state) => state.user);
  const gridRef = useRef<AgGridReact>(null);
  
  const slidingPanelActions = useSlidingPanelActions();

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs();
    gridActions.setGridColumnDefs([...columnDefs]);
    gridActions.setGridAdvancedFilterClearCallback(gridAdvancedFilterClearCallback);
  
    const initialAdvancedFilter: AdvancedGridFilterModel = {
      feeEarnerIds: (loggedInUser.userId && loggedInUser.userRole?.name != UserRoleNames.Lawyer) ? [loggedInUser.userId] : []
    };
    gridAdvancedFilterCallback(initialAdvancedFilter);
  }, []);

  useEffect(() => {
    gridActions.setGridId(GridIds.LteIncidentalExpenses);
  }, []);

  const populateLteIncidentalExpenses = (advancedFilter?: AdvancedGridFilterModel) => {
    const filterFeeEarnerIds = advancedFilter?.feeEarnerIds;
    const filterStartDate = advancedFilter?.startDate ? getDateOnly(advancedFilter?.startDate) : undefined;
    const filterEndDate = advancedFilter?.endDate ? getDateOnly(advancedFilter?.endDate) : undefined;
    
    getMatterIncidentalExpensesForLte(filterFeeEarnerIds, filterStartDate, filterEndDate).then((response) => {
      gridActions.setGridRowData(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  };

  const loadLteIncidentalExpensesSummaryInfo = (advancedFilter?: AdvancedGridFilterModel) => {
    setIsSummaryInfoLoading(true);
    const filterFeeEarnerIds = advancedFilter?.feeEarnerIds;
    const filterStartDate = advancedFilter?.startDate ? getDateOnly(advancedFilter?.startDate) : undefined;
    const filterEndDate = advancedFilter?.endDate ? getDateOnly(advancedFilter?.endDate) : undefined;

    getLteIncidentalExpensesSummaryInfo(filterFeeEarnerIds, filterStartDate, filterEndDate).then((response) => {
      setSummaryInfo(response.data);
    })
    .catch((error) => {
      setGenericErrorsForSummaryInfo(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsSummaryInfoLoading(false);
    });
  }

  const gridOptions: GridOptionButtonDataModel[] = [
  ];

  const gridAdvancedFilterOnClickCallback = () => {
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Incidental Expenses Advanced Filter",
      children: <AdvancedGridFilterForm
        filterCallback={gridAdvancedFilterCallback}
        showDateFilter
        showFeeEarnersFilter={loggedInUser.userRole?.name != UserRoleNames.Lawyer}
        showDateOptionsFilter
        dateOptionsFilterValues={[
          GridAdvancedFilterDateOptions.Today,
          GridAdvancedFilterDateOptions.CurrentWeek,
          GridAdvancedFilterDateOptions.PreviousWeek,
          GridAdvancedFilterDateOptions.CurrentMonth,
          GridAdvancedFilterDateOptions.PreviousMonth,
          GridAdvancedFilterDateOptions.CurrentPrevious3Months,
          GridAdvancedFilterDateOptions.CurrentPrevious6Months,
          GridAdvancedFilterDateOptions.CurrentFinancialYear,
          GridAdvancedFilterDateOptions.PreviousFinancialYear
        ]}
      />
    });
  };

  const gridFilters: GridFilterButtonDataModel[] = [
    {
      type: GridFilterTypes.AdvancedFilters,
      callback: gridAdvancedFilterOnClickCallback
    }
  ];

  const gridAdvancedFilterCallback = (advancedFilter?: AdvancedGridFilterModel) => {
    gridActions.setGridAdvancedFilter(advancedFilter);
    const nonEmptyFilterValues = [(advancedFilter?.feeEarnerIds?.length ?? 0) > 0 ? true : undefined, advancedFilter?.startDate, advancedFilter?.endDate].filter(x => x);
    if(nonEmptyFilterValues.length > 0) {
      gridActions.setGridAdvancedFilterInfo(<>{`${nonEmptyFilterValues.length} advanced filter(s) applied`}</>);
    }
    else {
      gridActions.setGridAdvancedFilterInfo(undefined);
    }
    
    populateLteIncidentalExpenses(advancedFilter);
    loadLteIncidentalExpensesSummaryInfo(advancedFilter);
  };

  const gridAdvancedFilterClearCallback = () => {
    gridAdvancedFilterCallback(undefined);
  };

  return (
    <>
      {isSummaryInfoLoading &&
        <div className="lp-summary-boxes-placeholder">
          <Loader inlineLoader />
        </div>
      }

      {genericErrorsForSummaryInfo && (
        <div className="lp-errors">
          {genericErrorsForSummaryInfo}
        </div>
      )}

      {summaryInfo && !isSummaryInfoLoading && 
        <Row>
          <Col>
            <SummaryBoxesSection id={SummaryIds.LteIncidentalExpensesSummary}>
              <SummaryBox
                type={SummaryBoxTypes.IncidentalExpensesDoughnut}
                values={[
                  {
                    value: summaryInfo.totalBilled ?? 0,
                    label: "Billed",
                    className: "lp-primary"
                  },
                  {
                    value: summaryInfo.totalUnbilled ?? 0,
                    label: "Unbilled",
                    className: "lp-warning"
                  },
                  ...summaryInfo.totalWriteOff ? [{
                    value: summaryInfo.totalWriteOff ?? 0,
                    label: "Write-Off",
                    className: "lp-danger"
                  }] : []
                ]}
              />
            </SummaryBoxesSection>
          </Col>
        </Row>
      }

      <Row>
        <Col>
          <Title
            type="section"
            title={<>Incidental Expenses</>}
          >
          </Title>

          {genericErrors && (
            <div className="lp-errors">
              {genericErrors}
            </div>
          )}

          <Card className="with-grid">
            <Card.Body>
              <Grid 
                ref={gridRef}
                onGridReady={onGridReady}
                gridOptions={gridOptions}
                gridFilters={gridFilters}
                pagination={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <SlidingPanel gridRef={gridRef} onNavigation={(item: any) => viewCallback(item)} />
    </>
  );
}
