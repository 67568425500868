import { useEffect, useState } from "react";
import { LteDepartmentModel } from "models/view/LteDepartmentModel";
import { getLteDepartment } from "actions/lte";
import Loader from "components/Loader/index";
import Field from "components/Fields/Field";
import { Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import EditLteDepartmentForm from "../EditLteDepartment/EditLteDepartmentForm";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type Props = {
  departmentId: string,
  canBeEdited?: boolean,
  deleteCallback?: Function,
}

export default function ViewLteDepartment(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [lteDepartment, setLteDepartment] = useState<LteDepartmentModel | undefined>(undefined);
  const [loading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const modalActions = useModalActions();
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(() => {
    setIsLoading(true);
    getLteDepartment(props.departmentId!)
      .then((response) => {
        setLteDepartment(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });

    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageAllLTEs || a == UserPermissionsNames.ManageLTE) && 
      props.canBeEdited) {
        // Set edit button action
        slidingPanelActions.setOnEdit(editDepartment);

        if (props.deleteCallback) {
          slidingPanelActions.setMoreButtons([{
            type: ActionButtonTypes.Delete,
            callback: () => deleteModal(props.departmentId),
          }]);
        }
    }
  }, []);

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  };

  const editDepartment = () => {
    slidingPanelActions.clearSlidingPanel();
    
    slidingPanelActions.setSlidingPanel({
      isShown: true,
      title: `Edit Department`,
      children: <EditLteDepartmentForm
        departmentId={props.departmentId}
        submitCallback={onChildPanelClose}
      />,
      onCancel: onChildPanelClose
    });
  };

  const deleteModal = (id: string) => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this department?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => {
        props.deleteCallback!(id);
        currentSlidingPanelState.onCancel && currentSlidingPanelState.onCancel();
      },
      show: true
    }
    modalActions.setModal(modal);
  };
      
  return (
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Name"}
            value={lteDepartment?.name ?? ""}
          />
        </Col>
        <Col>
          <Field
            label={"Branch"}
            value={lteDepartment?.lteBranch?.name ?? ""}
          />
        </Col>
      </Row>
    </>
  );
}
