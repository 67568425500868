import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import Loader from "components/Loader";
import { removeEmptyFields } from "utils/form";
import { UserCalendarSettingsModel } from "models/view/UserCalendarSettingsModel";
import { updateUserCalendarSettings } from "actions/user";
import { useAppSelector } from "hooks/appSelector";
import { getValidationSuite } from "./validationSuite";
import CustomSelect from "components/Select/Select";
import { getDaysOfWeek } from "utils/misc";
import moment from "moment";
import "./style.scss"
import Field from "components/Fields/Field";
import ReactDatePicker from "react-datepicker";
import { DateFormat } from "utils/constants";
import { UpdateUserCalendarSettingsModel } from "models/update/UpdateUserCalendarSettingsModel";

type Props = {
  userCalendarSettings: UserCalendarSettingsModel | undefined,
  submitCallbackFn: Function,
  cancelCallbackFn: Function
}

export default function EditUserCalendarSettings(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const user = useAppSelector((state) => state.user);
  const daysOfWeek = getDaysOfWeek();
  const selectedDayOfWeekId = daysOfWeek.find((day) => day.name === props.userCalendarSettings?.firstDayOfWeek)?.id;

  const { register, setValue, getValues, watch, control, handleSubmit, formState: { errors } } = useForm<UpdateUserCalendarSettingsModel>({
    resolver: vestResolver(getValidationSuite()),
    defaultValues: {
      firstDayOfWeek: selectedDayOfWeekId,
      dateFormat: props.userCalendarSettings?.dateFormat ?? '',
      timeFormatIs24Hours: props.userCalendarSettings?.timeFormatIs24Hours ?? false,
      // Create date object with the time from userCalendarSettings
      startWorkingHours: moment(`${moment().format("YYYY-MM-DD")}T${props.userCalendarSettings?.startWorkingHours}`).toDate() ?? new Date(),
      endWorkingHours: moment(`${moment().format("YYYY-MM-DD")}T${props.userCalendarSettings?.endWorkingHours}`).toDate() ?? new Date(),
    }
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: UpdateUserCalendarSettingsModel) {
    setIsLoading(true);
    removeEmptyFields(data);

    // Format payload (working hours need to be changed from date to time string)
    const payload: UserCalendarSettingsModel = {
      ...data,
      startWorkingHours: moment(data.startWorkingHours).format('HH:mm:ss'),
      endWorkingHours: moment(data.endWorkingHours).format('HH:mm:ss'),
    }

    updateUserCalendarSettings(user.userId!, payload).then((response) => {
      if(props.submitCallbackFn){
        props.submitCallbackFn(response.data);
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const onCancelClick = () => {
    if(props.cancelCallbackFn){
      props.cancelCallbackFn();
    }
  };

  const changeDateFormat = (is24Hours: boolean) => {
    // Replace value in dateFormat
    const dateFormatValue = getValues('dateFormat');
    setValue('dateFormat', is24Hours ? dateFormatValue.replace(/h/g, 'H') : dateFormatValue.replace(/H/g, 'h'));
  }

  return (
    <Card>
      {isLoading && <Loader inlineLoader />}

      <Card.Body>
        {genericErrors && (
          <div className="lp-errors">
            {genericErrors}
          </div>
        )}

        <Form onSubmit={onSubmit}>
          <Row>
            <Form.Group as={Col} controlId="firstDayOfWeek">
              <Form.Label className="required">First day of the week</Form.Label>
              <Controller
                control={control}
                name="firstDayOfWeek"
                shouldUnregister={true}
                render={({field: { value, onChange }}) => (
                  <CustomSelect
                    id="firstDayOfWeek"
                    className={`lp-select${errors?.firstDayOfWeek?.message ? ' invalid' : ''}`}
                    options={daysOfWeek}
                    onChange={(selectedOption: any) => onChange(selectedOption.id)}
                    value={value}
                  />
                )}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="dateFormat">
              <Form.Label className="required">Date Format</Form.Label>
              <Form.Control
                type="text"
                className={`${errors?.dateFormat?.message ? 'invalid' : ''}`}
                {...register("dateFormat", {
                  shouldUnregister: true,
                  // Replace hh to HH or vice versa as the user types
                  onChange: (e) => setValue('dateFormat', watch('timeFormatIs24Hours')
                    ? e.target.value.replace(/h/g, 'H')
                    : e.target.value.replace(/H/g, 'h')
                  )
                })}
              />
              <Form.Text className="lp-error">
                {errors?.dateFormat?.message && (errors.dateFormat.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} controlId="timeFormatIs24Hours">
              <Form.Label>24-hour Time</Form.Label>
              <Controller
                control={control}
                name="timeFormatIs24Hours"
                shouldUnregister={true}
                render={({field: { onChange, value, ref }}) => (
                  <Form.Check 
                    type="switch"
                    id="timeFormatIs24Hours">
                      <Form.Check.Input
                        className= "form-check-input"
                        ref={ref}
                        checked={value ?? false}
                        onChange={(ev: any) => {
                          onChange(ev.target.checked)
                          changeDateFormat(ev.target.checked)
                        }}
                      />
                  </Form.Check>
                )}
              />
            </Form.Group>
          </Row>

          <Row>
            <Col>
              <Field
                label={"Date Format Preview"}
                value={moment('2020-01-30T15:30:00Z').format(watch('dateFormat'))}
              />
            </Col>
          </Row>

          <Row>
            <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId="startWorkingHours">
              <Form.Label className="required">Working Day Start Time</Form.Label>
              <Controller
                control={control}
                name="startWorkingHours"
                shouldUnregister={true}
                render={({ field: { onChange, value } }) => (
                  <ReactDatePicker
                    className={`${errors?.startWorkingHours?.message ? 'invalid' : ''}`}
                    id="startWorkingHours"
                    dateFormat={DateFormat.DatepickerTime}
                    selected={value}
                    onChange={(val) => onChange(val)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.startWorkingHours?.message && (errors.startWorkingHours?.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} xs={12} sm={6} className="mb-4" controlId="endWorkingHours">
              <Form.Label className="required">Working Day End Time</Form.Label>
              <Controller
                control={control}
                name="endWorkingHours"
                shouldUnregister={true}
                render={({ field: { onChange, value } }) => (
                  <ReactDatePicker
                    className={`${errors?.endWorkingHours?.message ? 'invalid' : ''}`}
                    id="endWorkingHours"
                    dateFormat={DateFormat.DatepickerTime}
                    timeFormat={DateFormat.DatepickerTime}
                    selected={value}
                    onChange={(val) => onChange(val)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.endWorkingHours?.message && (errors.endWorkingHours?.message)}
              </Form.Text>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className="d-flex justify-content-between">
              <Button variant="success" type="submit">Update</Button>
              <Button variant="secondary-400" onClick={onCancelClick}>Cancel</Button>
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
