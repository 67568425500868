import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { LteBankAccountModel } from "models/view/LteBankAccountModel";
import { deleteLteBankAccount, getLteBankAccount } from "actions/lte";
import { Row, Col } from "react-bootstrap";
import BooleanField from "components/Fields/BooleanField";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import useModalActions from "actions/modal";
import useGridActions from "actions/grid";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import EditLteBankAccountForm from "../EditLteBankAccount/EditLteBankAccountForm";
import { ModalState } from "state/modalSlice";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type Props = {
  id: string,
  editSubmitCallback?: Function,
}

function ViewLteBankAccount(props: Props){
  const [loading, setLoading] = useState(true);
  const [genericErrors, setGenericErrors] = useState(null);
  const [lteBankAccount, setLteBankAccount] = useState<LteBankAccountModel>();
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const modalActions = useModalActions();
  const gridActions = useGridActions();
  const currentGridState = useAppSelector((state) => state.grid);
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(()=>{
    setLoading(true);
    getLteBankAccount(props.id).then((response) => {
      setLteBankAccount(response.data)
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setLoading(false);
    });

    // Set edit button action
    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageAllLTEs || a == UserPermissionsNames.ManageLTE)) {
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();

        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Edit Bank Account",
          children: <EditLteBankAccountForm
            bankAccountId={props.id} 
            submitCallback={() => {
              props.editSubmitCallback && props.editSubmitCallback();
              onChildPanelClose();
            }}
          />,
          onCancel: onChildPanelClose
        });
      });
    }

    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageAllLTEs || a == UserPermissionsNames.ManageLTE)) {
      slidingPanelActions.setMoreButtons([{
        type: ActionButtonTypes.Delete,
        callback: () => deleteModal(),
      }]);
    }
  },[props.id])

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  };

  const deleteModal = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this bank account?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.id),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };
  
  const deleteCallback = (id: string) => {
    const gridState = currentGridState;
    modalActions.toggleModalLoading();
    deleteLteBankAccount(id).then(() => {
      gridActions.setGridRowData(gridState.rowData.filter((x: any) => x.id !== id));
      modalActions.toggleModalShownStatus();
      slidingPanelActions.clearSlidingPanel();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  return(
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Bank Name"}
            value={lteBankAccount?.bankName ?? ""}
          />
        </Col>
        <Col>
          <Field
            label={"Bank Account Name"}
            value={lteBankAccount?.bankAccountName ?? ""}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Bank Sort Code"}
            value={lteBankAccount?.bankSortCode ?? ""}
          />
        </Col>
        <Col>
          <Field
            label={"Bank Account No."}
            value={lteBankAccount?.bankAccountNumber ?? ""}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Bank IBAN"}
            value={lteBankAccount?.bankIban ?? ""}
          />
        </Col>
        <Col>
          <BooleanField
            label={"Is Receivable Account"}
            value={lteBankAccount?.isReceivableAccount ?? false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <BooleanField
            label={"Is Client Account"}
            value={lteBankAccount?.isClientAccount ?? false}
          />
        </Col>
        <Col>
          <BooleanField
            label={"Is Payable Account"}
            value={lteBankAccount?.isPayableAccount ?? false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Description"}
            value={lteBankAccount?.description ?? ""}
          />
        </Col>
      </Row>
    </>
  );
}

export default ViewLteBankAccount;
