import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import { getValidationSuite } from "./validationSuite";
import CustomSelect from "components/Select/Select";
import { CalendarFilterModel } from "models/view/CalendarFilterModel";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";

type Props = {
  filterCallback: Function,
  lteList: DropDownOptionModel[],
  localStorageCalendarFilterKey: string,
}

export default function CalendarFilterForm(props: Props) {
  const slidingPanelActions = useSlidingPanelActions();

  const {register, reset, control, setValue, watch, handleSubmit, formState: {errors}} = useForm<CalendarFilterModel>({
    resolver: vestResolver(getValidationSuite())
  });

  useEffect(() => {
    // If there are filters saved, load them into the form
    const savedFilters = localStorage.getItem(props.localStorageCalendarFilterKey);

    if(savedFilters) {
      const filters = JSON.parse(savedFilters);
      reset(filters);
    }
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));

  const submitData = (data: CalendarFilterModel) => {
    // Remove empty fields
    Object.entries(data).forEach(([key, value]) => {
      if(!value?.length) {
        delete data[key as keyof typeof CalendarFilterForm];
      }
    });

    props.filterCallback(data);

    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }

  return (
    <>
      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="ltes">
            <Form.Label>LTEs</Form.Label>
            <Controller
              control={control}
              name="ltes"
              shouldUnregister={true}
              render={({field: { onChange, value, ref }}) => (
                <CustomSelect
                  id="ltes"
                  inputRef={ref}
                  className={`lp-select${errors?.ltes?.message ? ' invalid' : ''}`}
                  options={props.lteList}
                  value={value}
                  onChange={val => onChange(val?.map((x: { id: any }) => x.id) ?? null)}
                  isMulti
                  isClearable
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.ltes?.message && (errors.ltes.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="location">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.location?.message ? 'invalid' : ''}`}
              {...register("location", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.location?.message && (errors.location.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="attendee">
            <Form.Label>Attendee</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.attendee?.message ? 'invalid' : ''}`}
              {...register("attendee", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.attendee?.message && (errors.attendee.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            <Button variant="success" type="submit">Filter</Button>
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
