import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import CustomSelect from "components/Select/Select";
import Loader from "components/Loader";
import { validationSuite } from "./validationSuite";
import { removeEmptyFields } from "utils/form";
import { getMatterActivityHistoryById, getMatterActivityHistoryTaxonomyTerms, updateMatterActivityHistoryTaxonomyTerms } from "actions/matter";
import useGridActions from "actions/grid";
import { useAppSelector } from "hooks/appSelector";
import { UpdateMatterActivityHistoryTaxonomyTermsModel } from "models/update/UpdateMatterActivityHistoryTaxonomyTermsModel";
import { MatterActivityHistoryModel } from "models/view/MatterActivityHistoryModel";
import { TaxonomyTermModel } from "models/view/TaxonomyTermModel";
import { getMatterTaxonomies, getMatterTaxonomyTermsSummary } from "actions/taxonomy";
import { TaxonomyModel } from "models/view/TaxonomyModel";
import React from "react";
import CustomTreeSelect from "components/TreeSelect/CustomTreeSelect";
import TreeSelect from "rc-tree-select";
import { MatterActivityHistoryTypes } from "enums/MatterActivityHistoryTypes";
import FormErrorButton from "components/Buttons/FormErrorButton";

type Props = {
  matterId: string,
  activityHistoryId: string,
  onSubmitCallback?: Function
}

export default function EditActivityHistoryTaxonomyTermsForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taxonomies, setTaxonomies] = useState<TaxonomyModel[]>([]);
  const [activityHistoryRecord, setActivityHistoryRecord] = useState<MatterActivityHistoryModel | undefined>(undefined);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const gridActions = useGridActions();
  const grid = useAppSelector((state) => state.grid);

  const {reset, control, handleSubmit, register, formState: {errors}} = useForm<any>({
    resolver: vestResolver(validationSuite)
  });

  useEffect(() => {
    setIsLoading(true);
    const promises = [
      getMatterTaxonomies(props.matterId),
      getMatterActivityHistoryTaxonomyTerms(props.matterId, props.activityHistoryId),
      getMatterActivityHistoryById(props.matterId, props.activityHistoryId)
    ];

    Promise.all(promises)
      .then(([taxonomiesResponse, activityHistoryTaxonomyTermsResponse, activityHistoryResponse]) => {
        setTaxonomies(taxonomiesResponse.data);
        setActivityHistoryRecord(activityHistoryResponse.data);
        
        let initialState: any = {};
        if(activityHistoryResponse.data.activityType == MatterActivityHistoryTypes.File){
          initialState[`fileAdditionalNote`] = activityHistoryResponse.data?.fileAdditionalNote;
        }
        taxonomiesResponse.data.forEach(function (taxonomy: TaxonomyModel) {
          if(taxonomy.isMultiSelect) {
            if(taxonomy.isHierarchical) {
              initialState[`taxonomy-${taxonomy.id}`] = activityHistoryTaxonomyTermsResponse.data
                .filter((x: TaxonomyTermModel) => x.taxonomyId == taxonomy.id)
                .map((x: TaxonomyTermModel) => {return {label: x.displayName, value: x.id}});
            }
            else {
              initialState[`taxonomy-${taxonomy.id}`] = activityHistoryTaxonomyTermsResponse.data
                .filter((x: TaxonomyTermModel) => x.taxonomyId == taxonomy.id).map((x: TaxonomyTermModel) => x.id);
            }
          }
          else {
            const [currentValue] = activityHistoryTaxonomyTermsResponse.data
              .filter((x: TaxonomyTermModel) => x.taxonomyId == taxonomy.id).map((x: TaxonomyTermModel) => x.id);
            initialState[`taxonomy-${taxonomy.id}`] = currentValue;
          }
        });

        reset(initialState);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));
    
  async function submitData(data: any) {
    setIsLoading(true);
    removeEmptyFields(data);
    const requestBody: UpdateMatterActivityHistoryTaxonomyTermsModel = {
      taxonomyTermIds: [],
      fileAdditionalNote: data.fileAdditionalNote
    };

    Object.keys(data).forEach(function (key: string) {
      if(key == "fileAdditionalNote") {
        return;
      }
      const value = Reflect.get(data, key);
      if(Array.isArray(value)) {
        if(value.length > 0 && typeof value[0] === 'object') {
          requestBody.taxonomyTermIds = requestBody.taxonomyTermIds.concat(value.map(x => x.value));
        }
        else {
          requestBody.taxonomyTermIds = requestBody.taxonomyTermIds.concat(value);
        }
      }
      else {
        requestBody.taxonomyTermIds = requestBody.taxonomyTermIds.concat([value]);
      }
    });

    updateMatterActivityHistoryTaxonomyTerms(props.matterId, props.activityHistoryId, requestBody)
      .then((response) => {
        let index: number = grid.rowData.findIndex((x: any) => x.id === response.data.id);
        let matterActivityHistory: MatterActivityHistoryModel = {...response.data};
        let newArray: Array<MatterActivityHistoryModel> = [...grid.rowData];
        newArray[index] = matterActivityHistory;
        gridActions.setGridRowData(newArray);
        slidingPanelActions.clearSlidingPanel();
        reset();

        props.onSubmitCallback && props.onSubmitCallback();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel && currentSlidingPanelState.onCancel();
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        {taxonomies.map((x: TaxonomyModel, index: number) => {
          return (
            <React.Fragment key={index}>
              <Row>
                <Form.Group as={Col} controlId={`taxonomy-${x.id}`}>
                  <Form.Label>{x.displayName}</Form.Label>
                  <Controller
                    control={control}
                    name={`taxonomy-${x.id}`}
                    shouldUnregister={true}
                    render={({field: { onChange, value, name, ref }}) => (
                      <>
                        {x.isHierarchical ? 
                          <CustomTreeSelect
                            id={`taxonomy-${x.id}`}
                            className={`lp-select w-100${errors?.parentTaxonomyTermId?.message ? ' invalid' : ''}`}
                            endpointCall={() => getMatterTaxonomyTermsSummary(props.matterId, x.id)}
                            value={value}
                            onChange={(val: any) => onChange(val ?? null)}
                            dropdownMatchSelectWidth
                            treeDefaultExpandAll
                            treeCheckable={x.isMultiSelect}
                            showCheckedStrategy={x.isMultiSelect ? TreeSelect.SHOW_ALL : undefined}
                            treeCheckStrictly={x.isMultiSelect}
                            maxTagCount={4}
                            maxTagTextLength={25}
                          />
                        :
                          <CustomSelect
                            id={`taxonomy-${x.id}`}
                            inputRef={ref}
                            endpointCall={() => getMatterTaxonomyTermsSummary(props.matterId, x.id)}
                            isMulti={x.isMultiSelect}
                            isClearable
                            value={value}
                            onChange={val => {
                              x.isMultiSelect ? 
                                (onChange(val?.map((x: { id: any; }) => x.id) ?? null))
                              : 
                                (onChange(val?.id ?? null))
                            }}
                          />
                        }
                      </>
                      
                    )}
                  />
                </Form.Group>
              </Row>
            </React.Fragment>
          )
        })}

        {activityHistoryRecord?.activityType == MatterActivityHistoryTypes.File &&
          <Row>
              <Form.Group as={Col} controlId="fileAdditionalNote">
              <Form.Label>File Additional Note</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                className={`${errors?.fileAdditionalNote?.message ? 'invalid' : ''}`}
                {...register(`fileAdditionalNote`, {shouldUnregister: true})}
              />
            </Form.Group>
          </Row>
        }

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Update" />
              : <Button variant="success" type="submit">Update</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
