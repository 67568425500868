import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col } from "react-bootstrap";
import BooleanField from "components/Fields/BooleanField";
import { EntityBankAccountModel } from "models/view/EntityBankAccountModel";
import { deleteEntityBankAccount, getEntityBankAccountById } from "actions/entity";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import useModalActions from "actions/modal";
import useGridActions from "actions/grid";
import EditEntityBankAccountForm from "../EditEntityBankAccount/EditEntityBankAccountForm";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState } from "state/modalSlice";
import { UserPermissionsNames } from "enums/UserPermissionsNames";

type Props = {
  entityId: string,
  bankAccountId: string
}

function ViewEntityBankAccount(props: Props){
  const [loading, setLoading] = useState(true);
  const [genericErrors, setGenericErrors] = useState(null);
  const [entityBankAccount, setEntityBankAccount] = useState<EntityBankAccountModel>();
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const modalActions = useModalActions();
  const gridActions = useGridActions();
  const currentGridState = useAppSelector((state) => state.grid);
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(() => {
    setLoading(true);
    getEntityBankAccountById(props.entityId, props.bankAccountId).then((response) => {
      setEntityBankAccount(response.data)
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setLoading(false);
    });

    // Set edit button action
    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities)) {
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();

        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Edit Bank Account",
          children: <EditEntityBankAccountForm
            entityId={props.entityId}
            bankAccountId={props.bankAccountId}
            onSubmitCallback={onChildPanelClose}
          />,
          onCancel: onChildPanelClose
        });
      });
    }

    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageEntities)) {
      slidingPanelActions.setMoreButtons([{
        type: ActionButtonTypes.Delete,
        callback: () => deleteModal()
      }]);
    }
  }, [props.entityId, props.bankAccountId]);

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  };

  const deleteModal = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this bank account?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.entityId, props.bankAccountId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const deleteCallback = (entityId: string, bankAccountId: string) => {
    const gridState = currentGridState;
    modalActions.toggleModalLoading();
    deleteEntityBankAccount(entityId, bankAccountId).then(() => {
      gridActions.setGridRowData(gridState.rowData.filter((x: any) => x.id !== bankAccountId));
      modalActions.toggleModalShownStatus();
      slidingPanelActions.clearSlidingPanel();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  return(
    <>
      {loading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Bank Name"}
            value={entityBankAccount?.bankName ?? ""}
          />
        </Col>
        <Col>
          <Field
            label={"Bank Account Name"}
            value={entityBankAccount?.bankAccountName ?? ""}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Bank Sort Code"}
            value={entityBankAccount?.bankSortCode ?? ""}
          />
        </Col>
        <Col>
          <Field
            label={"Bank Account No."}
            value={entityBankAccount?.bankAccountNumber ?? ""}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Bank IBAN"}
            value={entityBankAccount?.bankIBAN ?? ""}
          />
        </Col>
        <Col>
          <BooleanField
            label={"Is Preferred"}
            value={entityBankAccount?.isPreferred ?? false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Description"}
            value={entityBankAccount?.description ?? ""}
          />
        </Col>
      </Row>
    </>
  );
}

export default ViewEntityBankAccount;
