import { ColDef } from "ag-grid-community";
import ActionsCellRenderer from "components/Grid/ActionsCellRenderer/ActionsCellRenderer";
import InvoiceStatusCellRenderer from "components/Grid/InvoiceStatusCellRenderer";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { CurrencyFormatter } from "components/Grid/ValueFormatters/CurrencyFormatter";
import { dateComparator } from "components/Grid/Comparators/DateComparator";
import { DateFormatter } from "components/Grid/ValueFormatters/DateFormatter";
import { DropdownFilter } from "components/Grid/GridFilters/Filters/DropdownFilter";
import { getMatterInvoiceStatuses } from "actions/matter";
import { DropdownFloatingFilter } from "components/Grid/GridFilters/FloatingFilters/DropdownFloatingFilter";
import { MatterInvoiceModel } from "models/view/MatterInvoiceModel";
import store from "state/store";
import { setSlidingPanelData } from "state/slidingPanelSlice";
import ViewMatterInvoice from "containers/Matter/ViewMatter/ActivityHistory/MatterInvoice/ViewMatterInvoice/ViewMatterInvoice";
import MatterRefCellRenderer from "components/Grid/MatterRefCellRenderer";
import { InvoiceStatusFormatter } from "components/Grid/ValueFormatters/InvoiceStatusFormatter";
import DropDownOptionListCellRenderer from "components/Grid/DropDownOptionListCellRenderer";
import { DropDownOptionListFormatter } from "components/Grid/ValueFormatters/DropDownOptionListFormatter";

export const viewCallback = (rowData: MatterInvoiceModel) => {
  store.dispatch(
    setSlidingPanelData(
      {
        isShown: true,
        allowNavigation: true,
        title: "View Invoice",
        children: <ViewMatterInvoice 
          matterId={rowData.matterId}
          matterInvoiceId={rowData.id}
          isMatterClosed={rowData?.matter?.isClosed}
          showMoreButtons
        />
      }
    )
  );
};

export const getColumnDefs = () => {
  const columnDefs: (ColDef<MatterInvoiceModel> | {excludeFromExport: boolean})[] = [
    {
      headerName: 'Invoice Ref Number',
      field: 'invoiceRefNumber',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Matter Ref',
      field: 'matter.fileNumberWithDisplayName',
      filter: 'agTextColumnFilter',
      cellRenderer: MatterRefCellRenderer,
    },
    {
      headerName: 'Clients',
      field: 'matterParticipatingEntities',
      filter: 'agTextColumnFilter',
      cellRenderer: DropDownOptionListCellRenderer,
      valueFormatter: DropDownOptionListFormatter,
    },
    {
      headerName: 'Invoice Date',
      field: 'date',
      filter: 'agDateColumnFilter',
      valueFormatter: DateFormatter,
      filterParams: {
        comparator: dateComparator
      },
      minWidth: 120
    },
    {
      headerName: 'Due Date',
      field: 'dueDate',
      filter: 'agDateColumnFilter',
      valueFormatter: DateFormatter,
      filterParams: {
        comparator: dateComparator
      },
      minWidth: 120
    },
    {
      headerName: 'Status',
      field: 'matterInvoiceStatus',
      cellRenderer: InvoiceStatusCellRenderer,
      filter: DropdownFilter,
      filterParams: {
        property: 'matterInvoiceStatusId',
        suppressFilterButtons: false,
      },
      floatingFilterComponent: DropdownFloatingFilter,
      floatingFilterComponentParams: {
        endpointCall: getMatterInvoiceStatuses
      },
      valueFormatter: InvoiceStatusFormatter,
      minWidth: 220
    },
    {
      headerName: 'Amount Paid',
      field: 'amountPaid',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },
    {
      headerName: 'Amount Due',
      field: 'amountDue',
      filter: 'agNumberColumnFilter',
      valueFormatter: CurrencyFormatter,
      cellClass: 'text-end',
    },
    {
      cellClass: 'lp-actions-cell',
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: (params: any) => {
        return { buttonsData: [
          {
            type: ActionButtonTypes.View,
            callback: () => viewCallback(params.data)
          }
        ]};
      },
      excludeFromExport: true,
      suppressSizeToFit: true,
      floatingFilter: false,
      sortable: false,
      minWidth: 65,
      maxWidth: 65,
      width: 65,
      suppressMovable: true,
      pinned: 'right',
      resizable: false
    }
  ];
  return columnDefs;
}