import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { getColumnDefs } from "./columnDefs";
import CreateDefaultActivityForm from "./CreateDefaultActivity/CreateDefaultActivityForm";
import { Card, Button } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Title from "components/Title/index";
import { getDefaultActivities } from "actions/settings";
import SlidingPanel from "components/SlidingPanel/index";
import { useAppSelector } from "hooks/appSelector";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { GridIds } from "enums/GridIds";

export default function DefaultActivityList() {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const loggedInUser = useAppSelector((state) => state.user);

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs();
    gridActions.setGridColumnDefs([...columnDefs]);
    getDefaultActivities().then((response) => 
    {
      gridActions.setGridRowData(response.data as any)
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  }, []);

  useEffect(() => {
    gridActions.setGridId(GridIds.SettingsDefaultActivities);
  }, []);

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Add Default Activity",
        children: <CreateDefaultActivityForm />
      });
  }

  return (
    <>
      <Title type="section" title={"Default Activities"}>
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageDefaultSettings) &&
          <Button onClick={toggleSlidingPanel} className="btn-icon" variant="success">
            <MdAdd />
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        <Card.Body>
          <Grid onGridReady={onGridReady} />
        </Card.Body>
      </Card>

      <SlidingPanel />
    </>
  );
}
