import { useEffect, useState } from 'react';
import Field from 'components/Fields/Field';
import Loader from 'components/Loader';
import { Row, Col } from 'react-bootstrap';
import {
  deleteMatterEmail,
  deleteMatterFilePermission,
  getMatterEmailById,
  getMatterFilePermissions,
} from 'actions/matter';
import { MatterEmailModel } from 'models/view/MatterEmailModel';
import BooleanField from 'components/Fields/BooleanField';
import moment from 'moment';
import { DateFormat } from 'utils/constants';
import { getEmailTypeName } from 'utils/enums';
import { MatterFilePermissionsModel } from 'models/view/MatterFilePermissionsModel';
import ListSlidingPanel from 'components/List/ListSlidingPanel';
import { ActionButtonTypes } from 'enums/ActionButtonTypes';
import { ModalState } from 'state/modalSlice';
import { DeleteMatterFilePermissionModel } from 'models/delete/DeleteMatterFilePermissionModel';
import useModalActions from 'actions/modal';
import useSlidingPanelActions from 'actions/slidingPanel';
import { useAppSelector } from 'hooks/appSelector';
import EditMatterEmailDetailsForm from '../EditMatterEmailDetails/EditMatterEmailDetailsForm';
import EditActivityHistoryTaxonomyTermsForm from '../../ActivityHistoryGrid/ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm';
import PreviewMatterEmail from '../PreviewMatterEmail/PreviewMatterEmail';
import SaveAttachments from '../SaveAttachments/SaveAttachments';
import { UserRoleNames } from 'enums/UserRoleNames';
import usePageActions from 'actions/page';

type Props = {
  matterId: string,
  emailId: string,
  activityHistoryId: string,
  isMatterClosed?: boolean,
  hasTaxonomies: boolean,
  syncMatterFiles?: Function,
  showMoreButtons?: boolean,
};

function ViewMatterEmail(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [matterEmail, setMatterEmail] = useState<MatterEmailModel>();
  const [matterFilePermissions, setMatterFilePermissions] = useState<MatterFilePermissionsModel>();
  const modalActions = useModalActions();
  const slidingPanelActions = useSlidingPanelActions();
  const pageActions = usePageActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(() => {
    setIsLoading(true);
    getMatterEmailById(props.matterId, props.emailId)
      .then((response) => {
        setMatterEmail(response.data);

        const canManageMails = response.data.isCurrentUserSenderOrRecipient || 
          (loggedInUser.userRole?.name != UserRoleNames.Clerk &&
          loggedInUser.userRole?.name != UserRoleNames.BackOffice && 
          loggedInUser.userRole?.name != UserRoleNames.Accountant &&
          loggedInUser.userRole?.name != UserRoleNames.EntryLevel &&
          !response.data.isCurrentUserSenderOrRecipient);

        // Set edit button action
        if (!props.isMatterClosed && canManageMails) {
          slidingPanelActions.setOnEdit(() => {
            slidingPanelActions.clearSlidingPanel();

            slidingPanelActions.setSlidingPanel(
            {
              isShown: true,
              title: "Edit Matter Email Details",
              children: <EditMatterEmailDetailsForm
                matterId={props.matterId}
                emailId={props.emailId}
                onSubmitCallback={onChildPanelClose}
              />,
              onCancel: onChildPanelClose
            });
          });
        }

        if (!props.isMatterClosed && props.showMoreButtons && canManageMails) {
          slidingPanelActions.setMoreButtons([
            {
              type: ActionButtonTypes.PreviewEmail,
              callback: () => onClickPreview(),
            },
            ...(props.hasTaxonomies ? [{
              type: ActionButtonTypes.EditTaxonomyTerms,
              callback: () => onClickTaxonomies(),
            }] : []),
            {
              type: ActionButtonTypes.SaveAttachments,
              callback: () => onClickSaveAttachments(),
            },
            {
              type: ActionButtonTypes.Delete,
              callback: () => onClickDelete(),
            }
          ]);
        }

        if (response.data?.isEmailFileDeletedOnStorage == false) {
          getMatterFilePermissions(props.matterId, response.data.matterFileId)
            .then((getMatterFilePermissionsResponse) => {
              setMatterFilePermissions(getMatterFilePermissionsResponse.data);
            })
            .catch((error) => {
              setGenericErrors(error.response?.data?.Message ?? error.message);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        setIsLoading(false);
      });
  }, [props.emailId]);

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  }

  const onClickPreview = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        allowNavigation: true,
        title: "Preview Matter Email",
        children: <PreviewMatterEmail
          matterId={props.matterId}
          emailId={props.emailId!}
          activityHistoryId={props.activityHistoryId}
          isMatterClosed={props.isMatterClosed}
          hasTaxonomies={props.hasTaxonomies}
          syncMatterFiles={props.syncMatterFiles}
          showMoreButtons
        />
      }
    );
  };

  const onClickTaxonomies = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        children: <EditActivityHistoryTaxonomyTermsForm
          matterId={props.matterId}
          activityHistoryId={props.activityHistoryId}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const onClickSaveAttachments = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Save Attachments",
        children: <SaveAttachments
          matterId={props.matterId}
          emailId={props.emailId}
          syncMatterFiles={props.syncMatterFiles}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const onClickDelete = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Recordable Event?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.emailId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const deleteCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    deleteMatterEmail(matterId, activityId).then(() => {
      //reload the whole grid, because we might have RecordableEvents that changed by deleting the email
      pageActions.triggerReloadPage();

      modalActions.toggleModalShownStatus();
      slidingPanelActions.clearSlidingPanel();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  const deletePermissionModal = (email: string, isEditPermission: boolean) => {
    let modal: ModalState = {
      title: 'Delete confirmation',
      body: 'Are you sure you want to delete this permission?',
      actionText: 'Delete',
      actionVariant: 'danger',
      onAction: () => deletePermissionCallback(email, isEditPermission),
      show: true,
    };
    modalActions.setModal(modal);
  };

  const deletePermissionCallback = (
    email: string,
    isEditPermission: boolean
  ) => {
    setIsLoading(true);
    const data: DeleteMatterFilePermissionModel = { email: email };
    if (!matterEmail?.matterFileId) {
      return;
    }
    deleteMatterFilePermission(props.matterId, matterEmail.matterFileId, data)
      .then(() => {
        if (isEditPermission) {
          const perm = matterFilePermissions?.editPermissions?.find(
            (x) => x.email == email
          );
          if (!perm?.isInherited) {
            setMatterFilePermissions({
              editPermissions:
                matterFilePermissions?.editPermissions?.filter(
                  (x) => x.email != email
                ) ?? [],
              viewPermissions: matterFilePermissions?.viewPermissions ?? [],
            });
          }
        } else {
          const perm = matterFilePermissions?.viewPermissions?.find(
            (x) => x.email == email
          );
          if (!perm?.isInherited) {
            setMatterFilePermissions({
              editPermissions: matterFilePermissions?.editPermissions ?? [],
              viewPermissions:
                matterFilePermissions?.viewPermissions?.filter(
                  (x) => x.email != email
                ) ?? [],
            });
          }
        }
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Row>
        <Col>
          <Field
            label={"Description"}
            value={matterEmail?.description}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field label={'Subject'} value={matterEmail?.subject} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={'Sender'}
            value={matterEmail?.senderEmailAddress}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={'To'}
            value={
              matterEmail?.toRecipients && matterEmail?.toRecipients.length > 0
                ? matterEmail?.toRecipients.join(', ')
                : '—'
            }
          />
          {matterEmail?.ccRecipients &&
            matterEmail?.ccRecipients.length > 0 && (
              <Field
                label={'Cc'}
                value={matterEmail?.ccRecipients.join(', ')}
              />
            )}
          {matterEmail?.bccRecipients &&
            matterEmail?.bccRecipients.length > 0 && (
              <Field
                label={'Bcc'}
                value={matterEmail?.bccRecipients.join(', ')}
              />
            )}
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field label={'Type'} value={getEmailTypeName(matterEmail?.type!)} />
        </Col>
        {matterEmail?.type == 0 && (
          <Col sm={6} className="mt-4 mt-sm-0">
            <Field
              label={'Sent Date Time'}
              value={
                matterEmail?.sentDateTime
                  ? moment(matterEmail?.sentDateTime).format(
                      DateFormat.MomentWithTime
                    )
                  : '—'
              }
            />
          </Col>
        )}
        {matterEmail?.type != 0 && (
          <Col sm={6} className="mt-4 mt-sm-0">
            <Field
              label={'Received Date Time'}
              value={
                matterEmail?.receivedDateTime
                  ? moment(matterEmail?.receivedDateTime).format(
                      DateFormat.MomentWithTime
                    )
                  : '—'
              }
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col sm={6}>
          <BooleanField
            label={'Has Attachments'}
            value={matterEmail?.hasAttachments ?? false}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <BooleanField
            label={'Internal'}
            value={matterEmail?.isInternal ?? false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Additional Note"}
            value={matterEmail?.additionalNote}
          />
        </Col>
      </Row>
      {
        matterEmail?.hasAttachments &&
        <Row>
          <Col>
            <div className="departments">
              <ListSlidingPanel
                listItems={
                  matterEmail?.attachments?.map((x) => ({ id: x, name: x })) ?? []
                }
                listButtons={[]}
              >
                Attachments
              </ListSlidingPanel>
            </div>
          </Col>
        </Row>
      }
      {matterEmail?.isEmailFileDeletedOnStorage == false && (
        <>
          <Row>
            <Col>
              <div className="departments">
                <ListSlidingPanel
                  listItems={
                    matterFilePermissions?.editPermissions?.map((x) => ({
                      id: x.email,
                      name: x.email,
                      hideDeleteBtn: x.isInherited,
                    })) ?? []
                  }
                  listButtons={!matterEmail.matter?.isClosed ? [
                    {
                      type: ActionButtonTypes.Delete,
                      callback: (id: string) => deletePermissionModal(id, true),
                    }
                  ] : []}
                >
                  People that can view & edit
                </ListSlidingPanel>
              </div>
              <div className="departments">
                <ListSlidingPanel
                  listItems={
                    matterFilePermissions?.viewPermissions?.map((x) => ({
                      id: x.email,
                      name: x.email,
                      hideDeleteBtn: x.isInherited,
                    })) ?? []
                  }
                  listButtons={!matterEmail.matter?.isClosed ? [
                    {
                      type: ActionButtonTypes.Delete,
                      callback: (id: string) =>
                        deletePermissionModal(id, false),
                    },
                  ] : []}
                >
                  People that can view
                </ListSlidingPanel>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default ViewMatterEmail;
