import SlidingPanel from "components/SlidingPanel";
import Loader from "components/Loader";
import Grid from "components/Grid";
import { Card, Col, Row } from "react-bootstrap";
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import useGridActions from "actions/grid";
import { getMatterDashboardEvents } from "actions/matter";
import { getColumnDefs, viewCallback } from "./columnDefs";
import moment from "moment";
import Title from "components/Title";
import { DateFormat } from "utils/constants";
import { getDateOnly } from "utils/date";
import DatePicker from "react-datepicker";
import './style.scss';
import { GridIds } from "enums/GridIds";
import { AgGridReact } from "ag-grid-react";
import usePageActions from "actions/page";

type Props = {
  matterId: string,
  startDate: Date,
  endDate: Date,
  setStartDate: Dispatch<SetStateAction<Date>>,
  setEndDate: Dispatch<SetStateAction<Date>>,
}

export default function MatterAppointments(props: Props) {
  const gridActions = useGridActions();
  const pageActions = usePageActions();
  const [genericErrors, setGenericErrors] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const gridRef = useRef<AgGridReact>(null);

  const setGridData = () => {
    setIsLoading(true);
    const userTimeZone = moment.tz.guess();
    getMatterDashboardEvents(props.matterId, props.startDate.toISOString(), props.endDate.toISOString(), userTimeZone).then((response) =>
    {
      gridActions.setGridRowData(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }
  
  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs(props.matterId);
    gridActions.setGridColumnDefs([...columnDefs]);
  }, [props.matterId]);

  useEffect(() => {
    if(props.startDate >= props.endDate) {
      setGenericErrors("Start Date cannot be after End Date");
      return;
    }
    setGenericErrors(undefined);
    setGridData();

    pageActions.setReloadGridData(setGridData);
  }, [props.matterId, props.startDate, props.endDate]);

  useEffect(() => {
    gridActions.setGridId(GridIds.Appointments);

    return () => {
      pageActions.resetPageData();
    }
  }, []);

  return (
    <>
      <Row>
        <Col>
          <Title
            type="section"
            title="Appointments"
          >
          </Title>

          {genericErrors && (
            <div className="lp-errors">
              {genericErrors}
            </div>
          )}

          {isLoading && <Loader inlineLoader />}

          <Card className="with-grid">
            <Card.Body>
              <div className="lp-calendar-date appointments">
                <span>From</span>
                <DatePicker 
                  dateFormat={DateFormat.Datepicker}
                  value={moment(props.startDate).format(DateFormat.Moment)}
                  selected={props.startDate ? getDateOnly(props.startDate) : null}
                  onChange={(date: Date) => {props.setStartDate(date)}}
                  showMonthDropdown
                  showYearDropdown
                  autoComplete="off"
                />
                <span>to</span>
                <DatePicker 
                  dateFormat={DateFormat.Datepicker}
                  value={moment(props.endDate).format(DateFormat.Moment)}
                  selected={props.endDate ? getDateOnly(props.endDate) : null}
                  onChange={(date: Date) => props.setEndDate(date)}
                  showMonthDropdown
                  showYearDropdown
                  autoComplete="off"
                />
              </div>
              <Grid
                ref={gridRef}
                onGridReady={onGridReady}
                skipRowIdCheck={true}
              />
            </Card.Body>
          </Card>

          <SlidingPanel gridRef={gridRef} onNavigation={(item: any) => viewCallback(props.matterId, item.graphId)} />
        </Col>
      </Row>
    </>
  )
}
