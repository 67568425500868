import useWindowSize from "hooks/windowSize";
import React, { useEffect, useState, useRef } from "react";
import { Accordion } from "react-bootstrap";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import Title from "components/Title";

type Props = {
  children: React.ReactNode,
  id: string,
}

export default function SummaryBoxesSection(props: Props) {
  const [width] = useWindowSize();
  const [isExpanded, setIsExpanded] = useState<boolean>(localStorage.getItem(`Summary_${props.id}`) === 'true');
  const [summaryBoxesNumber, setSummaryBoxesNumber] = useState(0);
  const rowRef = useRef<HTMLDivElement>(null);

  const toggleSummaryBoxes = () => {
    setIsExpanded(!isExpanded);
    localStorage.setItem(`Summary_${props.id}`, (!isExpanded).toString());

    if(isExpanded) {
      document.getElementsByClassName('react-tabs__tab-panel--selected')[0].classList.remove('lp-summary-boxes-expanded');
    } else {
      document.getElementsByClassName('react-tabs__tab-panel--selected')[0].classList.add('lp-summary-boxes-expanded');
    }
  }

  useEffect(() => {
    const summaryArea = document.querySelectorAll('.lp-summary-boxes-area')?.[0];
    if (summaryArea) {
      setSummaryBoxesNumber(summaryArea.children.length);
    }
  }, []);

  return (
    <>
      {width < 1200 ?
        <Accordion>
          <Accordion.Item eventKey="0" className="lp-summary-boxes-accordion">
            <Accordion.Header>Statistics Cards</Accordion.Header>
            <Accordion.Body>
              <div className={`lp-summary-boxes-area boxes-${summaryBoxesNumber}`}>
                {props.children}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      : 
        <>
          <Title type="section" title={"Statistics Cards"} className="lp-title boxes" onClick={toggleSummaryBoxes}>
            {isExpanded ? <MdExpandLess /> : <MdExpandMore />}
          </Title>
          <div className={`${isExpanded ? 'expanded' : 'collapsed'}`} ref={rowRef}>
            <div className={`lp-summary-boxes-area boxes-${summaryBoxesNumber}`}>
              {props.children}
            </div>
          </div>
        </>
      }
    </>
  );
}