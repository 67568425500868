import { useEffect, useState } from "react";
import Loader from "components/Loader";
import { Row, Col, Button } from "react-bootstrap";
import { createForwardDraftEmail, createReplyAllDraftEmail, createReplyDraftEmail, deleteMatterEmail, getMatterEmailById, getMatterEmailPreview } from "actions/matter";
import { BiLinkExternal } from 'react-icons/bi';
import { useAppSelector } from "hooks/appSelector";
import { MdReply, MdReplyAll } from 'react-icons/md';
import "./style.scss";
import { openUrlInNewtab } from "utils/misc";
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import EditActivityHistoryTaxonomyTermsForm from "../../ActivityHistoryGrid/ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm";
import SaveAttachments from "../SaveAttachments/SaveAttachments";
import { ModalState } from "state/modalSlice";
import ViewMatterEmail from "../ViewMatterEmail/ViewMatterEmail";
import { UserRoleNames } from "enums/UserRoleNames";
import usePageActions from "actions/page";

type Props = {
  matterId: string,
  emailId: string,
  activityHistoryId: string,
  isMatterClosed?: boolean,
  hasTaxonomies: boolean,
  syncMatterFiles?: Function,
  showMoreButtons?: boolean,
}

function PreviewMatterEmail(props: Props){
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [matterEmailPreviewUrl, setMatterEmailPreviewUrl] = useState<string>();
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const modalActions = useModalActions();
  const pageActions = usePageActions();

  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    setIsLoading(true);
    getMatterEmailById(props.matterId, props.emailId)
      .then((response) => {
        const canManageMails = response.data.isCurrentUserSenderOrRecipient || 
          (user.userRole?.name != UserRoleNames.Clerk &&
            user.userRole?.name != UserRoleNames.BackOffice && 
            user.userRole?.name != UserRoleNames.Accountant &&
            user.userRole?.name != UserRoleNames.EntryLevel &&
            !response.data.isCurrentUserSenderOrRecipient);

        if (!props.isMatterClosed && props.showMoreButtons && canManageMails) {
          slidingPanelActions.setMoreButtons([
            {
              type: ActionButtonTypes.View,
              callback: () => onClickViewDetails(),
            },
            ...(props.hasTaxonomies ? [{
              type: ActionButtonTypes.EditTaxonomyTerms,
              callback: () => onClickTaxonomies(),
            }] : []),
            {
              type: ActionButtonTypes.SaveAttachments,
              callback: () => onClickSaveAttachments(),
            },
            {
              type: ActionButtonTypes.Delete,
              callback: () => onClickDelete(),
            }
          ]);
        }

        getMatterEmailPreview(props.matterId, props.emailId).then((response2) => {
          setMatterEmailPreviewUrl(response2.data.getUrl);
        })
        .catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
          setMatterEmailPreviewUrl(undefined);
        })
        .finally(() => {
          setIsLoading(false);
        });
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        setIsLoading(false);
      });
  }, [props.emailId]);

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  }

  const onClickViewDetails = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        allowNavigation: true,
        title: "View Matter Email Details",
        children: <ViewMatterEmail
          matterId={props.matterId}
          emailId={props.emailId!}
          activityHistoryId={props.activityHistoryId}
          isMatterClosed={props.isMatterClosed}
          hasTaxonomies={props.hasTaxonomies}
          syncMatterFiles={props.syncMatterFiles}
          showMoreButtons
        />,
      }
    );
  };

  const onClickTaxonomies = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        children: <EditActivityHistoryTaxonomyTermsForm
          matterId={props.matterId}
          activityHistoryId={props.activityHistoryId}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const onClickSaveAttachments = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Save Attachments",
        children: <SaveAttachments
          matterId={props.matterId}
          emailId={props.emailId}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const onClickDelete = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Recordable Event?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.emailId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const deleteCallback = (matterId: string, activityId: string) => {
    modalActions.toggleModalLoading();
    deleteMatterEmail(matterId, activityId).then(() => {
      //reload the whole grid, because we might have RecordableEvents that changed by deleting the email
      pageActions.triggerReloadPage();

      modalActions.toggleModalShownStatus();
      slidingPanelActions.clearSlidingPanel();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  function createDraftReplyEmailHandler() {
    setIsLoading(true);
    createReplyDraftEmail(props.matterId, props.emailId, user.msalAccessToken!)
      .then((response) => {
        openUrlInNewtab(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function createDraftReplyAllEmailHandler() {
    setIsLoading(true);
    createReplyAllDraftEmail(props.matterId, props.emailId, user.msalAccessToken!)
      .then((response) => {
        openUrlInNewtab(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function createDraftForwardEmailHandler() {
    setIsLoading(true);
    createForwardDraftEmail(props.matterId, props.emailId, user.msalAccessToken!)
      .then((response) => {
        openUrlInNewtab(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return(
    <div className="lp-iframe-panel">
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      {matterEmailPreviewUrl &&
        <>
          <Row>
            <Col className="lp-email-action-btns">
              <Button onClick={createDraftReplyEmailHandler} variant="secondary-400">
                <MdReply />
                <span>Reply</span>
              </Button>
              <Button onClick={createDraftReplyAllEmailHandler} variant="secondary-400">
                <MdReplyAll />
                <span>Reply all</span>
              </Button>
              <Button onClick={createDraftForwardEmailHandler} variant="secondary-400 reverse-icon">
                <MdReply />
                <span>Forward</span>
              </Button>
            </Col>
          </Row>

          <Row className="full-height">
            <Col>
              <iframe src={matterEmailPreviewUrl} title="Matter Email Preview" height="100%" width="100%"></iframe> 
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="d-flex justify-content-between">
                <Button variant="primary" onClick={() => openUrlInNewtab(matterEmailPreviewUrl)}>
                  <BiLinkExternal />
                  Open in new tab
                </Button>
              </div>
            </Col>
          </Row>
        </>
      }
    </div>
  );
}

export default PreviewMatterEmail;
