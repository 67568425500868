import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import { vestResolver } from '@hookform/resolvers/vest';
import { Form, Button, Col, Row } from 'react-bootstrap';
import useSlidingPanelActions from 'actions/slidingPanel';
import Loader from 'components/Loader';
import { getValidationSuite } from './validationSuite';
import Upload from 'components/Upload';
import { addTemplate, getEntityRolesSummary, getLTEHeaderFooterTypesSummary, getMatterTemplateTypesSummary, getTemplateTypeById } from 'actions/settings';
import { CreateOrUpdateTemplateFormModel } from 'models/create/CreateOrUpdateTemplateFormModel';
import CustomSelect from 'components/Select/Select';
import { useAppSelector } from 'hooks/appSelector';
import { HeaderAndFooterTemplateTypeIds } from 'enums/HeaderAndFooterTemplateTypeIds';
import { DropDownOptionModel } from 'models/view/DropDownOptionModel';
import FormErrorButton from 'components/Buttons/FormErrorButton';

type Props = {
  refreshTemplatesPage: Function,
  typeId?: string
  isMatterTemplate?: boolean
}

export default function CreateTemplate(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const slidingPanelActions = useSlidingPanelActions();
  const user = useAppSelector((state) => state.user);
  const [matterTypes, setMatterTypes] = useState<DropDownOptionModel[]>([]);
  const [isLoadingMatterTypes, setIsLoadingMatterTypes] = useState<boolean>(false);

  const {
    reset,
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<CreateOrUpdateTemplateFormModel>({
    resolver: vestResolver(getValidationSuite(props.isMatterTemplate ?? false)),
    defaultValues: {
      typeId: props.typeId,
      useParticipatingEntity: false,
      participatingEntityIsInvoicingParty: false,
      removeEmptyTables: false
    }
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateTemplateFormModel) {
    const formData = new FormData();

    formData.append('file', data.file[0]);
    formData.append('name', data.name);
    formData.append('description', data.description);
    formData.append('useHeaderAndFooter', data.useHeaderAndFooter ? "true" : "false");
    formData.append('typeId', data.typeId);
    formData.append('useParticipatingEntity', data.useParticipatingEntity ? "true" : "false");
    formData.append('participatingEntityIsInvoicingParty', data.participatingEntityIsInvoicingParty ? "true" : "false");
    if(data.participatingEntityEntityRoleId) {
      formData.append('participatingEntityEntityRoleId', data.participatingEntityEntityRoleId);
    }
    formData.append('removeEmptyTables', data.removeEmptyTables ? "true" : "false");
    formData.append('lteId', user.lawPageTradingEntityId!);

    if(data.matterTypeIds) {
      for (let matterTypeId of data.matterTypeIds) {
        formData.append('matterTypeIds', matterTypeId);
      }
    }

    setIsLoading(true);

    addTemplate(formData)
      .then(() => {
        slidingPanelActions.clearSlidingPanel();
        reset();
        props.refreshTemplatesPage();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  };

  const onChangeTypeId = (value?: string) => {
    if(value){
      setIsLoadingMatterTypes(true);
      getTemplateTypeById(value).then((response) => {
        setMatterTypes(response.data.matterTypes);
      }).catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        setMatterTypes([]);
      })
      .finally(() => {
        setIsLoadingMatterTypes(false);
      });
    }
    else {
      setMatterTypes([]);
    }
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && <div className="lp-errors">{genericErrors}</div>}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="name">
            <Form.Label className="required">Name</Form.Label>
            <Form.Control
              type="text"
              className={`${
                errors?.name?.message
                  ? 'invalid'
                  : ''
              }`}
              {...register('name', {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.name?.message &&
                errors.name.message}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              {...register('description', {shouldUnregister: true})}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="typeId">
            <Form.Label className="required">Type</Form.Label>
            <Controller
              control={control}
              name="typeId"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <CustomSelect
                  isDisabled={props.typeId != undefined}
                  id="typeId"
                  inputRef={ref}
                  className={`lp-select${errors?.typeId?.message ? ' invalid' : ''}`}
                  endpointCall={props.typeId ? getLTEHeaderFooterTypesSummary : getMatterTemplateTypesSummary}
                  value={value}
                  onChange={val => { onChange(val?.id ?? null); onChangeTypeId(val?.id); }}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.typeId?.message && (errors.typeId.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        {watch('typeId') != HeaderAndFooterTemplateTypeIds.HeaderAndFooterTemplateId &&
          <Row>
            <Form.Group as={Col} sm={6} controlId="useHeaderAndFooter">
              <Form.Label>Use Header & Footer Template</Form.Label>
              <Controller
                control={control}
                name="useHeaderAndFooter"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Form.Check type="switch" id="useHeaderAndFooter">
                    <Form.Check.Input
                      className="form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => {
                        onChange(ev.target.checked);
                      }}
                    />
                  </Form.Check>
                )}
              />
            </Form.Group>

            <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="removeEmptyTables">
              <Form.Label>Remove Empty Tables</Form.Label>
              <Controller
                control={control}
                name="removeEmptyTables"
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Form.Check type="switch" id="removeEmptyTables">
                    <Form.Check.Input
                      className="form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => {
                        onChange(ev.target.checked);
                      }}
                    />
                  </Form.Check>
                )}
              />
            </Form.Group>
          </Row>
        }

        {props.isMatterTemplate &&
          <>
            <Row>
              <Form.Group as={Col} controlId="matterTypeIds">
                <Form.Label className="required">Matter Types</Form.Label>
                <Controller
                  control={control}
                  name="matterTypeIds"
                  shouldUnregister={true}
                  render={({field: { onChange, value, name, ref }}) => (
                    <CustomSelect
                      id="matterTypeIds"
                      inputRef={ref}
                      className={`lp-select${errors?.matterTypeIds?.message ? ' invalid' : ''}`}
                      options={matterTypes}
                      isLoading={isLoadingMatterTypes}
                      value={value}
                      onChange={val => onChange(val?.map((x: { id: any; }) => x.id) ?? null)}
                      isMulti
                      isClearable
                    />
                  )}
                />
                <Form.Text className="lp-error">
                  {errors?.matterTypeIds?.message && (errors.matterTypeIds.message)}
                </Form.Text>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} controlId="useParticipatingEntity">
                <Form.Label>Use Participating Entity</Form.Label>
                <Controller
                  control={control}
                  name="useParticipatingEntity"
                  shouldUnregister={true}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Form.Check type="switch" id="useParticipatingEntity">
                      <Form.Check.Input
                        className="form-check-input"
                        ref={ref}
                        checked={value ?? false}
                        onChange={(ev: any) => {
                          onChange(ev.target.checked);
                        }}
                      />
                    </Form.Check>
                  )}
                />
              </Form.Group>
            </Row>

            {watch('useParticipatingEntity') && 
              <Row>
                <Form.Group as={Col} xs={12}>
                  <div className="lp-text-title">Restrict Participating Entities to</div>
                </Form.Group>
                
                <Form.Group as={Col} sm={6} controlId="participatingEntityIsInvoicingParty">
                  <Form.Label>Is Invoicing Party Entity</Form.Label>
                  <Controller
                    control={control}
                    name="participatingEntityIsInvoicingParty"
                    shouldUnregister={true}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Form.Check type="switch" id="participatingEntityIsInvoicingParty">
                        <Form.Check.Input
                          className="form-check-input"
                          ref={ref}
                          checked={value ?? false}
                          onChange={(ev: any) => {
                            onChange(ev.target.checked);
                          }}
                        />
                      </Form.Check>
                    )}
                  />
                </Form.Group>

                <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="participatingEntityEntityRoleId">
                  <Form.Label>Participating Entity Role</Form.Label>
                  <Controller
                    control={control}
                    name="participatingEntityEntityRoleId"
                    shouldUnregister={true}
                    render={({ field: { onChange, value } }) => (
                      <CustomSelect
                        id="participatingEntityEntityRoleId"
                        endpointCall={getEntityRolesSummary}
                        value={value}
                        onChange={(val) => onChange(val?.id ?? null)}
                        isClearable
                      />
                    )}
                  />
                </Form.Group>
              </Row>
            }
          </>
        }
        
        <Row>
          <Form.Group as={Col} controlId="file">
            <Form.Label className="required">File</Form.Label>
            <Controller
              control={control}
              name="file"
              shouldUnregister={true}
              render={({ field: { onChange } }) => (
                <Upload
                  className={`${errors?.file?.message ? 'invalid' : ''}`}
                  onChange={(val) => onChange(val ?? null)}
                  accept='.docx'
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.file?.message && errors.file.message}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Create" />
              : <Button variant="success" type="submit">Create</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>
              Cancel
            </Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
