import { useCallback, useEffect, useState } from 'react';
import { MdAddCircle } from 'react-icons/md';
import Grid from "components/Grid";
import Title from 'components/Title';
import SlidingPanel from 'components/SlidingPanel';
import { Card, Button } from 'react-bootstrap';
import useGridActions from 'actions/grid';
import CreateMatterForm from 'containers/Matter/CreateMatter/CreateMatterForm';
import { getColumnDefs } from './columnDefs';
import { getMatters } from 'actions/matter';
import useSlidingPanelActions from 'actions/slidingPanel';
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import { GridIds } from 'enums/GridIds';

function MatterList() {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const slidingPanelState = useAppSelector((state) => state.slidingPanel);
  const [genericErrors, setGenericErrors] = useState(null);
  const loggedInUser = useAppSelector((state) => state.user);

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs();
    gridActions.setGridColumnDefs([...columnDefs]);
    getMatters().then((response) => 
    {
      gridActions.setGridRowData(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  }, []);

  useEffect(() => {
    gridActions.setGridId(GridIds.Matters);
  }, []);

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: !slidingPanelState.isShown,
        title: "Add Matter",
        children: <CreateMatterForm />
      });
  };

  return (
    <div className="lp-page-content">
      <Title
        title="Matters"
        type='page'
      >
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatters) &&
          <Button variant="success" onClick={toggleSlidingPanel}>
            <MdAddCircle />
            Add Matter
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        <Card.Body>
          <Grid 
            onGridReady={onGridReady}
          />
        </Card.Body>
      </Card>

      <SlidingPanel />
    </div>
  );
}

export default MatterList;
