import useGridActions from "actions/grid";
import { useEffect, useRef, useState } from "react";
import { getColumnDefs, viewCallback } from "./columnDefs";
import { Card } from "react-bootstrap";
import Title from "components/Title";
import DatePicker from "react-datepicker";
import SlidingPanel from "components/SlidingPanel";
import Loader from "components/Loader";
import { DateFormat } from "utils/constants";
import moment from "moment";
import { getDateOnly } from "utils/date";
import Grid from "components/Grid";
import { getDiaryManagerEvents } from "actions/lte";
import { AgGridReact } from "ag-grid-react";
import { GridIds } from "enums/GridIds";
import { getUserCalendarSettings } from "actions/user";
import { useAppSelector } from "hooks/appSelector";
import { DayOfWeek } from "enums/DayOfWeek";

export default function ViewDiaryManagerList() {
  const gridActions = useGridActions();
  const [genericErrors, setGenericErrors] = useState<string | undefined>();
  const [asyncColumnDefsLoaded, setAsyncColumnDefsLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const gridRef = useRef<AgGridReact>(null);
  const user = useAppSelector((state) => state.user);

  const setGridData = () => {
    setIsLoading(true);
    const userTimeZone = moment.tz.guess();

    getDiaryManagerEvents(startDate!.toISOString(), endDate!.toISOString(), userTimeZone).then((response) => {
      gridActions.setGridRowData(response.data);
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  // Get calendar settings and set column defs accordingly
  useEffect(() => {
    gridActions.setGridId(GridIds.DiaryManagerCalendar);

    getUserCalendarSettings(user.userId!).then((calendarSettings) => {
      gridActions.setGridColumnDefs(getColumnDefs(calendarSettings.data.dateFormat));
      setAsyncColumnDefsLoaded(true);

      // Get the day of the week that matches the settings
      let weekStartDate = moment().day(DayOfWeek[calendarSettings.data.firstDayOfWeek]).startOf('day');
      // If the weekStartDate is in the future, set the start date to the previous week
      if(weekStartDate.isAfter(moment())) {
        weekStartDate = weekStartDate.subtract(7, 'days').startOf('day');
      }
      const weekEndDate = weekStartDate.clone().add(6, 'days').endOf('day');

      // After setting startDate and endDate, another useEffect will get triggered to set the grid data
      setStartDate(weekStartDate.toDate());
      setEndDate(weekEndDate.toDate());
    }).catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    });
  }, []);

  useEffect(() => {
    if(startDate && endDate && startDate >= endDate) {
      setGenericErrors("Start Date cannot be after End Date");
      return;
    }
    setGenericErrors(undefined);

    if(startDate && endDate) {
      setGridData();
    }
  }, [startDate, endDate]);

  return (
    <>
      <Title
        type="section"
        title={<>Diary Manager List</>}
      ></Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        {isLoading && <Loader inlineLoader />}
        
        <Card.Body>
          <div className="lp-calendar-date appointments">
            <span>From</span>
            <DatePicker 
              dateFormat={DateFormat.Datepicker}
              value={moment(startDate).format(DateFormat.Moment)}
              selected={startDate ? getDateOnly(startDate) : null}
              onChange={(date: Date) => {setStartDate(date)}}
              showMonthDropdown
              showYearDropdown
              autoComplete="off"
            />
            <span>to</span>
            <DatePicker 
              dateFormat={DateFormat.Datepicker}
              value={moment(endDate).format(DateFormat.Moment)}
              selected={endDate ? getDateOnly(endDate) : null}
              onChange={(date: Date) => setEndDate(date)}
              showMonthDropdown
              showYearDropdown
              autoComplete="off"
            />
          </div>
          <Grid
            ref={gridRef}
            skipRowIdCheck={true}
            asyncColumnDefsLoaded={asyncColumnDefsLoaded}
          />
        </Card.Body>
      </Card>

      <SlidingPanel gridRef={gridRef} onNavigation={(item: any) => viewCallback(item.graphId)} />
    </>
  )
}
