import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import useGridActions from "actions/grid";
import { changeDelegatedUserStatus, getDelegatedUserById } from "actions/user";
import BooleanField from "components/Fields/BooleanField";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { DelegatedUserModel } from "models/view/DelegatedUserModel";
import EditDelegatedUserForm from "../EditDelegatedUser/EditDelegatedUserForm";

type Props = {
  lteId: string,
  delegatedUserId: string,
  showDelegateAccessFields?: boolean,
  showManageDiaryFields?: boolean
}

export default function ViewDelegatedUser(props: Props){
  const [isLoading, setIsLoading] = useState(true);
  const [delegatedUser, setDelegatedUser] = useState<DelegatedUserModel>();
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const modalActions = useModalActions();
  const gridActions = useGridActions();
  const currentGridState = useAppSelector((state) => state.grid);
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(() => {
    loadDelegatedUser();
  }, [props.delegatedUserId]);

  const loadDelegatedUser = () => {
    setIsLoading(true);
    getDelegatedUserById(props.delegatedUserId).then((response) => {
      setDelegatedUser(response.data);

      // Set edit button action
      if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageLTEDelegateUsers)) {
        slidingPanelActions.setOnEdit(() => {
          slidingPanelActions.clearSlidingPanel();

          slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: "Edit User",
            children: <EditDelegatedUserForm
              lteId={props.lteId}
              delegatedUserId={props.delegatedUserId}
              onSubmitCallback={onChildPanelClose}
              showDelegateAccessFields={props.showDelegateAccessFields}
              showManageDiaryFields={props.showManageDiaryFields}
            />,
            onCancel: onChildPanelClose
          });
        });

        slidingPanelActions.setMoreButtons([
          {
              type: response.data.active ? ActionButtonTypes.Deactivate : ActionButtonTypes.Activate,
              callback: () => changeStatusModal()
          }
        ]);
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  };

  const changeStatusModal = () => {
    let modal: ModalState = {
      title: "Confirmation",
      body: "Are you sure you want to change the status for this User?",
      onAction: () => changeStatus(props.delegatedUserId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const changeStatus = (delegatedUserId: string) => {
    const gridState = currentGridState;
    modalActions.toggleModalLoading();
    changeDelegatedUserStatus(delegatedUserId).then(() => {
      loadDelegatedUser();
      let index: number = gridState.rowData.findIndex((x: any) => x.id == delegatedUserId);
      let newEntry: DelegatedUserModel = { ...gridState.rowData[index], active: !gridState.rowData[index].active };
      let newArray: Array<any> = [...gridState.rowData];
      newArray[index] = newEntry;
      gridActions.setGridRowData(newArray);
      modalActions.toggleModalShownStatus();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  return(
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col sm={props.showDelegateAccessFields ? 6 : 12}>
          <Field
            label={"User"}
            value={delegatedUser?.user?.name ?? "—"}
          />
        </Col>
        {props.showDelegateAccessFields &&
          <Col sm={6} className="mt-4 mt-sm-0">
            <Field
              label={"User Role"}
              value={delegatedUser?.userRole?.name ?? "—"}
            />
          </Col>
        }
      </Row>
      {props.showDelegateAccessFields &&
        <Row>
          <Col>
            <Field
              label={"Additional Permissions"}
              value={delegatedUser?.userPermissions?.length ? delegatedUser?.userPermissions?.map(e => e.name)?.join(", ") : "—"}
            />
          </Col>
        </Row>
      }
      <Row>
        {props.showManageDiaryFields &&
          <Col sm={6}>
            <BooleanField
              label={"Manage Diary"}
              value={delegatedUser?.managesDiary ?? false}
            />
          </Col>
        }
        <Col sm={props.showManageDiaryFields ? 6 : 12} className={props.showManageDiaryFields ? 'mt-4 mt-sm-0' : ''}>
          <BooleanField
            label={"Active"}
            value={delegatedUser?.active ?? false}
          />
        </Col>
      </Row>
    </>
  );
}
