import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col, Button } from "react-bootstrap";
import { deleteMatterDisbursement, getMatterDisbursementById } from "actions/matter";
import { MatterDisbursementModel } from "models/view/MatterDisbursementModel";
import moment from "moment";
import { DateFormat } from "utils/constants";
import ListSlidingPanel from "components/List/ListSlidingPanel";
import _ from 'lodash';
import { formatCurrency } from "utils/misc";
import useSlidingPanelActions from "actions/slidingPanel";
import useModalActions from "actions/modal";
import { ModalState } from "state/modalSlice";
import { useAppSelector } from "hooks/appSelector";
import EditMatterDisbursementForm from "../EditMatterDisbursement/EditMatterDisbursementForm";
import BooleanField from "components/Fields/BooleanField";
import ViewMatterDisbursementPayments from "../ViewMatterDisbursementPayments/ViewMatterDisbursementPayments";
import MatterDisbursementWriteOffForm from "../MatterDisbursementWriteOff/MatterDisbursementWriteOffForm";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import EditActivityHistoryTaxonomyTermsForm from "../../ActivityHistoryGrid/ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import useGridActions from "actions/grid";
import usePageActions from "actions/page";

type Props = {
  matterId: string,
  matterDisbursementId: string,
  activityHistoryId?: string,
  hasTaxonomies?: boolean,
  syncMatterFiles?: Function,
  showMoreButtons?: boolean,
}

function ViewMatterDisbursement(props: Props){
  const [isLoading, setIsLoading] = useState(true);
  const [matterDisbursement, setMatterDisbursement] = useState<MatterDisbursementModel>();
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const modalActions = useModalActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const loggedInUser = useAppSelector((state) => state.user);
  const gridState = useAppSelector((state) => state.grid);
  const gridActions = useGridActions();
  const pageActions = usePageActions();

  useEffect(() => {
    setIsLoading(true);
    getMatterDisbursementById(props.matterId, props.matterDisbursementId).then((response) => {
      setMatterDisbursement(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, [props.matterDisbursementId]);

  useEffect(() => {
    if (matterDisbursement) {
      setSlidingPanelEditButton();
      setSlidingPanelMoreButtons();
    }
  }, [matterDisbursement]);

  const setSlidingPanelEditButton = () => {
    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterDisbursements) &&
      !matterDisbursement?.matter?.isClosed && !matterDisbursement?.isBilled &&
      !matterDisbursement?.hasPayments && !matterDisbursement?.writeOffAmount) {
      slidingPanelActions.setOnEdit(() => {
        slidingPanelActions.clearSlidingPanel();

        slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Edit Disbursement",
          children: <EditMatterDisbursementForm
            matterId={props.matterId}
            matterDisbursementId={props.matterDisbursementId}
            onSubmitCallback={onChildPanelClose}
          />,
          onCancel: onChildPanelClose
        });
      });
    }
  };

  const setSlidingPanelMoreButtons = () => {
    if (!matterDisbursement?.matter?.isClosed && props.showMoreButtons) {
      slidingPanelActions.setMoreButtons([
        ...((loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterDisbursements) && 
          props.hasTaxonomies && props.activityHistoryId) ? [{
          type: ActionButtonTypes.EditTaxonomyTerms,
          callback: () => onClickTaxonomies(),
        }] : []),
        {
          type: ActionButtonTypes.Payment,
          callback: () => onClickRecordPayment(),
        },
        ...(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterDisbursements) && 
          !matterDisbursement?.isBilled ? [
          {
            type: ActionButtonTypes.WriteOff,
            callback: () => onClickWriteOff(),
          },
          {
            type: ActionButtonTypes.Delete,
            callback: () => onClickDelete(),
          }
        ] : []),
      ]);
    }
  };

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  }

  const onClickTaxonomies = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        children: <EditActivityHistoryTaxonomyTermsForm
          matterId={props.matterId}
          activityHistoryId={props.activityHistoryId!}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  }

  function onClickDelete() {
    setIsLoading(true);
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Matter Disbursement?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.matterDisbursementId),
      onClose: () => {setIsLoading(false);},
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();  
  }

  const deleteCallback = (matterId: string, matterDisbursementId: string) => {
    modalActions.toggleModalLoading();
    deleteMatterDisbursement(matterId, matterDisbursementId).then(() => {
      slidingPanelActions.clearSlidingPanel();
      modalActions.toggleModalShownStatus(); 

      if(props.syncMatterFiles) {
        // Reload summary
        pageActions.triggerReloadPage(false);
        // Call sync matter files
        props.syncMatterFiles && props.syncMatterFiles();
      }
      else {
        // Reload entire page
        pageActions.triggerReloadPage();
      }
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
      setIsLoading(false);
    });
  };

  function onClickRecordPayment() {
    //close View Matter Disbursement sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open View Matter Disbursement Payments sliding panel having onCancel event to reopen 
    //View Matter Disbursement Payments sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Disbursement Payments",
      children: <ViewMatterDisbursementPayments
        matterId={props.matterId}
        matterDisbursementId={props.matterDisbursementId}
        onPaymentsChangedCallback={onChildPanelClose}
      />,
      onCancel: onChildPanelClose
    });
  }

  function onClickWriteOff() {
    //close View Matter Disbursement sliding panel
    slidingPanelActions.clearSlidingPanel();
    //open View Matter Disbursement WriteOff sliding panel having onCancel event to reopen 
    //View Matter Disbursement sliding panel
    slidingPanelActions.setSlidingPanel(
    {
      isShown: true,
      title: "Disbursement Write-Off",
      children: <MatterDisbursementWriteOffForm
          matterId={props.matterId}
          matterDisbursementId={props.matterDisbursementId}
          onSubmitCallback={onChildPanelClose}
          onDeleteCallback={onChildPanelClose}
        />,
      onCancel: onChildPanelClose
    });
  }

  return(
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col sm={6}>
          <Field
            label={"Fee Earner"}
            value={matterDisbursement?.user?.name}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Client"}
            value={matterDisbursement?.matterParticipatingEntity?.name}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            label={"Date"}
            value={matterDisbursement?.date ? moment(matterDisbursement?.date).format(DateFormat.Moment) : "—"}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Display Name"}
            value={matterDisbursement?.displayName}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Description"}
            value={matterDisbursement?.description}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Field
            label={"Gross Value"}
            value={matterDisbursement?.grossValue ? formatCurrency(matterDisbursement?.grossValue) : "—"}
          />
        </Col>
        <Col sm={4} className="mt-4 mt-sm-0">
          <Field
            label={"VAT Value"}
            value={matterDisbursement?.vatValue ? formatCurrency(matterDisbursement?.vatValue) : "—"}
          />
        </Col>
        <Col sm={4} className="mt-4 mt-sm-0">
          <Field
            label={"Net Value"}
            value={matterDisbursement?.netValue ? formatCurrency(matterDisbursement?.netValue) : "—"}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            label={"Supplier"}
            value={matterDisbursement?.supplier?.name}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Supplier Reference"}
            value={matterDisbursement?.supplierReference}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            label={"Due Date"}
            value={matterDisbursement?.dueDate ? moment(matterDisbursement?.dueDate).format(DateFormat.Moment) : "—"}
          />
        </Col>
        <Col sm={6} className="mt-4 mt-sm-0">
          <Field
            label={"Amount Due"}
            value={formatCurrency(matterDisbursement?.amountDue)}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <BooleanField
            label={"Is Billed"}
            value={matterDisbursement?.isBilled ?? false}
          />
        </Col>

        {matterDisbursement?.writeOffAmount &&
          <Col sm={6} className="mt-4 mt-sm-0">
            <Field
              label={"Write-Off Date"}
              value={matterDisbursement?.writeOffDate ? moment(matterDisbursement?.writeOffDate).format(DateFormat.Moment) : "—"}
            />
          </Col>
        }
      </Row>

      {matterDisbursement?.writeOffAmount &&
        <Row>
          <Col sm={6}>
            <Field
              label={"Write-Off Amount"}
              value={matterDisbursement?.writeOffAmount ? formatCurrency(matterDisbursement?.writeOffAmount) : "—"}
            />
          </Col>
          <Col sm={6} className="mt-4 mt-sm-0">
            <Field
              label={"Write-Off Description"}
              value={matterDisbursement?.writeOffDescription}
            />
          </Col>
        </Row>
      }

      <Row>
        <Col>
          <div className="departments">
            <ListSlidingPanel
              listItems={_.orderBy(matterDisbursement?.files?.map(x => ({id: x.id, name: x.name, webUrl: x.webUrl})) ?? [], x => x.name.toLowerCase())}
              listButtons={loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterDisbursements) ? [
                {
                  type: ActionButtonTypes.OpenFile
                }
              ] : []}
            >
              Files
            </ListSlidingPanel>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ViewMatterDisbursement;
