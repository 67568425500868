import moment from "moment";
import { create, test, enforce } from "vest";

export const getValidationSuite = () => {
  return create((data = {}) => {
    test('firstDayOfWeek', 'First day of the week is required', () => {
      enforce(data.firstDayOfWeek).isNotEmpty();
    });

    test('dateFormat', 'Date format is required', () => {
      enforce(data.dateFormat).isNotEmpty();
      enforce(data.dateFormat).isString();
    });

    test('dateFormat', 'Date format is invalid', () => {
      enforce(moment('01/01/2000', data.dateFormat).isValid()).isTruthy();
    });

    test('startWorkingHours', 'Start time is required', () => {
      enforce(moment(data.startWorkingHours).isValid()).isTruthy();
    });

    test('endWorkingHours', 'End time is required', () => {
      enforce(moment(data.endWorkingHours).isValid()).isTruthy();
    });

    test('endWorkingHours', 'End time must be greater than start time', () => {
      const start = moment(data.startWorkingHours, 'HH:mm');
      const end = moment(data.endWorkingHours, 'HH:mm');
      enforce(start.isBefore(end)).isTruthy();
    });
  });
}