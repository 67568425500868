import { useCallback, useEffect, useState } from "react";
import useGridActions from 'actions/grid';
import Grid from "components/Grid";
import { columnDefs } from "./columnDefs";
import Title from 'components/Title';
import SlidingPanel from 'components/SlidingPanel';
import { Card } from 'react-bootstrap';
import { getChartOfAccountsSummaries } from "actions/lte";
import { GridIds } from "enums/GridIds";

function ChartOfAccounts() {
  const gridActions = useGridActions();
  const [genericErrors, setGenericErrors] = useState(null);

  const onGridReady = useCallback(() =>
  {
    gridActions.setGridColumnDefs([...columnDefs]);
    getChartOfAccountsSummaries()
      .then((response) => 
      {
        gridActions.setGridRowData(response.data);
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
        gridActions.setGridRowData([]);
      });
  }, []);

  useEffect(() => {
    gridActions.setGridId(GridIds.ChartOfAccounts);
  }, []);

  return (
    <div className="lp-page-content">
      <Title
        type="page"
        title="Chart of Accounts"
      >
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        <Card.Body>
          <Grid
            onGridReady={onGridReady}
            skipRowIdCheck={true}
          />
        </Card.Body>
      </Card>

      <SlidingPanel/>
    </div>
  );
}

export default ChartOfAccounts;
