import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col } from "react-bootstrap";
import { deleteMatterFeeEarner, getMatterFeeEarnerById } from "actions/matter";
import { MatterFeeEarnerModel } from "models/view/MatterFeeEarnerModel";
import BooleanField from "components/Fields/BooleanField";
import ViewMatterFeeEarnerPreferredContacts from "../ViewMatterFeeEarnerPreferredContacts";
import ViewMatterFeeEarnerProfile from "../ViewMatterFeeEarnerProfile";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import EditMatterFeeEarnerForm from "../EditMatterFeeEarner/EditMatterFeeEarnerForm";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { MatterFeeEarnerType } from "enums/MatterFeeEarnerType";

type Props = {
  matterId: string,
  matterFeeEarnerId: string,
  isMatterClosed?: boolean,
  editSubmitCallback?: Function,
  deleteCallback?: Function,
  isCurrentCaseManager?: boolean,
  isCurrentUserAllowedToViewMatter?: boolean
}

function ViewMatterFeeEarner(props: Props){
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const [matterFeeEarner, setMatterFeeEarner] = useState<MatterFeeEarnerModel>();
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const modalActions = useModalActions();
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(()=>{
    setIsLoading(true);
    getMatterFeeEarnerById(props.matterFeeEarnerId).then((response) => {
      setMatterFeeEarner(response.data);

      // Set edit button action
      if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatters) && 
        !props.isMatterClosed && props.isCurrentUserAllowedToViewMatter) {
        slidingPanelActions.setOnEdit(() => {
          slidingPanelActions.clearSlidingPanel();

          slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            title: `Edit ${response.data?.type == MatterFeeEarnerType.FeeEarner ? 'Fee Earner' : 'Delegate User'}`,
            children: <EditMatterFeeEarnerForm
              matterId={props.matterId}
              matterFeeEarnerId={props.matterFeeEarnerId}
              submitCallback={() => {
                props.editSubmitCallback && props.editSubmitCallback();
                onChildPanelClose();
              }}
              isCurrentCaseManager={props.isCurrentCaseManager}
            />,
            onCancel: onChildPanelClose
          });
        });
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  },[])

  useEffect(() => {
    setMoreButtons();
  }, [matterFeeEarner]);

  const setMoreButtons = () => {
    if(loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatters) && 
      !props.isMatterClosed && matterFeeEarner && !matterFeeEarner?.isCaseManager && props.isCurrentUserAllowedToViewMatter) {
        slidingPanelActions.setMoreButtons([{
          type: ActionButtonTypes.Delete,
          callback: () => deleteMatterFeeEarnerModal(props.matterFeeEarnerId),
        }]);
    }
  }

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  };

  const deleteMatterFeeEarnerModal = (id: string) => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: `Are you sure you want to delete this ${matterFeeEarner?.type == MatterFeeEarnerType.FeeEarner ? 'Fee Earner' : 'Delegate User'}?`,
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteMatterFeeEarnerCallback(id),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const deleteMatterFeeEarnerCallback = (id: string) => {
    modalActions.toggleModalLoading();
    deleteMatterFeeEarner(id)
      .then(() => {
        props.deleteCallback && props.deleteCallback();

        slidingPanelActions.clearSlidingPanel();
      })
      .catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);

        modalActions.toggleModalLoading();
        modalActions.toggleModalShownStatus();
      });
  };

  return(
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <ViewMatterFeeEarnerProfile 
            matterFeeEarner={matterFeeEarner!}
            showRole={false}
          />
        </Col>
      </Row>
      <div className="lp-entity-info">
        <Row>
          <Col sm={6}>
            <Field
              label={"Fee Earner Level"}
              value={matterFeeEarner?.feeEarnerLevel?.name}
            />
          </Col>
          <Col sm={6}>
            <BooleanField
              label={"Is Case Manager"}
              value={matterFeeEarner?.isCaseManager ?? false}
            />
          </Col>
        </Row>
      </div>
      {matterFeeEarner &&
        <Row>
          <Col>
            <ViewMatterFeeEarnerPreferredContacts matterFeeEarner={matterFeeEarner}/>
          </Col>
        </Row>
      }
    </>
  );
}

export default ViewMatterFeeEarner;
