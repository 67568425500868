import { useNavigate, NavigateFunction, useParams } from "react-router-dom";
import { MatterModel } from "models/view/MatterModel";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import RiskRatingFieldValue from "components/Fields/RiskRatingFieldValue";

type Props = {
  matter: MatterModel;
  selectedMatterId?: string;
}

function MatterQuickSearchPanelItem(props: Props) {
  const navigate = useNavigate();
  const { tab } = useParams();

  const viewCallback = (id: string, navigate: NavigateFunction) => {
    if(id === props.selectedMatterId) {
      return; // Return if the user clicks on the already active tab
    }
    // Send quickSearchMatterId as navigation state to identify the source of navigation (matter quick search panel)
    navigate(`/matter/${id}${tab ? `/${tab}` : ''}`, {state: { "edit": false, "quickSearchMatterId": id }});
  };

  return(
    <div
      className={`lp-quick-search-list-item ${props.matter?.id === props.selectedMatterId ? 'active' : ''}`}
      onClick={() => viewCallback(props.matter?.id, navigate)}
    >
      <div className="lp-quick-matter-rating">
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip-right">Risk Rating: <strong>{props.matter?.riskRating.name}</strong></Tooltip>
          }
        >
          <div><RiskRatingFieldValue value={props.matter?.riskRating.name ?? ''} /></div>
        </OverlayTrigger>
      </div>
      <div className="lp-quick-matter-info">
        <div className="lp-quick-matter-number">{props.matter?.fileNumber}</div>
        <div className="lp-quick-matter-name">{props.matter?.displayName}</div>
      </div>
    </div>
  );
}

export default MatterQuickSearchPanelItem;
