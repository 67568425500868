import { vestResolver } from "@hookform/resolvers/vest";
import {
  getFundsTransferTypes,
  getMatterClientsSummary,
  getMatterDefaultClient,
  getMatterFundsTransferById,
  getOpenMattersSummary,
  updateMatterFundsTransfer,
} from "actions/matter";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader/index";
import CustomSelect from "components/Select/Select";
import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { DateFormat } from "utils/constants";
import { removeEmptyFields } from "utils/form";
import { validationSuite } from "./validationSuite";
import { getDateOnly } from "utils/date";
import { DropDownOptionModel } from "models/view/DropDownOptionModel";
import { CreateOrUpdateMatterFundsTransferModel } from "models/create/CreateOrUpdateMatterFundsTransferModel";
import { FundsTransferTypeIds } from "enums/FundsTransferTypeIds";
import { useAppSelector } from "hooks/appSelector";
import usePageActions from "actions/page";
import FormErrorButton from "components/Buttons/FormErrorButton";

type Props = {
  matterId: string,
  matterFundsTransferId: string,
  onSubmitCallback?: Function
}

export default function EditMatterFundsTransferForm(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const [clients, setClients] = useState<DropDownOptionModel[]>([]);
  const [transferToClients, setTransferToClients] = useState<DropDownOptionModel[]>([]);
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const pageActions = usePageActions();

  const { register, handleSubmit, control, watch, reset, setValue, resetField, formState: { errors } } = useForm<CreateOrUpdateMatterFundsTransferModel>({
    resolver: vestResolver(validationSuite)
  });

  useEffect(() => {
    setIsLoading(true);
    getMatterFundsTransferById(props.matterId, props.matterFundsTransferId).then((response) => {
      let initialState: CreateOrUpdateMatterFundsTransferModel = {
        matterParticipatingEntityId: response.data.matterParticipatingEntityId,
        date: getDateOnly(response.data.date),
        transferTypeId: response.data.transferTypeId,
        amount: response.data.amount,
        description: response.data.description,
        transferToMatterId: response.data.transferToMatterId,
        transferToMatterParticipatingEntityId: response.data.transferToMatterParticipatingEntityId
      };

      loadClients(props.matterId);

      if(initialState.transferTypeId == FundsTransferTypeIds.InterClientId && initialState.transferToMatterId) {
        setIsLoading(true);
        getMatterClientsSummary(initialState.transferToMatterId).then(response => {
          setTransferToClients(response.data);
        }).catch((error) => {
          setGenericErrors(error.response?.data?.Message ?? error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
      }

      reset(initialState);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  const onSubmit = handleSubmit((data) => submitData(data));

  async function submitData(data: CreateOrUpdateMatterFundsTransferModel) {
    setIsLoading(true);
    removeEmptyFields(data);
    updateMatterFundsTransfer(props.matterId, props.matterFundsTransferId, data).then((response) => {
      pageActions.triggerReloadPage();

      slidingPanelActions.clearSlidingPanel();
      reset();

      props.onSubmitCallback && props.onSubmitCallback();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();

    currentSlidingPanelState.onCancel !== undefined && currentSlidingPanelState?.onCancel();
  }

  const loadClients = (matterId?: string) => {
    if(matterId) {
      setIsLoading(true);
      getMatterClientsSummary(matterId).then((response) => {
        setClients(response.data);
      }).catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
    else {
      setClients([]);
    }
  }

  const onChangeTransferToMatter = (matterId?: string) => {
    if(matterId){
      const promises: Promise<any>[] = [];
      promises.push(getMatterClientsSummary(matterId));
      promises.push(getMatterDefaultClient(matterId));
      
      Promise.all(promises).then(([matterClientsResponse, matterDefaultClientResponse]) => {
        setTransferToClients(matterClientsResponse.data);
        setValue('transferToMatterParticipatingEntityId', matterDefaultClientResponse.data?.id);
      }).catch((error) => {
        setGenericErrors(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
    else {
      setTransferToClients([]);
      resetField('transferToMatterParticipatingEntityId');
    }
  }

  const onChangeTransferType = (transferTypeId?: string) => {
    if(transferTypeId != FundsTransferTypeIds.InterClientId){
      resetField("transferToMatterId");
      resetField("transferToMatterParticipatingEntityId");
    }
  }

  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} sm={6} controlId="matterParticipatingEntityId">
            <Form.Label className="required">Client</Form.Label>
            <Controller
              control={control}
              name={`matterParticipatingEntityId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="matterParticipatingEntityId"
                  inputRef={ref}
                  className={`lp-select${errors?.matterParticipatingEntityId?.message ? ' invalid' : ''}`}
                  options={clients}
                  value={value}
                  onChange={val => onChange(val?.id ?? null)}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.matterParticipatingEntityId?.message && (errors.matterParticipatingEntityId?.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="date">
            <Form.Label className="required">Date</Form.Label>
            <Controller
              control={control}
              name="date"
              shouldUnregister={true}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  className={`${errors?.date?.message ? 'invalid' : ''}`}
                  id="date"
                  dateFormat={DateFormat.Datepicker}
                  selected={value ? getDateOnly(value) : null}
                  onChange={(val) => onChange(val != null ? getDateOnly(val) : val)}
                  showMonthDropdown
                  showYearDropdown
                  autoComplete="off"
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.date?.message && (errors.date.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={6} controlId="transferTypeId">
            <Form.Label className="required">Transfer Type</Form.Label>
            <Controller
              control={control}
              name={`transferTypeId`}
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <CustomSelect
                  id="transferTypeId"
                  inputRef={ref}
                  className={`lp-select${errors?.transferTypeId?.message ? ' invalid' : ''}`}
                  endpointCall={getFundsTransferTypes}
                  value={value}
                  onChange={val => { onChange(val?.id ?? null); onChangeTransferType(val?.id);}}
                />
              )}
            />
            <Form.Text className="lp-error">
              {errors?.transferTypeId?.message && (errors.transferTypeId?.message)}
            </Form.Text>
          </Form.Group>

          <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="amount">
            <Form.Label className={"required"}>Amount</Form.Label>
            <Form.Control
              type="number"
              className={`${errors?.amount?.message ? 'invalid' : ''}`}
              {...register(`amount`, {shouldUnregister: true})}
              min="0.00"
              step="0.01"
              onWheel={e => e.currentTarget.blur()}
            />
            <Form.Text className="lp-error">
              {errors?.amount?.message && (errors.amount?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        {watch("transferTypeId") == FundsTransferTypeIds.InterClientId &&
          <Row>
            <Form.Group as={Col} sm={6} controlId="transferToMatterId">
              <Form.Label className="required">Transfer To Matter</Form.Label>
              <Controller
                control={control}
                name={`transferToMatterId`}
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="transferToMatterId"
                    inputRef={ref}
                    className={`lp-select${errors?.transferToMatterId?.message ? ' invalid' : ''}`}
                    endpointCall={() => getOpenMattersSummary(props.matterId)}
                    value={value}
                    onChange={val => {onChange(val?.id ?? null); onChangeTransferToMatter(val?.id ?? undefined);}}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.transferToMatterId?.message && (errors.transferToMatterId?.message)}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm={6} className="mt-4 mt-sm-0" controlId="transferToMatterParticipatingEntityId">
              <Form.Label className="required">Transfer To Client</Form.Label>
              <Controller
                control={control}
                name={`transferToMatterParticipatingEntityId`}
                shouldUnregister={true}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CustomSelect
                    id="transferToMatterParticipatingEntityId"
                    inputRef={ref}
                    className={`lp-select${errors?.transferToMatterParticipatingEntityId?.message ? ' invalid' : ''}`}
                    options={transferToClients}
                    value={value}
                    onChange={val => onChange(val?.id ?? null)}
                  />
                )}
              />
              <Form.Text className="lp-error">
                {errors?.transferToMatterParticipatingEntityId?.message && (errors.transferToMatterParticipatingEntityId?.message)}
              </Form.Text>
            </Form.Group>
          </Row>
        }

        <Row>
          <Form.Group as={Col} controlId="description">
            <Form.Label className="required">Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              className={`${errors?.description?.message ? 'invalid' : ''}`}
              {...register(`description`, {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.description?.message && (errors.description?.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Update" />
              : <Button variant="success" type="submit">Update</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
