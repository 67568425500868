import { useCallback, useEffect, useState } from "react";
import useGridActions from 'actions/grid';
import Grid from "components/Grid";
import { getColumnDefs } from "./columnDefs";
import { MdAddCircle } from 'react-icons/md';
import Title from 'components/Title';
import SlidingPanel from 'components/SlidingPanel';
import LawPageTradingEntityForm from 'containers/LawPageTradingEntity/CreateLawPageTradingEntity/LawPageTradingEntityCreateForm';
import { Card, Button } from 'react-bootstrap';
import { getLtes } from "actions/lte";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from 'hooks/appSelector';
import { UserPermissionsNames } from 'enums/UserPermissionsNames';
import { GridIds } from "enums/GridIds";

function LawPageTradingEntityList() {
  const gridActions = useGridActions();
  const slidingPanelActions = useSlidingPanelActions();
  const [genericErrors, setGenericErrors] = useState(null);
  const loggedInUser = useAppSelector((state) => state.user);

  const onGridReady = useCallback(() =>
  {
    const columnDefs = getColumnDefs();
    gridActions.setGridColumnDefs([...columnDefs]);
    getLtes().then((response) => 
    {
      gridActions.setGridRowData(response.data);
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
      gridActions.setGridRowData([]);
    });
  }, []);

  useEffect(() => {
    gridActions.setGridId(GridIds.Lte);
  }, []);

  const toggleSlidingPanel = () => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Add new LTE",
        children: <LawPageTradingEntityForm />
      });
  };

  return (
    <div className="lp-page-content">
      <Title
        type="page"
        title="Trading Entities"
      >
        {loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageAllLTEs) &&
          <Button variant="success" onClick={toggleSlidingPanel}>
            <MdAddCircle />
            Add Trading Entity
          </Button>
        }
      </Title>

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Card className="with-grid">
        <Card.Body>
          <Grid
            onGridReady={onGridReady}
          />
        </Card.Body>
      </Card>

      <SlidingPanel />
    </div>
  );
}

export default LawPageTradingEntityList;
