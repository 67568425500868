import { useEffect, useState } from "react";
import Field from "components/Fields/Field";
import Loader from "components/Loader";
import { Row, Col } from "react-bootstrap";
import { deleteMatterNote, getMatterNoteById } from "actions/matter";
import { MatterNoteModel } from "models/view/MatterNoteModel";
import useSlidingPanelActions from "actions/slidingPanel";
import { useAppSelector } from "hooks/appSelector";
import EditMatterNoteForm from "../EditMatterNote/EditMatterNoteForm";
import { MatterActivityHistoryModel } from "models/view/MatterActivityHistoryModel";
import { ActionButtonTypes } from "enums/ActionButtonTypes";
import EditActivityHistoryTaxonomyTermsForm from "../../ActivityHistoryGrid/ActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTerms/EditActivityHistoryTaxonomyTermsForm";
import { MatterActivityHistorySubtypes } from "enums/MatterActivityHistorySubtypes";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import useGridActions from "actions/grid";
import { UserPermissionsNames } from "enums/UserPermissionsNames";
import { UserRoleNames } from "enums/UserRoleNames";

type Props = {
  matterId: string,
  noteId: string,
  activitySubtype: number,
  activityHistoryId: string,
  isMatterClosed?: boolean,
  hasTaxonomies: boolean,
  showMoreButtons?: boolean,
}

function ViewMatterNote(props: Props){
  const [isLoading, setIsLoading] = useState(true);
  const [matterNote, setMatterNote] = useState<MatterNoteModel>();
  const [genericErrors, setGenericErrors] = useState(null);
  const slidingPanelActions = useSlidingPanelActions();
  const currentSlidingPanelState = useAppSelector((state) => state.slidingPanel);
  const modalActions = useModalActions();
  const gridActions = useGridActions();
  const currentGridState = useAppSelector((state) => state.grid);
  const loggedInUser = useAppSelector((state) => state.user);

  useEffect(() => {
    setIsLoading(true);
    getMatterNoteById(props.matterId, props.noteId).then((response) => {
      setMatterNote(response.data);

      const canManageUserNotes = response.data.createdById == loggedInUser.userId || 
        (loggedInUser.userRole?.name != UserRoleNames.Lawyer &&
        loggedInUser.userRole?.name != UserRoleNames.Clerk &&
        loggedInUser.userRole?.name != UserRoleNames.BackOffice && 
        loggedInUser.userRole?.name != UserRoleNames.Accountant &&
        loggedInUser.userRole?.name != UserRoleNames.EntryLevel &&
        response.data.createdById != loggedInUser.userId);

      // Set edit button action
      if (!props.isMatterClosed && props.activitySubtype !== MatterActivityHistorySubtypes.SystemNote && canManageUserNotes) {
        slidingPanelActions.setOnEdit(() => {
          slidingPanelActions.clearSlidingPanel();

          slidingPanelActions.setSlidingPanel(
          {
            isShown: true,
            width: "55rem",
            title: "Edit Matter Note",
            children: <EditMatterNoteForm
              matterId={props.matterId}
              noteId={props.noteId}
              onSubmitCallback={onChildPanelClose}
            />,
            onCancel: onChildPanelClose
          });
        });
      }

      if (!props.isMatterClosed && props.showMoreButtons) {
        slidingPanelActions.setMoreButtons([
          ...(props.hasTaxonomies &&
            ((props.activitySubtype == MatterActivityHistorySubtypes.SystemNote &&
            loggedInUser.userPermissions?.some(a => a == UserPermissionsNames.ManageMatterActivityHistory)) ||
            props.activitySubtype == MatterActivityHistorySubtypes.UserNote && canManageUserNotes)
          ? [{
            type: ActionButtonTypes.EditTaxonomyTerms,
            callback: () => onClickTaxonomies(),
          }] : []),
          ...(props.activitySubtype !== MatterActivityHistorySubtypes.SystemNote && canManageUserNotes
          ? [{
            type: ActionButtonTypes.Delete,
            callback: () => onClickDelete(),
          }] : [])
        ]);
      }
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, [props.noteId]);

  const onChildPanelClose = () => {
    slidingPanelActions.clearSlidingPanel();
    slidingPanelActions.setSlidingPanel({
      ...currentSlidingPanelState,
    });
  };

  const onClickTaxonomies = () => {
    slidingPanelActions.clearSlidingPanel();

    slidingPanelActions.setSlidingPanel(
      {
        isShown: true,
        title: "Edit Taxonomy Terms",
        children: <EditActivityHistoryTaxonomyTermsForm
          matterId={props.matterId}
          activityHistoryId={props.activityHistoryId}
          onSubmitCallback={onChildPanelClose}
        />,
        onCancel: onChildPanelClose
      }
    );
  };

  const onClickDelete = () => {
    let modal: ModalState = {
      title: "Delete confirmation",
      body: "Are you sure you want to delete this Matter Note?",
      actionText: "Delete",
      actionVariant: "danger",
      onAction: () => deleteCallback(props.matterId, props.noteId),
      show: false
    }
    modalActions.setModal(modal);
    modalActions.toggleModalShownStatus();
  };

  const deleteCallback = (matterId: string, activityId: string) => {
    const gridState = currentGridState;
    modalActions.toggleModalLoading();
    deleteMatterNote(matterId, activityId).then(() => {
      gridActions.setGridRowData(gridState.rowData.filter((x: MatterActivityHistoryModel) => x.matterNoteId !== activityId));
      modalActions.toggleModalShownStatus();
      slidingPanelActions.clearSlidingPanel();
    })
    .catch((error) => {
      modalActions.setErrorsForModal(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      modalActions.toggleModalLoading();
    });
  };

  return(
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Row>
        <Col>
          <Field
            label={"Note"}
            value={matterNote?.note}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            label={"Type"}
            value={matterNote?.noteType?.name}
          />
        </Col>
      </Row>
    </>
  );
}

export default ViewMatterNote;
