export enum GridIds {
  Matters = "Matters",
  ParticipatingEntities = "ParticipatingEntities",
  Appointments = "Appointments",
  ActivityHistory = "ActivityHistory",
  MatterClientLedger = "MatterClientLedger",
  MatterDisbursements = "MatterDisbursements",
  MatterIncidentalExpenses = "MatterIncidentalExpenses",
  MatterRecordableItems = "MatterRecordableItems",
  MatterTaxonomy = "MatterTaxonomy",
  MatterTaxonomyTerms = "MatterTaxonomyTerms",
  Lte = "Lte",
  LteBankAccounts = "LteBankAccounts",
  LteUsers = "LteUsers",
  EntityList = "EntityList",
  EntityBankAccounts = "EntityBankAccounts",
  EntityLedger = "EntityLedger",
  ChargingSchemes = "ChargingSchemes",
  ChargeRates = "ChargeRates",
  SettingsDefaultAccounts = "SettingsDefaultAccounts",
  SettingsDefaultAccountTypes = "SettingsDefaultAccountTypes",
  SettingsDefaultAccountTypeCategories = "SettingsDefaultAccountTypeCategories",
  SettingsAccounts = "SettingsAccounts",
  SettingsAccountTypes = "SettingsAccountTypes",
  SettingsAccountTypeCategories = "SettingsAccountTypeCategories",
  SettingsDefaultEntityRoles = "SettingsDefaultEntityRoles",
  SettingsEntityRoles = "SettingsEntityRoles",
  SettingsDefaultActivities = "SettingsDefaultActivities",
  SettingsDefaultFeeEarnerLevels = "SettingsDefaultFeeEarnerLevels",
  SettingsActivities = "SettingsActivities",
  SettingsFeeEarnerLevels = "SettingsFeeEarnerLevels",
  SettingsTaxonomies = "SettingsTaxonomies",
  SettingsTaxonomyTerms = "SettingsTaxonomyTerms",
  SettingsMatterTemplates = "SettingsMatterTemplates",
  SettingsMatterTemplateTypes = "SettingsMatterTemplateTypes",
  ChartOfAccounts = "ChartOfAccounts",
  ChartOfAccountsLedgerEntries = "ChartOfAccountsLedgerEntries",
  Invoices = "Invoices",
  BillsToPay = "BillsToPay",
  LteRecordableItems = "LteRecordableItems",
  LteIncidentalExpenses = "LteIncidentalExpenses",
  FreeBusyCalendar = "FreeBusyCalendar",
  DiaryManagerCalendar = "DiaryManagerCalendar",
}