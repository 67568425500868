import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { vestResolver } from "@hookform/resolvers/vest";
import useGridActions from "actions/grid";
import { useAppSelector } from "hooks/appSelector";
import { Form, Button, Row, Col } from "react-bootstrap";
import useSlidingPanelActions from "actions/slidingPanel";
import Loader from "components/Loader/index";
import { getValidationSuite } from "./validationSuite";
import { CreateOrUpdateTaxonomyModel } from "models/create/CreateOrUpdateTaxonomyModel";
import { addMatterTaxonomy } from "actions/taxonomy";
import FormErrorButton from "components/Buttons/FormErrorButton";

type Props = {
  matterId: string
}

export default function CreateMatterTaxonomyForm(props: Props) {
  const [genericErrors, setGenericErrors] = useState(null);
  const gridActions = useGridActions();
  const grid = useAppSelector((state) => state.grid);
  const slidingPanelActions = useSlidingPanelActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function submitData(data: CreateOrUpdateTaxonomyModel) {
    setIsLoading(true);
    data.canBeOnTemplates = false;
    addMatterTaxonomy(props.matterId, data).then((response) => {
      gridActions.setGridRowData(grid.rowData.concat(response.data));
      slidingPanelActions.clearSlidingPanel();
      reset();
    })
    .catch((error) => {
      setGenericErrors(error.response?.data?.Message ?? error.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const {register, reset, control, handleSubmit, formState: {errors}} = useForm<CreateOrUpdateTaxonomyModel>({
    resolver: vestResolver(getValidationSuite(props.matterId))
  });

  const onSubmit = handleSubmit((data) => submitData(data));

  const cancelForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    slidingPanelActions.clearSlidingPanel();
    reset();
  }
      
  return (
    <>
      {isLoading && <Loader inlineLoader />}

      {genericErrors && (
        <div className="lp-errors">
          {genericErrors}
        </div>
      )}

      <Form onSubmit={onSubmit} className="d-flex flex-column h-100">
        <Row>
          <Form.Group as={Col} controlId="displayName">
            <Form.Label className="required">Display Name</Form.Label>
            <Form.Control
              type="text"
              className={`${errors?.displayName?.message ? 'invalid' : ''}`}
              {...register("displayName", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.displayName?.message && (errors.displayName.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              className={`${errors?.description?.message ? 'invalid' : ''}`}
              {...register("description", {shouldUnregister: true})}
            />
            <Form.Text className="lp-error">
              {errors?.description?.message && (errors.description.message)}
            </Form.Text>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={4} controlId="isHierarchical">
            <Form.Label>Is Hierarchical</Form.Label>
            <Controller
              control={control}
              name="isHierarchical"
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <Form.Check type="switch" id="isHierarchical">
                  <Form.Check.Input
                    className="form-check-input"
                    ref={ref}
                    checked={value ?? false}
                    onChange={(ev: any) => {
                      onChange(ev.target.checked);
                    }}
                  />
                </Form.Check>
              )}
            />
          </Form.Group>

          <Form.Group as={Col} sm={4} className="mt-4 mt-sm-0" controlId="isMultiSelect">
            <Form.Label>Is MultiSelect</Form.Label>
            <Controller
              control={control}
              name="isMultiSelect"
              shouldUnregister={true}
              render={({ field: { onChange, value, name, ref } }) => (
                <Form.Check type="switch" id="isMultiSelect">
                  <Form.Check.Input
                    className="form-check-input"
                    ref={ref}
                    checked={value ?? false}
                    onChange={(ev: any) => {
                      onChange(ev.target.checked);
                    }}
                  />
                </Form.Check>
              )}
            />
          </Form.Group>

          <Form.Group as={Col} sm={4} className="mt-4 mt-sm-0" controlId="active">
            <Form.Label>Active</Form.Label>
            <Controller
              control={control}
              name="active"
              shouldUnregister={true}
              render={({field: { onChange, value, name, ref }}) => (
                <Form.Check 
                  type="switch"
                  id="active">
                    <Form.Check.Input
                      className= "form-check-input"
                      ref={ref}
                      checked={value ?? false}
                      onChange={(ev: any) => onChange(ev.target.checked)}
                    />
                </Form.Check>
              )}
            />
          </Form.Group>
        </Row>

        <div className="lp-slide-panel-sticky-bottom">
          <Form.Group className="d-flex justify-content-between">
            { Object.keys(errors).length
              ? <FormErrorButton text="Create" />
              : <Button variant="success" type="submit">Create</Button>
            }
            <Button variant="secondary-400" onClick={cancelForm}>Cancel</Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
